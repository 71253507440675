import { Button, Modal } from "@afound/react";
import { removeQuerystringByKeys, stringFormat } from "@afound/common";
import { useCallback, useEffect, useState } from "react";
import { useLocalization, useSettings } from "../../settings";
import styles from "./declined-permissions-modal.module.scss";
import { selectCart } from "../../features/cart/cart-slice";
import { useAppSelector } from "../../store";

const showDeclinedPermissionsModalQuery = "showdeclinedpermissionsmodal";
const providerQuery = "provider";

export const DeclinedPermissionsModal = () => {
   const { 
      externalLoginFailedHeader, 
      externalLoginFailedText, 
      externalLoginFailedTryAgain, 
      externalLoginFailedClose 
   } = useLocalization("shared");
   const {
      locale
   } = useSettings();
   const { cart } = useAppSelector(selectCart);

   const query = new URLSearchParams(window.location.search);
   const [showDeclinedPermissionsModal, setShowDeclinedPermissionsModal] = useState(query.get(showDeclinedPermissionsModalQuery) === "1");
   const provider = query.get(providerQuery) || ""; 

   useEffect(() => {
      const query = new URLSearchParams(window.location.search);
      if (query.has(showDeclinedPermissionsModalQuery) && !showDeclinedPermissionsModal) {        
         removeQuerystringByKeys(query, [showDeclinedPermissionsModalQuery, providerQuery]);
      }
   }, [showDeclinedPermissionsModal]);
      
   const closeModal = useCallback(() => setShowDeclinedPermissionsModal(false), []);

   return(
      <Modal visible={showDeclinedPermissionsModal} theme="mintgreen" onClose={closeModal}>
         <div className={styles.declinedPermissionsWrapper}>           
            <h2>{stringFormat(externalLoginFailedHeader, provider)}</h2>   
            <span>{externalLoginFailedText}</span>
            <div className={styles.buttonWrapper}>
               <Button type="button" className={styles.button} url={`/api/externallogin/initiate?provider=${provider}&locale=${locale}&market=${cart?.marketName}&facebookAuthType=rerequest`}>
                  {externalLoginFailedTryAgain}
               </Button>
               <Button type="button" className={styles.button} onClick={closeModal}>
                  {externalLoginFailedClose}
               </Button>
            </div>                
         </div>
      </Modal>
   );
};