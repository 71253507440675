import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useCallback, useEffect, useRef, useState } from "react";

import { useGoogleMapsContext } from "../google-maps/google-maps-context";
import { runAsync } from "./utils";

export interface PredictionResult {
   predictions: google.maps.places.AutocompletePrediction[];
   fetchPlaceDetails: (placeId: string) => Promise<google.maps.GeocoderAddressComponent[]>;
   fetchPredictions: (text: string, locale: string) => Promise<google.maps.places.AutocompletePrediction[]>;
}

export const usePlacesAutocomplete = (
   text: string,
   locale: string,
   debounceTimeout = 400
): PredictionResult => {
   const [predictions, setPredictions] = useState<google.maps.places.AutocompletePrediction[]>([]);
   const sessionTokenRef = useRef<google.maps.places.AutocompleteSessionToken | null>();

   const { autoCompleteService, placesService, requestSessionToken } = useGoogleMapsContext();
   const ai = useAppInsightsContext();

   const fetchPredictions = async (text: string, locale: string) => {
      try {
         if (!text || text === "") {
            return [];
         }

         if (!sessionTokenRef.current) {
            sessionTokenRef.current = requestSessionToken();
         }

         const predictions = await runAsync<google.maps.places.AutocompletePrediction[] | null>(
            autoCompleteService.getPlacePredictions,
            autoCompleteService,
            {
               input: text,
               componentRestrictions: { country: locale },
               types: ["address"],
               sessionToken: sessionTokenRef.current!,
            }
         );

         sessionTokenRef.current = null;

         return predictions || [];
      } catch (e) {
         ai.trackException({ exception: e as Error });
         return [];
      }
   }

   useEffect(() => {
      const handleDebounce = setTimeout(async () => {

         const predictions = await fetchPredictions(text, locale)

         setPredictions(predictions || []);

      }, debounceTimeout);

      return () => {
         clearTimeout(handleDebounce);
      };
   }, [text, debounceTimeout, ai, locale, requestSessionToken, autoCompleteService]);

   const fetchPlaceDetails = async (placeId: string) => {
      try {
         if (!placeId || placeId === "") {
            return [];
         }

         const placeResult = await runAsync<google.maps.places.PlaceResult>(
            placesService.getDetails,
            placesService,
            {
               placeId,
               fields: ["address_components"],
               sessionToken: sessionTokenRef.current,
            }
         );

         sessionTokenRef.current = null;

         return placeResult.address_components || [];
      } catch (e) {
         ai.trackException({ exception: e as Error });
         return [];
      }
   };

   return {
      predictions,
      fetchPlaceDetails: useCallback(fetchPlaceDetails, [ai, placesService]),
      fetchPredictions,
   };
};
