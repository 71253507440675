import { Button, Checkbox, InputField, useGtm } from "@afound/react";
import classNames from "classnames";
import { Form, Formik, FormikProps } from "formik";
import { useEffect, useRef, useState } from "react";
import * as Yup from "yup";

import { useLocalization, useSettings } from "../../../settings";
import { useAppDispatch, useAppSelector } from "../../../store";
import { error, fatal } from "../../notification/notification-slice";
import { applyCoupon, selectCart } from "../cart-slice";
import styles from "./coupon-form.module.scss";
import { getCouponAppliedEventProps } from "../../../tracking/gtm";

interface CouponContract {
   hasCoupon: boolean;
   couponCode: string;
}

interface CouponFormProps {
   disabled?: boolean;
}

export const CouponForm = (props: CouponFormProps) => {
   const { couponStatus, applyCouponError, cart } = useAppSelector(selectCart);

   const [visible, setVisible] = useState(false);

   const { locale } = useSettings();

   const formikRef = useRef<FormikProps<CouponContract>>(null);

   const dispatch = useAppDispatch();

   const { couponLabel, couponPlaceholder, submitCoupon } = useLocalization("cart");
   const { required } = useLocalization("errors");

   const { dataLayer } = useGtm();

   useEffect(() => {
      if (cart && cart.couponErrorMessages.length) {
         const couponError = cart.couponErrorMessages[0];
         dispatch(error(couponError));
      } else if (couponStatus === "success") {
         const appliedCoupon = formikRef.current!.values.couponCode;
         dataLayer(getCouponAppliedEventProps(appliedCoupon));
         formikRef.current!.resetForm();
         setVisible(false);
      } else if (couponStatus === "error") {
         if (applyCouponError) {
            formikRef.current!.setErrors({ couponCode: applyCouponError });
         } else {
            dispatch(fatal());
         }
      }
   }, [couponStatus, cart, applyCouponError, dispatch, dataLayer]);

   const handleVisibleToggle = (checked: boolean) => {
      setVisible(checked);
   };

   const handleCouponApplied = ({ couponCode }: CouponContract) => {
      dispatch(applyCoupon({ locale, couponCode }));
   };

   const couponFormClasses = classNames({
      [styles.couponForm]: true,
      [styles.visible]: !!visible,
   });

   return (
      <div className={styles.coupon}>
         <Formik
            innerRef={formikRef}
            initialValues={{ hasCoupon: false, couponCode: "" }}
            validationSchema={Yup.object({
               couponCode: Yup.string().required(required),
            })}
            onSubmit={handleCouponApplied}
         >
            <Form>
               <Checkbox name="hasCoupon" onChange={handleVisibleToggle} disabled={props.disabled}>
                  {couponLabel}
               </Checkbox>
               <div className={couponFormClasses}>
                  <InputField
                     name="couponCode"
                     placeholder={couponPlaceholder}
                     className={styles.couponField}
                     disabled={props.disabled}
                     errorAsHtml
                  />
                  <Button
                     type="submit"
                     theme="secondary"
                     busy={couponStatus === "loading"}
                     wide={false}
                  >
                     {submitCoupon}
                  </Button>
               </div>
            </Form>
         </Formik>
      </div>
   );
};
