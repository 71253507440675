import { Icon } from "../../../components/icon";
import styles from "./help.module.scss";


type HelpTexts = {
    heading: string;
    faq: string;
    contactUs: string;
}
interface CheckoutHelpProps {    
    texts: HelpTexts;
    faqPageUrl: string;
    contactUsUrl: string;
}

export const CheckoutHelp = (props: CheckoutHelpProps) => {
    const { texts, faqPageUrl, contactUsUrl } = props;

    return (
        <div className={styles.wrapper}>
            <h2 className={styles.heading}>{texts.heading}</h2>
            <div className={styles.content}>
                <a className={styles.contentItem} href={faqPageUrl}>
                    <span className={styles.icon}>
                        <Icon type="Faq" size={40} color={"none"} />
                    </span>
                    <span>{texts.faq}</span>
                </a>
                <a className={styles.contentItem} href={contactUsUrl}>
                    <span className={styles.icon}>
                        <Icon type="EnvelopeBig" size={40} color={"none"} />
                    </span>
                    <span>{texts.contactUs}</span>
                </a>
            </div>
        </div>
    );
};
