import { Button, ButtonWrapper, LinkButton, Notification, useGtm } from "@afound/react";
import { FC, useState } from "react";

import { useLocalization } from "../../../../settings";
import { getAccountActivationInitiatedEventProps } from "../../../../tracking/gtm";
import { Login } from "../login";
import { IdentificationFormProps } from "../types";
import { useRegistration } from "../use-registration";
import { UserGreeting } from "../user-greeting";
import styles from "./activation-form.module.scss";

interface ActivationFormProps extends IdentificationFormProps {
   firstName: string;
   locale: string;
}

const ResendActivationEmailMessage: FC<{
   templatedText: string;
   loading: boolean;
   onResendClick: () => void;
}> = ({ templatedText, loading, onResendClick }) => {
   const firstDelimiter = templatedText.indexOf("[");
   const first = templatedText.substring(0, firstDelimiter);

   const lastDelimiter = templatedText.indexOf("]");
   const last = templatedText.substring(lastDelimiter + 1);

   const linkText = templatedText.substring(firstDelimiter + 1, lastDelimiter);

   return (
      <div className={styles.resendSection}>
         <span>{first}</span>
         <LinkButton theme="underlined" disabled={loading} onClick={onResendClick}>
            {linkText}
         </LinkButton>
         <span>{last}</span>
      </div>
   );
};

export const ActivationForm = (props: ActivationFormProps) => {
   const { email, firstName, locale } = props;

   const {
      activationFormHeading,
      activationFormMessage,
      activationFormSubmit,
      resendActivationEmail,
      ...rest
   } = useLocalization("checkout");
   const errorTexts = useLocalization("errors");

   const [loading, setLoading] = useState(false);
   const [activationRequested, setActivationRequested] = useState(false);
   const [activationError, setActivationError] = useState<string>();
   const { requestActivationEmail } = useRegistration(locale);

   const { dataLayer } = useGtm();

   const handleRequestActivation = async () => {
      setLoading(true);
      setActivationError(undefined);

      const isSuccess = await requestActivationEmail(email);
      if (!isSuccess) {
         setActivationError(errorTexts.accountActivationEmail);
      } else {
         setActivationRequested(true);
         dataLayer(getAccountActivationInitiatedEventProps());
      }

      setLoading(false);
   };

   const errorNotification = (
      <Notification
         visible={!!activationError}
         theme="error"
         className={styles.error}
         message={activationError || ""}
         onClose={() => setActivationError(undefined)}
      />
   );

   return (
      <div>
         {!activationRequested ? (
            <>
               <UserGreeting
                  heading={activationFormHeading.replace("{name}", firstName)}
                  message={activationFormMessage}
               />
               {errorNotification}
               <ButtonWrapper>
                  <Button
                     type="button"
                     theme="primary"
                     onClick={handleRequestActivation}
                     disabled={loading && !activationRequested}
                  >
                     {activationFormSubmit}
                  </Button>
               </ButtonWrapper>
            </>
         ) : (
            <div>
               {errorNotification}
               <ResendActivationEmailMessage
                  templatedText={resendActivationEmail}
                  loading={loading && activationRequested}
                  onResendClick={handleRequestActivation}
               />
               <Login email={email} texts={rest} errorTexts={errorTexts} context="activation" />
            </div>
         )}
      </div>
   );
};
