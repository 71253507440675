import React, { useContext } from "react";

export interface WizardModel {
   activeStep: number;
   configureStep: (step: number) => StepConfig;
}

interface StepConfig {
   isActive: () => boolean;
   isLocked: () => boolean;
   hasPassed: () => boolean;
   edit: () => void;
   submit: (data?: any) => void;
}

export const WizardContext = React.createContext<WizardModel>({} as any);

export const useWizardContext = () => {
   return useContext(WizardContext);
};
