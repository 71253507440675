/**
 * Google maps API functions use callbacks for async operations. This function wraps them in a
 * Promise to enable async/await syntax for less akward usage.
 * @param callbackFn The API function (must accept callback as last arg).
 * @param args The function args _excluding_ the callback arg.
 * @returns The result (returned from the API function).
 */
export const runAsync = <T>(callbackFn: Function, thisArg: any, ...args: any[]) => {
   return new Promise<T>((resolve, reject) => {
      try {
         callbackFn.apply(thisArg, [...args, resolve]);
      } catch (e) {
         reject(e);
      }
   });
};
