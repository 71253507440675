import classNames from "classnames";
import { useEffect, useState } from "react";

import { ReactComponent as Logo } from "../../icons/site-logo-dark.svg";
import styles from "./splash.module.scss";

export const Splash = () => {
   const [visible, setVisible] = useState(false);

   useEffect(() => {
      const timeout = setTimeout(() => {
         setVisible(true);
      }, 1000);

      return () => clearTimeout(timeout);
   }, []);

   const classes = classNames({
      [styles.splash]: true,
      [styles.visible]: visible,
   });

   return (
      <div className={classes}>
         <Logo />
      </div>
   );
};
