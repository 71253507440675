import { useEffect, useState } from "react";
import styles from "./countdown.module.scss";

export interface CountdownProps {
   until: Date;
   title?: string;
   labels: {
      days: string;
      hours: string;
      minutes: string;
      seconds: string;
   };
   renderExpired?: () => React.ReactNode;
   onExpired?: () => void;
}

interface TimeLeft {
   days: number;
   hours: number;
   minutes: number;
   seconds: number;
   finished: boolean;
}

export const Countdown = (props: CountdownProps) => {

   const { until, title, labels, renderExpired, onExpired } = props;
   
   let isExpired: boolean | null = null;

   const calculateTimeLeft = (until: Date): TimeLeft => {
      const now = new Date();
      const difference = new Date(until.getTime() - now.getTime());
      const wasExpired = isExpired;
      isExpired = until.getTime() < now.getTime();

      if (onExpired && wasExpired === false && isExpired === true)
         onExpired();
   
      let timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };
   
      if (!isExpired) {
         timeLeft = {
            days: difference.getUTCDate()-1,
            hours: difference.getUTCHours(),
            minutes: difference.getUTCMinutes(),
            seconds: difference.getUTCSeconds(),
         };
      }
   
      return { ...timeLeft, finished: isExpired===true };
   };
   
   const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(until));
   const { days, hours, minutes, seconds, finished } = timeLeft;

   useEffect(() => {
      if (finished) {
         return;
      }

      const timer = setTimeout(() => {
         setTimeLeft(calculateTimeLeft(props.until));
      }, 1000);

      return () => clearTimeout(timer);
   });

   return (
      <div className={styles.countdown}>
         {!finished || !renderExpired ? (
            <>
               {title && <span>{title}</span>}
               <div>
                  <span className={styles.value}>{days}</span>
                  {labels?.days}
                  <span className={styles.value}>{hours}</span>
                  {labels?.hours}
                  <span className={styles.value}>{minutes}</span>
                  {labels?.minutes}
                  <span className={styles.value}>{seconds}</span>
                  {labels?.seconds}
               </div>
            </>
         ) : (
            renderExpired()
         )}
      </div>
   );
};
