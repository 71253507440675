import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { CountryCode, parsePhoneNumber } from "libphonenumber-js";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { get, post } from "../../../api/client";
import { ApiError, ServerValidationError } from "../../../api/types";
import { error } from "../../notification/notification-slice";
import { CustomerAddresses } from "../types";

export type ServerAddressValidationResult = {
   identifier?: string;
   subscribedSuccessfully: boolean;
   billingAddressValidationResult: {
      errors: ServerValidationError[];
   };
   shippingAddressValidationResult: {
      errors: ServerValidationError[];
   };
};

export interface UpdateAddressesResult {
   customerIdentifier?: string | undefined;
   subscribedSuccessfully: boolean;
}

export const useAddresses = (locale: string) => {
   const ai = useAppInsightsContext();
   const dispatch = useDispatch();

   const fetchAddresses = async (email: string) => {
      try {
         const saved = await get<CustomerAddresses & { isValidData: boolean }>(
            `/api/v1/${locale}/checkoutstate/addresses/?email=${email}`
         );

         return saved?.isValidData ? saved : undefined;
      } catch (err) {
         ai.trackException({ exception: err as Error });
      }
   };

   const updateAddresses = async (
      addresses: CustomerAddresses,
      callingCodeCountryCode: string,
      updateErrorMessage: string,
      customerIdentifier: string | undefined,
      onServerValidationFailed: (errors: ServerAddressValidationResult) => void
   ): Promise<UpdateAddressesResult> => {
      const formatPhone = (phone: string) =>
         parsePhoneNumber(phone, callingCodeCountryCode as CountryCode).format("E.164");

      const billingWithPhone = {
         ...addresses.billingAddress,
         phoneNumber: formatPhone(addresses.billingAddress.phoneNumber),
      };

      const addr = {
         ...addresses,
         ...(customerIdentifier ? { customerIdentifier } : {}),
         billingAddress: billingWithPhone,
         shippingAddress: addresses.hasDifferentBillingAndShippingAddress
            ? {
                 ...billingWithPhone, // Currently we only collect (street) address, so use billing address for remaining fields
                 line1: addresses.shippingAddress.line1,
                 postalCode: addresses.shippingAddress.postalCode,
                 city: addresses.shippingAddress.city,
              }
            : billingWithPhone,
      };

      try {
         const apiResponse = await post<CustomerAddresses, ServerAddressValidationResult>(
            `/api/v1/${locale}/checkoutstate/addresses/`,
            addr
         );

         return {
            customerIdentifier: apiResponse?.identifier,
            subscribedSuccessfully: apiResponse?.subscribedSuccessfully ?? false,
         };
      } catch (err) {
         const apiError = err as ApiError;
         if (apiError.status === 400) {
            onServerValidationFailed(apiError.content as ServerAddressValidationResult);
         } else {
            dispatch(error(updateErrorMessage));
            ai.trackException({
               exception: err as Error,
            });

            throw err;
         }

         return { customerIdentifier: undefined, subscribedSuccessfully: false };
      }
   };

   const memoFetch = useCallback(fetchAddresses, [locale, ai]);
   const memoUpdate = useCallback(updateAddresses, [locale, ai, dispatch]);

   return { fetchAddresses: memoFetch, updateAddresses: memoUpdate };
};
