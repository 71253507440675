import React, { useContext } from "react";

import { LegalModalName } from ".";

export interface LegalModalContextValues {
   showModal: (modal: LegalModalName) => void;
}

export const LegalModalContext = React.createContext<LegalModalContextValues>({
   showModal: () => {},
});

export const useLegalModalContext = () => useContext<LegalModalContextValues>(LegalModalContext);
