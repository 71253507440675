import { Autocomplete, AutocompleteItem, InputFieldProps } from "@afound/react";
import { useFormikContext } from "formik";
import { ChangeEventHandler, FocusEventHandler } from "react";

import { Icon } from "../icon";
import { useSettings } from "../../settings";

type EmailFieldProps = Pick<InputFieldProps, "name" | "label" | "description"> & {
   locked: boolean;
   domainSuggestions: string[];
   onBlur?: FocusEventHandler;
   onChange?: ChangeEventHandler;
};

export const EmailField = (props: EmailFieldProps) => {
   const { name, label, locked, domainSuggestions, ...rest } = props;

   const { enableNewCheckoutDesign } = useSettings();

   const { values, setFieldValue } = useFormikContext();

   const filterDomains = (items: AutocompleteItem[], val?: string) => {
      const value = val || "";
      const atIndex = value.indexOf("@");

      if (!value || atIndex === -1) {
         return [];
      }

      const currentDomain = value.substring(atIndex + 1);

      return items.filter((item) => item.text.toLowerCase().includes(currentDomain.toLowerCase()));
   };

   const handleItemSelect = (item: AutocompleteItem) => {
      const currentVal = (values as any)[name];
      if (currentVal) {
         const valueWithoutDomain = currentVal.trim().substring(0, currentVal.indexOf("@"));
         setFieldValue(name, valueWithoutDomain + item.value);
      }
   };

   return (
      <Autocomplete
         {...rest}
         name={name}
         type="email"
         placeholder={label}
         renderValidIcon={() => <Icon type="Check" />}
         disabled={locked}
         formatOptions={{ forceLowerCase: true }}
         items={domainSuggestions.map((s) => ({ text: s, value: s }))}
         customFilter={filterDomains}
         onItemSelect={handleItemSelect}
      />
   );
};
