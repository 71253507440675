import { dataLayer } from "@afound/react";

import {
   getAccountActivationCompletedEventProps,
   getAccountRegistrationEventProps,
   getLoginEventProps,
   getLoginSuccessEventProps,
} from "../../tracking/gtm";
import { AuthenticationContext, TrackingInfo } from "./types";

export const pushCustomerGtmEvents = (
   authContext: AuthenticationContext,
   trackingInfo: TrackingInfo
) => {
   switch (authContext) {
      case "registration":
         dataLayer(getAccountRegistrationEventProps());
         break;
      case "activation":
         dataLayer(getAccountActivationCompletedEventProps());
         break;
      case "login":
         dataLayer(getLoginEventProps(trackingInfo.user));
         dataLayer(getLoginSuccessEventProps());
   }
};
