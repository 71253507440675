import { Breakpoints, Loader, ResponsiveContext } from "@afound/react";
import classNames from "classnames";
import { useEffect } from "react";

import { DeclinedPermissionsModal } from "../../components/declined-permissions-modal";
import { Cart } from "../../features/cart";
import { fetchCart, selectCart } from "../../features/cart/cart-slice";
import { Checkout } from "../../features/checkout";
import { error, fatal } from "../../features/notification/notification-slice";
import { initSession, selectSession } from "../../features/session/session-slice";
import { useLocalization, useSettings } from "../../settings";
import { useAppDispatch, useAppSelector } from "../../store";
import oneTrust from "../../tracking/one-trust";
import { GoogleMapsContext } from "../google-maps/google-maps-context";
import { useGoogleMaps } from "../google-maps/use-google-maps";
import { Header } from "../header";
import styles from "./page.module.scss";

const breakpoints: Breakpoints = {
   desktop: +styles.desktopWidth.replace(/px$/, ""),
};

export const Page = () => {
   const { initStatus, hasExpiredSession } = useAppSelector(selectSession);
   const { fetchStatus: cartFetchStatus } = useAppSelector(selectCart);
   const dispatch = useAppDispatch();

   const { locale } = useSettings();
   const { sessionExpired } = useLocalization("errors");

   const googleMaps = useGoogleMaps(APP_CONFIG.googleMapsApiKey);

   useEffect(() => {
      oneTrust();
   }, []);

   useEffect(() => {
      if (initStatus === "idle") {
         dispatch(initSession({ locale }));
      }

      if (cartFetchStatus === "idle") {
         dispatch(fetchCart(locale));
      }

      if (hasExpiredSession) {
         dispatch(error(sessionExpired));
      } else if (initStatus === "error" || cartFetchStatus === "error") {
         dispatch(fatal());
      }
   }, [initStatus, hasExpiredSession, cartFetchStatus, locale, sessionExpired, dispatch]);

   const isLoading = initStatus === "loading" || cartFetchStatus === "loading";

   const classes = classNames({
      [styles.page]: true,
      [styles.loading]: isLoading,
   });

   return (
      <ResponsiveContext.Provider value={breakpoints}>
         <div className={classes}>
            <Header />
            {isLoading || !googleMaps ? (
               <Loader visible />
            ) : (
               <>
                  {initStatus === "success" && !hasExpiredSession && (
                     <GoogleMapsContext.Provider value={googleMaps}>
                        <Checkout />
                     </GoogleMapsContext.Provider>
                  )}
                  <Cart />
               </>
            )}
            <DeclinedPermissionsModal />
         </div>
      </ResponsiveContext.Provider>
   );
};
