// Typed address component types. See https://developers.google.com/maps/documentation/javascript/geocoding#GeocodingAddressTypes for complete list.
export enum AddressComponentTypes {
   StreetNumber = "street_number",
   StreetName = "route",
   PostalTown = "postal_town",
   Locality = "locality",
   PostalCode = "postal_code",
}

export interface PlaceDetails {
   streetAddress: string;
   postalCode: string;
   city: string;
}
