import { ReactComponent as Alert } from "../../icons/alert.svg";
import { ReactComponent as CartPackage } from "../../icons/cart-package.svg";
import { ReactComponent as ChatBig } from "../../icons/chat-big.svg";
import { ReactComponent as CheckCircle } from "../../icons/check-circle.svg";
import { ReactComponent as Check } from "../../icons/check.svg";
import { ReactComponent as ChevronDown } from "../../icons/chevron-down.svg";
import { ReactComponent as ChevronRight } from "../../icons/chevron-right.svg";
import { ReactComponent as Close } from "../../icons/cross.svg";
import { ReactComponent as Deal } from "../../icons/deal.svg";
import { ReactComponent as Faq } from "../../icons/faq.svg";
import { ReactComponent as Delivery } from "../../icons/delivery.svg";
import { ReactComponent as DeliveryNew } from "../../icons/delivery_new.svg"; //TODO EB: swap to Edit after Checkout is released
import { ReactComponent as Eco } from "../../icons/eco.svg";
import { ReactComponent as Edit } from "../../icons/edit.svg";
import { ReactComponent as EditNew } from "../../icons/edit_new.svg"; //TODO EB: swap to Edit after Checkout is released
import { ReactComponent as Envelope } from "../../icons/envelope.svg";
import { ReactComponent as EnvelopeBig } from "../../icons/envelope-big.svg";
import { ReactComponent as FacebookLogo } from "../../icons/facebook-logo.svg";
import { ReactComponent as GoogleLogo } from "../../icons/google-logo.svg";
import { ReactComponent as HeartFilled } from "../../icons/heart-filled.svg";
import { ReactComponent as LocationPin } from "../../icons/location-pin.svg";
import { ReactComponent as MemberAccessPerk } from "../../icons/member-access.svg";
import { ReactComponent as MemberDealsPerk } from "../../icons/member-discount.svg";
import { ReactComponent as MemberFavoritesPerk } from "../../icons/member-favorite.svg";
import { ReactComponent as MemberDiscountPerk } from "../../icons/member-gift.svg";
import { ReactComponent as MemberBackInStockPerk } from "../../icons/member-mail.svg";
import { ReactComponent as Minus } from "../../icons/minus.svg";
import { ReactComponent as Package } from "../../icons/package.svg";
import { ReactComponent as Plus } from "../../icons/plus.svg";
import { ReactComponent as Return } from "../../icons/return.svg";
import { ReactComponent as ReturnReverse } from "../../icons/return-reverse.svg";
import { ReactComponent as UserFilled } from "../../icons/user-filled.svg";
import { ReactComponent as User } from "../../icons/user.svg";

export const iconMap = {
   Alert,
   CartPackage,
   ChatBig,
   Check,
   CheckCircle,
   ChevronDown,
   ChevronRight,
   Close,
   Deal,
   Delivery,
   DeliveryNew,
   Eco,
   Edit,
   EditNew,
   Envelope,
   EnvelopeBig,
   FacebookLogo,
   Faq,
   GoogleLogo,
   HeartFilled,
   LocationPin,
   MemberAccessPerk,
   MemberBackInStockPerk,
   MemberDealsPerk,
   MemberDiscountPerk,
   MemberFavoritesPerk,
   Minus,
   Plus,
   Package,
   Return,
   ReturnReverse,
   User,
   UserFilled,
};
