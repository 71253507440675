import { FormikErrors, FormikTouched } from "formik";
import { parsePhoneNumber, CountryCode, isValidPhoneNumber } from "libphonenumber-js";
import { Country, ErrorTexts, Validations } from "../../../settings";
import { BillingAddress, CustomerAddresses } from "../types";
import { ReenterInfoType } from "../use-reenter-info";
import * as yup from "yup";

const setPersistedBillingAddress = (billingAddress: BillingAddress, marketCountryCode: string) => {
   const result: BillingAddress = {
      ...billingAddress,
   };

   if (billingAddress.phoneNumber) {
      // Parse phone number, using the current market as fallback country (country calling code in phone number
      // will be used primarily to determine calling code if it exists).
      const parsedPhone = parsePhoneNumber(
         billingAddress.phoneNumber,
         marketCountryCode as CountryCode
      );
      result.callingCodeCountryCode = parsedPhone.country!;
      result.phoneNumber = parsedPhone.format("NATIONAL");
   }

   return result;
};

export const getInitialFormValues = (
   defaultEmail: string,
   country: Country,
   reenterInfoTypes: ReenterInfoType[],
   persisted: CustomerAddresses | undefined
): CustomerAddresses => {
   const isPersistedValid =
      !!persisted && !!persisted.billingAddress && !!persisted.shippingAddress;

   const defaultCallingCountryCode = country.twoLetterISORegionName;

   const billingAddress = {
      email: defaultEmail,
      phoneNumber: "",
      firstName: "",
      lastName: "",
      line1: "",
      postalCode: "",
      city: "",
      countryCode: country.threeLetterISORegionName,
      callingCodeCountryCode: defaultCallingCountryCode,
      ...(isPersistedValid
         ? setPersistedBillingAddress(persisted!.billingAddress, defaultCallingCountryCode)
         : {}),
   };

   const shippingAddress = {
      email: "",
      firstName: "",
      lastName: "",
      line1: "",
      postalCode: "",
      city: "",
      countryCode: country.threeLetterISORegionName,
      ...(isPersistedValid ? persisted!.shippingAddress : {}),
      // Phone is required in shipping address, but in UI we don't have a field for it, so keep it in sync with billing address
      phoneNumber: billingAddress.phoneNumber,
   };

   reenterInfoTypes.forEach((type) => {
      if (type === "phone") {
         billingAddress.phoneNumber = "";
      }

      if (type === "city") {
         billingAddress.city = "";
      }

      if (type === "streetAddress") {
         shippingAddress.line1 = "";
      }
   });

   return {
      hasDifferentBillingAndShippingAddress: isPersistedValid,
      hasApprovedTermsOfService: !!persisted && persisted.hasApprovedTermsOfService,
      hasSignedUpForNewsLetter: !!persisted && persisted.hasSignedUpForNewsLetter,
      departmentPreference: persisted?.departmentPreference,
      billingAddress,
      shippingAddress,
   };
};

export const getInitialErrors = (
   reenterInfoTypes: ReenterInfoType[],
   errorTexts: Pick<ErrorTexts, "required">
): [FormikErrors<CustomerAddresses>, FormikTouched<CustomerAddresses>] => {
   let errors: FormikErrors<CustomerAddresses> = {};
   let touched: FormikTouched<CustomerAddresses> = {};

   reenterInfoTypes.forEach((type) => {
      if (type === "phone") {
         errors.billingAddress = {
            ...errors.billingAddress,
            phoneNumber: errorTexts.required,
         };

         touched.billingAddress = {
            ...touched.billingAddress,
            phoneNumber: true,
         };
      }

      if (type === "streetAddress") {
         errors.shippingAddress = {
            ...errors.shippingAddress,
            line1: errorTexts.required,
         };

         touched.shippingAddress = {
            ...touched.shippingAddress,
            line1: true,
         };
      }

      if (type === "city") {
         errors.billingAddress = {
            ...errors.billingAddress,
            city: errorTexts.required,
         };

         touched.billingAddress = {
            ...touched.billingAddress,
            city: true,
         };
      }
   });

   return [errors, touched];
};

export const getAddressValidationSchema = (
   validations: Validations,
   errorTexts: Pick<
      ErrorTexts,
      | "required"
      | "invalidName"
      | "invalidPhone"
      | "invalidLine1"
      | "invalidPostalCode"
      | "invalidCity"
      | "departmentPreferenceRequired"
   >,
   validateDepartmentPreference: boolean
) => {
   const { name: nameRegex, line1, postalCode, city } = validations;
   const {
      required,
      invalidName,
      invalidPhone,
      invalidLine1,
      invalidPostalCode,
      invalidCity,
      departmentPreferenceRequired,
   } = errorTexts;

   const getSharedProps = () => ({
      line1: yup
         .string()
         .required(required)
         .trim()
         .matches(new RegExp(line1), invalidLine1)
         .max(41, invalidLine1),
      postalCode: yup
         .string()
         .required(required)
         .trim()
         .matches(new RegExp(postalCode), invalidPostalCode),
      city: yup.string().required(required).trim().matches(new RegExp(city), invalidCity),
   });

   return yup.object().shape({
      billingAddress: yup.object().shape({
         firstName: yup
            .string()
            .required(required)
            .trim()
            .matches(new RegExp(nameRegex), invalidName),
         lastName: yup
            .string()
            .required(required)
            .trim()
            .matches(new RegExp(nameRegex), invalidName),
         countryCode: yup.string().optional().trim(),
         phoneNumber: yup
            .string()
            .when(
               "$billingAddress.callingCodeCountryCode",
               ([callingCodeCountryCode], schema: yup.StringSchema) => {
                  return schema
                     .required(required)
                     .trim()
                     .test(
                        "is-phone",
                        invalidPhone,
                        (val: string | undefined) =>
                           !!val && isValidPhoneNumber(val!, callingCodeCountryCode as CountryCode)
                     );
               }
            ),
         ...getSharedProps(),
      }),
      ...(validateDepartmentPreference
         ? {
              departmentPreference: yup.string().when("hasSignedUpForNewsLetter", {
                 is: true,
                 then: (schema) => schema.required(departmentPreferenceRequired),
                 otherwise: (schema) => schema.optional(),
              }),
           }
         : {}),
      separateShipping: yup.bool().optional(),
      shippingAddress: yup.object().when("hasDifferentBillingAndShippingAddress", {
         is: true,
         then: (schema) => schema.shape({ ...getSharedProps() }),
      }),
   });
};
