import { Modal } from "@afound/react";
import { FC } from "react";

import { LegalText } from "./legal-text";

export type LegalModalName =
   | "marketingPolicy"
   | "termsAndConditions"
   | "privacyPolicy"
   | "accountPolicy"
   | "accountPrivacyPolicy";

interface LegalModalProps {
   visible: boolean;
   contentUrl: string;
   locale: string;
   onClose: () => void;
}

export const LegalModal: FC<LegalModalProps> = ({ visible, contentUrl, locale, onClose }) => (
   <Modal visible={visible} theme="beige" onClose={onClose}>
      <LegalText active={visible} contentUrl={contentUrl} locale={locale} />
   </Modal>
);
