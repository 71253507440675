import { validatePassword } from "@afound/common";
import {
   Button,
   ButtonWrapper,
   FieldRow,
   HtmlContent,
   InputField,
   Notification,
   withPasswordStrength,
} from "@afound/react";
import { Form, Formik } from "formik";
import { FC, useState } from "react";
import * as Yup from "yup";

import { Icon, IconType } from "../../../../components/icon";
import { useLegalModalContext } from "../../../../components/legal-modal/legal-modal-context";
import { ErrorTexts, useLocalization, useSettings } from "../../../../settings";
import { useAppDispatch } from "../../../../store";
import { authenticate } from "../../../session/session-slice";
import { IdentificationFormProps } from "../types";
import { useRegistration } from "../use-registration";
import { UserGreeting } from "../user-greeting";
import styles from "./registration-form.module.scss";
import classNames from "classnames";

interface RegistrationFormProps extends IdentificationFormProps {
   locale: string;
}

type RegistrationFormValues = {
   password: string;
   confirmPassword: string;
};

const getValidationSchema = (errorTexts: ErrorTexts) =>
   Yup.object({
      password: Yup.string()
         .required(errorTexts.required)
         // Use empty span as "message" since Formik requires a non-empty error to show invalid validation state,
         // and to avoid showing a visible message to align with behavior in other places where we use the password
         // strength indicator.
         .test("passwordFormat", "<span></span>", (v) => !!v && validatePassword(v)),
      confirmPassword: Yup.string()
         .required(errorTexts.required)
         .oneOf([Yup.ref("password")], errorTexts.passwordMatch),
   });

const Perk: FC<{ icon: IconType; label: string }> = ({ icon, label }) => (
   <div className={styles.perk}>
      <span className={styles.perkIcon}>
         <Icon type={icon} size={20} />
      </span>
      <span>{label}</span>
   </div>
);

export const RegistrationForm = (props: RegistrationFormProps) => {
   const { locale, email } = props;

   const dispatch = useAppDispatch();

   const {
      registerFormHeading,
      registerFormMessage,
      membershipPerk1,
      membershipPerk2,
      membershipPerk3,
      dealsPerk,
      earlyAccessPerk,
      discountCodesPerk,
      favoritesPerk,
      backInStockPerk,
      createPasswordLabel,
      passwordRequireMinLength: minLength,
      passwordRequireLowerCase: lowerCase,
      passwordRequireUpperCase: upperCase,
      passwordRequireDigit: digit,
      confirmPasswordLabel,
      registerFormSubmit,
      registrationPrivacyNotice,
      registrationSuccess,
   } = useLocalization("checkout");
   const errorTexts = useLocalization("errors");

   const { showModal } = useLegalModalContext();

   const { enableNewCheckoutDesign, enableNewMemberPerks } = useSettings();

   const [loading, setLoading] = useState(false);
   const [registerError, setRegisterError] = useState<string>();

   const { createAccount } = useRegistration(locale);

   const handleSubmit = async (values: RegistrationFormValues) => {
      setLoading(true);

      try {
         const { success, message } = await createAccount({
            email,
            password: values.password,
         });

         if (!success) {
            setRegisterError(message || errorTexts.general);
            setLoading(false);
            return;
         }

         dispatch(
            authenticate({
               email,
               password: values.password,
               rememberMe: false,
               locale,
               context: "registration",
               successMessage: registrationSuccess,
            })
         );
      } catch (err) {
         const error = err as Error;
         setRegisterError(error.message);
      }

      setLoading(false);
   };

   const PasswordField = withPasswordStrength(InputField, {
      minLength,
      lowerCase,
      upperCase,
      digit,
   });

   return (
      <Formik
         initialValues={{ password: "", confirmPassword: "" }}
         validationSchema={getValidationSchema(errorTexts)}
         onSubmit={handleSubmit}
      >
         <Form>
            <UserGreeting heading={registerFormHeading} message={registerFormMessage} />
            <div className={styles.perksWrapper}>
               <div
                  className={classNames(
                     styles.perks,
                     enableNewMemberPerks ? undefined : styles.old
                  )}
               >
                  {enableNewMemberPerks ? (
                     <>
                        <Perk icon="MemberDealsPerk" label={dealsPerk} />
                        <Perk icon="MemberAccessPerk" label={earlyAccessPerk} />
                        <Perk icon="MemberDiscountPerk" label={discountCodesPerk} />
                        <Perk icon="MemberFavoritesPerk" label={favoritesPerk} />
                        <Perk icon="MemberBackInStockPerk" label={backInStockPerk} />
                     </>
                  ) : (
                     <>
                        <Perk icon="Deal" label={membershipPerk1} />
                        <Perk icon="HeartFilled" label={membershipPerk2} />
                        <Perk icon="Delivery" label={membershipPerk3} />
                     </>
                  )}
               </div>
            </div>

            <FieldRow className={styles.passwordFieldRow}>
               <PasswordField
                  className={
                     enableNewCheckoutDesign ? styles.passwordFieldNew : styles.passwordField
                  }
                  name="password"
                  label={createPasswordLabel}
                  errorAsHtml
               />
            </FieldRow>
            <InputField type="password" name="confirmPassword" label={confirmPasswordLabel} />

            <Notification
               className={styles.error}
               visible={!!registerError}
               theme="error"
               message={registerError || ""}
               onClose={() => setRegisterError(undefined)}
            />

            <ButtonWrapper>
               <Button theme="primary" type="submit" disabled={loading}>
                  {registerFormSubmit}
               </Button>
            </ButtonWrapper>

            <HtmlContent
               className={styles.terms}
               html={registrationPrivacyNotice}
               links={[() => showModal("termsAndConditions"), () => showModal("privacyPolicy")]}
            />
         </Form>
      </Formik>
   );
};
