import { selectCart } from "../../features/cart/cart-slice";
import { useLocalization, useSettings } from "../../settings";
import { useAppSelector } from "../../store";
import { Icon } from "../icon";
import styles from "./external-login.module.scss";
import { LoginButton } from "./login-button";

export const ExternalLogin = () => {
   const { externalLoginProvider } = useLocalization("shared");
   const {
      locale,
      externalLoginSettings: {
         isFacebookLoginEnabled,
         isGoogleLoginEnabled,
         authenticationSourceLabel,
      },
   } = useSettings();
   const { cart } = useAppSelector(selectCart);

   return (
      <div className={styles.wrapper}>
         {isFacebookLoginEnabled && (
            <LoginButton
               authUrl={`/api/externallogin/initiate?provider=Facebook&source=${authenticationSourceLabel}&locale=${locale}&market=${cart?.marketName}`}
               className={styles.facebook}
            >
               <Icon type="FacebookLogo" size={34} />
               <span className={styles.buttonTextContainerAndText}>
                  {externalLoginProvider} Facebook
               </span>
            </LoginButton>
         )}
         {isGoogleLoginEnabled && (
            <LoginButton
               authUrl={`/api/externallogin/initiate?provider=Google&source=${authenticationSourceLabel}&locale=${locale}&market=${cart?.marketName}`}
               className={styles.google}
            >
               <Icon type="GoogleLogo" size={24} />
               <span className={styles.buttonTextContainerAndText}>
                  {externalLoginProvider} Google
               </span>
            </LoginButton>
         )}
      </div>
   );
};
