import { LinkButton } from "@afound/react";

import { selectAuthenticated } from "../../features/session/session-slice";
import { ReactComponent as Logo } from "../../icons/site-logo-dark.svg";
import { useLocalization, useSettings } from "../../settings";
import { useAppSelector } from "../../store";
import { BackButton } from "../back-button";
import { Icon } from "../icon";
import InformationBar from "../information-bar";
import styles from "./header.module.scss";

export const Header = () => {
   const {
      storeSiteUrl,
      locale,
      contentUrls: { myPages: myPagesUrl },
   } = useSettings();

   const isAuthenticated = useAppSelector(selectAuthenticated);

   const { logIn, continueShopping } = useLocalization("shared");

   return (
      <header className={styles.header}>
         <InformationBar url={storeSiteUrl} locale={locale} />
         <nav className={styles.nav}>
            <div className={styles.navGroup}>
               <div className={styles.navItem}>
                  <BackButton>
                     <Logo className={styles.logo} />
                  </BackButton>
               </div>
               <BackButton className={styles.navItem}>
                  <Icon type="ChevronDown" rotate={90} />
                  <span>{continueShopping}</span>
               </BackButton>
            </div>
            <div className={styles.navGroup}>
               {isAuthenticated && (
                  <LinkButton className={styles.navItem} url={myPagesUrl} target="_blank">
                     <Icon
                        type={isAuthenticated ? "UserFilled" : "User"}
                        size={isAuthenticated ? 24 : 20}
                     />
                     {!isAuthenticated && <span>{logIn}</span>}
                  </LinkButton>
               )}
            </div>
         </nav>
      </header>
   );
};
