import { PaymentMethod } from "@adyen/adyen-web/dist/types/types";

import { PaymentMethodResponseObject } from "./types";

export const getAdditionalPaymentMethodConfiguration = (
   response: PaymentMethodResponseObject,
   countryCode: string
) => {
   const paymentMethods = response.paymentMethodsResponse.paymentMethods;

   return {
      ...getApplePayConfig(
         response,
         countryCode,
         paymentMethods?.find((p) => p.type === "applepay")
      ),
      ...getGooglePayConfig(
         response,
         countryCode,
         paymentMethods?.find((p) => p.type === "paywithgoogle")
      ),
   };
};

const getApplePayConfig = (
   response: PaymentMethodResponseObject,
   countryCode: string,
   applePay?: PaymentMethod
) => {
   if (!applePay) {
      return {};
   }

   return {
      applepay: {
         amount: response.amount,
         countryCode,
      },
   };
};

const getGooglePayConfig = (
   response: PaymentMethodResponseObject,
   countryCode: string,
   googlePay?: PaymentMethod
) => {
   if (!googlePay) {
      return {};
   }

   return {
      paywithgoogle: {
         amount: response.amount,
         countryCode,
         environment: response.environment.toLowerCase() === "live" ? "PRODUCTION" : "TEST",
      },
   };
};
