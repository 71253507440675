import { FC } from "react";

import styles from "./user-greeting.module.scss";

interface UserGreetingProps {
   heading: string;
   message: string;
}

export const UserGreeting: FC<UserGreetingProps> = ({ heading, message }) => (
   <div className={styles.greeting}>
      <h3>{heading}</h3>
      <span>{message}</span>
   </div>
);
