const oneTrust = () => {
   window.Afound = window.Afound || {};
   window.Afound.OneTrust = {
      intervals: {
         data_collection: null,
      },
      init: function () {
         window.Afound.OneTrust.intervals.data_collection = setInterval(function () {
            if (
               window.Afound.data_collection != null &&
               typeof window.Afound.data_collection != "undefined"
            ) {
               clearInterval(window.Afound.OneTrust.intervals.data_collection);
            }
         }, 200);
      },
      on_consent_changed: function () {
         // called from GTM
         fetch("/api/v1/onetrust/onconsentchanged")
            .then(function (resp) {
               return resp.json();
            })
            .then(function (json) {
               window.dataLayer.push({ event: "afoundOnConsentChanged", adc: json.ccm });
            });
      },
      loaded_fired: false,
      on_loaded: function () {
         if (window.Afound.OneTrust.loaded_fired) return;

         fetch("/api/v1/onetrust/onloaded")
            .then(function (resp) {
               return resp.json();
            })
            .then(function (json) {
               window.Afound.OneTrust.loaded_fired = true;
               window.dataLayer.push({ event: "afoundOnConsentLoaded", adc: json.ccm });
            });
      },
   };

   window.Afound.OneTrust.init();
};

export default oneTrust;
