import { Notification } from "@afound/react";
import classNames from "classnames";

import { useLocalization, useSettings } from "../../../settings";
import { Carriers, CarriersProps } from "./carriers";
import styles from "./shipping.module.scss";
import { Icon } from "../../../components/icon";

export const DEFAULT_CARRIER_CODE = "default";

interface ShipmentProps extends CarriersProps {
   shipmentLabel: string;
   locale: string;
   showUnableToDeliverMessage: boolean;
}

export const Shipment = (props: ShipmentProps) => {
   const { cartSeller, shipmentIndex, shipmentLabel, showUnableToDeliverMessage } = props;

   const { title, products } = cartSeller;

   const { unavailableDeliveryForSeller } = useLocalization("errors");
   const { oneItemTemplate, multipleItemTemplate, soldAndShippedBy } = useLocalization("cart");
   const { enableNewCheckoutDesign } = useSettings();

   const headerClasses = classNames({
      [styles.shipmentHeader]: !enableNewCheckoutDesign,
      [styles.shipmentHeaderNew] : enableNewCheckoutDesign,
      [styles.faded]: showUnableToDeliverMessage,
   });

   const quantity = enableNewCheckoutDesign
      ? products.reduce((sum, current) => sum + current.quantity, 0)
      : 0;

   const quantityLabel = quantity === 1 ? oneItemTemplate : multipleItemTemplate;

   return (
      <div className={styles.shipment}>
         {enableNewCheckoutDesign ? (
            <>
               <div className={headerClasses}>
                  <Icon type="Package" color="none" />
                  <span className={styles.packageHeader}>
                     {shipmentLabel} {shipmentIndex + 1}
                  </span>
                  <span className={styles.packageQuantity}>
                     {`(${quantity} ${quantityLabel})`}
                  </span>
               </div>
               <div className={styles.shippedBy}>
                  <span>{soldAndShippedBy}</span>
                  <span>{title}</span>
               </div>
            </>
         ) : (
            <div className={headerClasses}>
               <span>
                  {shipmentLabel} {shipmentIndex + 1}
               </span>
               <h3>{title}</h3>
            </div>
         )}
         {showUnableToDeliverMessage ? (
            <Notification
               visible
               theme="error"
               message={unavailableDeliveryForSeller}
               closeable={false}
            />
         ) : (
            <Carriers {...props} />
         )}
      </div>
   );
};
