import { get } from "@afound/common";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useCallback } from "react";

import { useLocalization } from "../../../settings/localization";
import { updateShippingOptions } from "../../cart/cart-slice";
import { Cart } from "../../cart/types";
import { error } from "../../notification/notification-slice";
import { CarrierAgentShippingOption, SelectedShippingOption, ShippingOption } from "../types";
import { useAppDispatch } from "../../../store";

export const useShipping = (isShippingAssistantEnabled: boolean, locale: string) => {
   const ai = useAppInsightsContext();
   const dispatch = useAppDispatch();
   const { updateShippingOptionGeneralError, getShippingOptionGeneralError } =
      useLocalization("errors");

   const fetchShippingOptions = async (cart: Cart) => {
      const fallbackOptions = cart.shops.map(
         (shop) =>
            ({
               sellerId: shop.sellerId,
               sellerName: shop.title,
            } as ShippingOption)
      );

      if (!isShippingAssistantEnabled) {
         return fallbackOptions;
      }

      try {
         return await get<ShippingOption[]>(`/api/v1/${locale}/shipping/options`);
      } catch (err) {
         ai.trackException({
            exception: err as Error,
         });
         dispatch(error(getShippingOptionGeneralError));

         return null;
      }
   };

   const updateSelectedShippingOption = async (
      shippingOptions: ShippingOption[],
      selectedShippingOptions: SelectedShippingOption[],
      cartId: number
   ) => {
      if (!isShippingAssistantEnabled || selectedShippingOptions.length === 0) {
         return true;
      }

      try {
         //get necessary data from "master" object
         let map: { [sellerId: string]: CarrierAgentShippingOption } = {};
         selectedShippingOptions.forEach((selectedOption) => {
            const configuredShippingOption = shippingOptions.find(
               (so) => so.sellerId === selectedOption.sellerId
            );

            if (!configuredShippingOption || !configuredShippingOption.carriers) {
               return true;
            }

            const configuredCarrier = configuredShippingOption.carriers.find(
               (c) => c.code === selectedOption.code
            )!;
            const selectedAgentIndex = +selectedOption.agentIndex;
            const configuredAgent = configuredCarrier.agents[selectedAgentIndex];

            map[configuredShippingOption.sellerId] = {
               ...configuredAgent,
               shippingCost: configuredCarrier.shippingCost,
               deliveryBy: configuredCarrier.name,
               deliveryTime: configuredCarrier.estimatedDeliveryText,
            };
         });

         const {
            meta: { requestStatus },
         } = await dispatch(
            updateShippingOptions({
               locale: locale,
               cartId: cartId,
               selectedOptions: map,
            })
         );

         return requestStatus === "fulfilled";
      } catch (err) {
         ai.trackException({
            exception: err as Error,
         });
         dispatch(error(updateShippingOptionGeneralError));

         return false;
      }
   };

   const memoFetch = useCallback(fetchShippingOptions, [
      ai,
      locale,
      isShippingAssistantEnabled,
      getShippingOptionGeneralError,
      dispatch,
   ]);
   const memoUpdate = useCallback(updateSelectedShippingOption, [
      ai,
      locale,
      isShippingAssistantEnabled,
      updateShippingOptionGeneralError,
      dispatch,
   ]);

   return { fetchShippingOptions: memoFetch, updateSelectedShippingOption: memoUpdate };
};
