import { createAsyncThunk, createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";

import { get, put } from "../../api/client";
import { ApiResponse, ApiStatus } from "../../api/types";
import { AppStartListening } from "../../middleware/listener";
import { RootState } from "../../store";
import { getCartModifiedEventProps, getRemovedFromCartEventProps } from "../../tracking/gtm";
import { Cart } from "./types";

export interface CartState {
   fetchStatus: ApiStatus;
   validateStatus: ApiStatus;
   modifyStatus: ApiStatus;
   couponStatus: ApiStatus;
   updateShippingStatus: ApiStatus;
   cart?: Cart;
   applyCouponError?: string;
   locked: boolean;
}

export const initialState: CartState = {
   fetchStatus: "idle",
   validateStatus: "idle",
   modifyStatus: "idle",
   couponStatus: "idle",
   updateShippingStatus: "idle",
   cart: undefined,
   applyCouponError: undefined,
   locked: false,
};

export const fetchCart = createAsyncThunk(
   "cart/fetch",
   async (locale: string) => await get<Cart>(`/api/v1/${locale}/cart/`)
);

export const validateCart = createAsyncThunk(
   "cart/validate",
   async (locale: string) => await get<Cart>(`/api/v1/${locale}/cart/`)
);

export const changeQuantity = createAsyncThunk(
   "cart/quantity",
   async (payload: { locale: string; offerCode: string; quantity: number }) => {
      const { locale, offerCode, quantity } = payload;

      return await put<{}, Cart>(
         `/api/v1/${locale}/cart/quantities/?offerCode=${offerCode}&quantity=${quantity}`,
         {}
      );
   }
);

export const applyCoupon = createAsyncThunk(
   "cart/applyCoupon",
   async (payload: { locale: string; couponCode: string }, { rejectWithValue }) => {
      const { locale, couponCode } = payload;

      const response = await put<{}, ApiResponse<Cart>>(
         `/api/v1/${locale}/cart/coupon/apply?couponCode=${couponCode}`,
         {}
      );

      if (!response?.result) {
         return rejectWithValue(response!.errorMessage);
      }

      return response.result;
   }
);

export const removeCoupon = createAsyncThunk(
   "cart/removeCoupon",
   async (payload: { locale: string; couponCode: string }, { rejectWithValue }) => {
      const { locale, couponCode } = payload;

      const response = await put<{}, ApiResponse<Cart>>(
         `/api/v1/${locale}/cart/coupon/remove?couponCode=${couponCode}`,
         {}
      );

      if (!response?.result) {
         return rejectWithValue(response!.errorMessage);
      }

      return response.result;
   }
);

export const updateSize = createAsyncThunk(
   "cart/updateSize",
   async (
      payload: { locale: string; newOfferId: string; oldOfferId: string; quantity: number },
      { rejectWithValue }
   ) => {
      const { locale, newOfferId, oldOfferId, quantity } = payload;

      const response = await put<{}, ApiResponse<Cart>>(
         `/api/v1/${locale}/cart/size/update?newOfferId=${newOfferId}&oldOfferId=${oldOfferId}&quantity=${quantity}`,
         {}
      );

      if (!response?.result) {
         return rejectWithValue(response!.errorMessage);
      }

      return response.result;
   }
);

export const updateShippingOptions = createAsyncThunk(
   "cart/updateShippingOptions",
   async (
      payload: { locale: string; cartId: number; selectedOptions: { [sellerId: string]: {} } },
      { rejectWithValue }
   ) => {
      try {
         const { locale, cartId, selectedOptions } = payload;
         const jsonBody = JSON.stringify(selectedOptions);
         const response = await put<{}, ApiResponse<Cart>>(
            `/api/v1/${locale}/shipping/update?cartId=${cartId}`,
            jsonBody
         );

         if (!response?.result) {
            return rejectWithValue(response!.errorMessage);
         }

         return response.result;
      } catch {
         return rejectWithValue("Unhandled error");
      }
   }
);

export const cartSlice = createSlice({
   name: "cart",
   initialState,
   reducers: {
      lock: (state, action: PayloadAction<boolean>) => {
         state.locked = action.payload;
      },
   },
   extraReducers: (builder) => {
      builder
      .addCase(fetchCart.pending, (state) => {
        state.fetchStatus = "loading";
      })
      .addCase(fetchCart.fulfilled, (state, action: PayloadAction<Cart | undefined>) => {
        state.fetchStatus = "success";
        state.cart = action.payload;
      })
      .addCase(fetchCart.rejected, (state) => {
        state.fetchStatus = "error";
      })

      .addCase(validateCart.pending, (state) => {
        state.validateStatus = "loading";
      })
      .addCase(validateCart.fulfilled, (state, action: PayloadAction<Cart | undefined>) => {
        state.validateStatus = "success";
        state.cart = action.payload;
      })
      .addCase(validateCart.rejected, (state) => {
        state.validateStatus = "error";
      })

      .addCase(applyCoupon.pending, (state) => {
        state.couponStatus = "loading";
      })
      .addCase(applyCoupon.fulfilled, (state, action: PayloadAction<Cart | undefined>) => {
        state.couponStatus = "success";
        state.cart = action.payload;
      })
      .addCase(applyCoupon.rejected, (state, action: PayloadAction<any>) => {
        state.couponStatus = "error";
        state.applyCouponError = action.payload;
      })

      .addCase(removeCoupon.pending, (state) => {
        state.couponStatus = "loading";
      })
      .addCase(removeCoupon.fulfilled, (state, action: PayloadAction<Cart | undefined>) => {
        state.couponStatus = "success";
        state.cart = action.payload;
      })
      .addCase(removeCoupon.rejected, (state, action: PayloadAction<any>) => {
        state.couponStatus = "error";
        state.applyCouponError = action.payload;
      })
      
      .addCase(updateShippingOptions.fulfilled, (state, action: PayloadAction<Cart | undefined>) => {
        state.updateShippingStatus = "success";
        state.cart = action.payload;
      })
      .addCase(updateShippingOptions.rejected, (state) => {
        state.updateShippingStatus = "error";
      })
      .addCase(updateShippingOptions.pending, (state) => {
        state.updateShippingStatus = "loading";
      })
    
      .addMatcher(
         isAnyOf(changeQuantity.pending, updateSize.pending),
         (state) => {
           state.modifyStatus = "loading";
         }
       )
       .addMatcher(
         isAnyOf(changeQuantity.fulfilled, updateSize.fulfilled),
         (state, action: PayloadAction<Cart | undefined>) => {
           state.modifyStatus = "success";
           state.cart = action.payload;
         }
       )
       .addMatcher(
         isAnyOf(changeQuantity.rejected, updateSize.rejected),
         (state) => {
           state.modifyStatus = "error";
         }
       )
    
   },
});

export const { lock } = cartSlice.actions;

export const selectCart = (s: RootState) => s.cart;

export const selectCartEmpty: (s: RootState) => boolean = ({ cart }) => {
   return !!cart.cart && cart.cart.shops.reduce((prev, s) => prev + s.products.length, 0) === 0;
};

export default cartSlice.reducer;

export const addCartListeners = (startListening: AppStartListening) => {
   startListening({
      matcher: isAnyOf(fetchCart.fulfilled, changeQuantity.fulfilled),
      effect: (action: PayloadAction<Cart | undefined>, listenerApi) => {
         if (!action.payload) {
            return;
         }

         switch (action.type) {
            case fetchCart.fulfilled.type: {
               const { hasBeenChangedDueToStock } = action.payload;

               if (hasBeenChangedDueToStock) {
                  window.dataLayer.push(getCartModifiedEventProps());
               }

               break;
            }
            case changeQuantity.fulfilled.type: {
               const prevCart = listenerApi.getOriginalState().cart.cart;
               const currentCart = listenerApi.getState().cart.cart;

               if (!prevCart || !currentCart) {
                  return;
               }

               const prevItemCount = prevCart.shops.reduce(
                  (prev, s) => prev + s.products.length,
                  0
               );
               const currentItemCount = currentCart.shops.reduce(
                  (prev, s) => prev + s.products.length,
                  0
               );

               if (currentItemCount < prevItemCount) {
                  window.dataLayer.push(getRemovedFromCartEventProps(currentCart));
               }

               break;
            }
         }
      },
   });
};
