import { FC } from "react";

import { Address, CustomerAddresses } from "../../types";
import styles from "./address-fields.module.scss";
import { useSettings } from "../../../../settings";

const AddressItem: FC<{ heading: string; address: Address }> = ({ heading, address }) => (
   <div className={styles.summaryItem}>
      <h4>{heading}</h4>
      <span data-cs-mask="">
         {address.firstName} {address.lastName}
      </span>
      <span data-cs-mask="">{address.line1}</span>
      <span data-cs-mask="">
         {address.postalCode} {address.city}
      </span>
      <span data-cs-mask="">{address.countryCode}</span>
      <span>&nbsp;</span>
      <span data-cs-mask="">{address.phoneNumber}</span>
   </div>
);

//TODO EB: replace the old AddressItem after new Checkout goes live
const AddressItemNew: FC<{ address: Address }> = ({ address }) => (
   <div className={styles.summaryItem}>
      <span data-cs-mask="">
         {address.firstName} {address.lastName}
      </span>
      <span data-cs-mask="">{address.phoneNumber}</span>
      <span data-cs-mask="">
         {address.line1} {address.postalCode.concat(",")} {address.city.concat(",")}{" "}
         {address.countryCode}
      </span>
   </div>
);

export const AddressSummary: FC<{
   addresses?: CustomerAddresses;
   billingLabel: string;
   shippingLabel: string;
}> = ({ addresses, billingLabel, shippingLabel }) => {
   const { enableNewCheckoutDesign } = useSettings();

   return addresses ? (
      enableNewCheckoutDesign ? (
         <div data-clarity-mask="True">
            <AddressItemNew address={addresses.billingAddress} />
         </div>
      ) : (
         <div className={styles.summary} data-clarity-mask="True">
            <AddressItem heading={billingLabel} address={addresses.billingAddress} />
            <AddressItem
               heading={shippingLabel}
               address={
                  addresses.hasDifferentBillingAndShippingAddress
                     ? addresses.shippingAddress
                     : addresses.billingAddress
               }
            />
         </div>
      )
   ) : null;
};
