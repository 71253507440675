import { LinkButton, Price, RadioButton, Select } from "@afound/react";
import { formatDateRange } from "@afound/common";
import { useFormikContext } from "formik";
import { useState } from "react";

import { Icon } from "../../../components/icon";
import { useLocalization, useSettings } from "../../../settings";
import { CartSeller } from "../../cart/types";
import { Carrier, SelectedShippingOption } from "../types";
import styles from "./shipping.module.scss";

export interface CarriersProps {
   locale: string;
   cartSeller: CartSeller;
   shipmentIndex: number;
   currency: string;
   carriers: Carrier[];
   initialExpandedCarrier: string | undefined;
   isLoading: boolean;
   handleOnSelectedShippingOption: (
      selectedShippingOptions: SelectedShippingOption[]
   ) => Promise<boolean>;
}

const ShippingIcon = (props: { url: string }) => {
   const { url } = props;
   const { enableNewCheckoutDesign } = useSettings();

   return (
      <span className={enableNewCheckoutDesign ? styles.shippingIconNew : styles.shippingIcon}>
         <img src={url} />
      </span>
   );
};

const CarrierItem = (props: Carrier & { locale: string; currency: string }) => {
   const { name, isEco, iconUrls, estimatedDeliveryText, shippingCost, currency, locale } = props;
   return (
      <div className={styles.carrierItem}>
         <div className={styles.carrierRow}>
            <span>{name}</span>
            {isEco && (
               <span className={styles.ecoIconWrapper}>
                  <Icon type={"Eco"} size={16} />
               </span>
            )}
            <span className={styles.priceWrapper}>
               <Price value={shippingCost} currency={currency} locale={locale} plain />
            </span>
         </div>
         <div className={styles.carrierRow}>
            <span>{estimatedDeliveryText}</span>
            <span className={styles.shippingIconWrapper}>
               {iconUrls?.map((iconUrl, i) => (
                  <ShippingIcon key={i} url={iconUrl} />
               ))}
            </span>
         </div>
      </div>
   );
};

//TODO EB: replace an old CarrierItem after new Checkout release
const CarrierItemNew = (props: Carrier & { locale: string; currency: string }) => {
   const { name, isEco, iconUrls, estimatedDeliveryText, shippingCost, currency, locale } = props;
   return (
      <div className={styles.carrierItem}>
         <div className={styles.carrierRowNew}>
            <span>
               {iconUrls?.map((iconUrl, i) => (
                  <ShippingIcon key={i} url={iconUrl} />
               ))}
            </span>
            <span className={styles.deliveryText}>
               {name?.concat(",")} {estimatedDeliveryText}
            </span>
            {isEco && (
               <span className={styles.ecoIconWrapper}>
                  <Icon type={"Eco"} size={16} />
               </span>
            )}
            <span className={styles.priceWrapperNew}>
               <Price
                  value={shippingCost}
                  currency={currency}
                  locale={locale}
                  plain
                  className={styles.shippingPrice}
               />
            </span>
         </div>
      </div>
   );
};

export const Carriers = (props: CarriersProps) => {
   const {
      locale,
      cartSeller,
      shipmentIndex,
      carriers,
      initialExpandedCarrier,
      currency,
      handleOnSelectedShippingOption,
   } = props;

   const {
      deliveryBy,
      deliveryTime,
      deliveryStartDate,
      deliveryEndDate,
      calculatedShippingFeeAmount,
      sellerId,
   } = cartSeller;

   const { freeShipping } = useLocalization("cart");

   const { setFieldValue } = useFormikContext<SelectedShippingOption[]>();

   const { enableNewCheckoutDesign } = useSettings();

   const { showLessShippingOptions, showMoreShippingOptions, selectPickupPoint } =
      useLocalization("checkout");
   const [showPickupPointsForCarrier, setShowPickupPointsForCarrier] = useState(
      initialExpandedCarrier ?? (carriers.length ? carriers[0].code : "")
   );
   const defaultNrOfCarriersToShow = 10;
   const [nrOfCarriersToShow, setNrOfCarriersToShow] = useState(defaultNrOfCarriersToShow);

   const deliveryTimeText = cartSeller
      ? `${formatDateRange(deliveryStartDate, locale)} -
           ${formatDateRange(deliveryEndDate, locale)}`
      : "";

   const setFieldValues = (sellerIndex: number, carrierGroupCode: string, agentIndex: string) => {
      setFieldValue(`selectedShippingOptions.${sellerIndex}.code`, carrierGroupCode);
      setFieldValue(`selectedShippingOptions.${sellerIndex}.agentIndex`, agentIndex);
   };

   const handleRadioButtonToggle = async (sellerIndex: number, carrierGroupCode: string) => {
      setShowPickupPointsForCarrier(carrierGroupCode);
      setFieldValues(sellerIndex, carrierGroupCode, "0");

      await handleOnSelectedShippingOption([
         {
            sellerId: sellerId,
            code: carrierGroupCode,
            agentIndex: "0",
         },
      ]);
   };

   const handleCarrierPagination = () => {
      if (nrOfCarriersToShow < carriers.length) {
         setNrOfCarriersToShow(nrOfCarriersToShow + defaultNrOfCarriersToShow);
      } else {
         setNrOfCarriersToShow(nrOfCarriersToShow - defaultNrOfCarriersToShow);
      }
   };

   return carriers.length > 0 ? (
      <>
         {carriers.slice(0, nrOfCarriersToShow).map((carrier, i) => (
            <div
               key={`wrapper-${i}`}
               className={
                  enableNewCheckoutDesign ? styles.carrierWrapperNew : styles.carrierWrapper
               }
            >
               <RadioButton
                  name={`selectedShippingOptions.${shipmentIndex}.code`}
                  value={carrier.code}
                  onChange={async () => await handleRadioButtonToggle(shipmentIndex, carrier.code)}
                  label={
                     enableNewCheckoutDesign ? (
                        <CarrierItemNew {...carrier} locale={locale} currency={currency} />
                     ) : (
                        <CarrierItem {...carrier} locale={locale} currency={currency} />
                     )
                  }
               />
               {showPickupPointsForCarrier === carrier.code && carrier.agents[0].storeId && (
                  <div key={`carrier-${i}`} className={styles.selectWrapper}>
                     <label>{selectPickupPoint}</label>
                     <Select
                        name={`selectedShippingOptions.${shipmentIndex}.agentIndex`}
                        small={true}
                        options={carrier.agents.map((agent, aidx) => ({
                           text: `${agent.storeName}, ${agent.address} ${agent.postCode}`,
                           value: `${aidx}`,
                        }))}
                        onChange={(event) =>
                           setFieldValues(shipmentIndex, carrier.code, event.target.value)
                        }
                     />
                  </div>
               )}
            </div>
         ))}
         {defaultNrOfCarriersToShow < carriers.length && (
            <div className={styles.submit}>
               <LinkButton onClick={handleCarrierPagination}>
                  <span className={styles.carrierPaginationButton}>
                     {nrOfCarriersToShow < carriers.length
                        ? showMoreShippingOptions
                        : showLessShippingOptions}
                  </span>
               </LinkButton>
            </div>
         )}
      </>
   ) : (
      <div className={enableNewCheckoutDesign ? styles.shipmentDeatilsNew : styles.shipmentDetails}>
         {enableNewCheckoutDesign ? (
            <div className={styles.deliveryNew}>
               <Icon type="CheckCircle" />
               <span className={styles.deliveryBy}>
                  {deliveryBy ? deliveryBy?.concat(",") : ""}
               </span>
               <span className={styles.deliveryTimeNew}>{deliveryTimeText}</span>
               {calculatedShippingFeeAmount === 0 ? (
                  <span className={styles.deliveryPrice}>{freeShipping}</span>
               ) : (
                  <Price
                     value={calculatedShippingFeeAmount}
                     currency={currency}
                     locale={locale}
                     plain
                     className={styles.deliveryPrice}
                  />
               )}
            </div>
         ) : (
            <>
               <Icon type="CheckCircle" />
               <div className={styles.delivery}>
                  <span className="u-bold">{deliveryBy}</span>
                  <span className={styles.deliveryTime}>{deliveryTime}</span>
               </div>
               {calculatedShippingFeeAmount === 0 ? (
                  freeShipping
               ) : (
                  <Price
                     value={calculatedShippingFeeAmount}
                     currency={currency}
                     locale={locale}
                     plain
                  />
               )}
            </>
         )}
      </div>
   );
};
