import { Price, Carousel } from "@afound/react";
import { formatDateRange } from "@afound/common";
import styles from "./order-summary-item-content.module.scss";
import { Icon, IconType } from "../../../../../components/icon";
import { useLocalization, useSettings } from "../../../../../settings";
import { Carrier } from "../../../types";
import { CartItem, CartSeller } from "../../../../cart/types";
import { FC } from "react";
interface OrderSummaryItemContentProps {
   keyId: number;
   selectedCarrier: Carrier[] | undefined;
   cartSeller: CartSeller | undefined;
   currency: string | undefined;
   shipmentHeader: string;
}

const SummaryInfoLine: FC<{ icon: IconType; infoLineText: string | undefined }> = ({
   icon,
   infoLineText,
}) => (
   <div className={styles.summaryInfoLine}>
      <Icon type={icon} color="none" />
      <span>{infoLineText},</span>
   </div>
);

const ProductImage: FC<{ product: CartItem }> = ({ product }) => {
   const { size, quantity: quantityText } = useLocalization("cart");
   return (
      <>
         <img
            className={styles.image}
            src={`${product.imageUrl}?preset=logo`}
            alt={product.title}
         />
         <div className={styles.imageInfo}>
            {size} : {product.sellerSize}
         </div>
         <div className={styles.imageInfo}>
            {quantityText}: {product.quantity}
         </div>
      </>
   );
};

export const OrderSummaryItemContent = (props: OrderSummaryItemContentProps) => {
   const { keyId, selectedCarrier, cartSeller, currency } = props;

   const { locale } = useSettings();
   const { freeShipping, shippingPolicyLabel, returnFeeLabel, freeReturnFee } =
      useLocalization("cart");

   const products = cartSeller?.products ?? [];
   const deliveryTime = cartSeller
      ? `${formatDateRange(cartSeller.deliveryStartDate, locale)} -
           ${formatDateRange(cartSeller.deliveryEndDate, locale)}`
      : "";

   return (
      <div className={styles.orderSummaryContent}>
         <div className={styles.orderSummaryInfo}>
            {cartSeller?.deliveryBy && (
               <SummaryInfoLine
                  icon="LocationPin"
                  infoLineText={selectedCarrier ? selectedCarrier[0].name : cartSeller.deliveryBy}
               />
            )}

            <SummaryInfoLine
               icon="DeliveryNew"
               infoLineText={
                  selectedCarrier ? selectedCarrier[0].estimatedDeliveryText : deliveryTime
               }
            />
            <div className={styles.summaryInfoLine}>
               <div className={styles.orderFee}>
                  <Icon type="Return" color="none" />
                  {(selectedCarrier && selectedCarrier[0].shippingCost === 0) ||
                  cartSeller?.calculatedShippingFeeAmount === 0 ? (
                     <span>{freeShipping}</span>
                  ) : (
                     <>
                        <span>{shippingPolicyLabel}</span>
                        <Price
                           value={
                              selectedCarrier
                                 ? selectedCarrier[0].shippingCost
                                 : cartSeller?.calculatedShippingFeeAmount ?? 0
                           }
                           currency={currency ?? ""}
                           locale={locale}
                           plain
                        />
                     </>
                  )}
               </div>
               <div className={styles.orderFee}>
                  <Icon type="ReturnReverse" color="none" />
                  {cartSeller?.returnFeeAmount === 0 ? (
                     <span>{freeReturnFee}</span>
                  ) : (
                     <>
                        <span>{returnFeeLabel}</span>
                        <Price
                           value={cartSeller?.returnFeeAmount ?? 0}
                           currency={currency ?? ""}
                           locale={locale}
                           plain
                        />
                     </>
                  )}
               </div>
            </div>
         </div>
         <div className={styles.imageContainer}>
            <div className={styles.swiperContainer}>
               <Carousel
                  keyId={"Id" + keyId}
                  slidesPerView={5}
                  spaceBetween={10}
                  slidesPerGroup={4}
                  disableNavigation
               >
                  {products.map((product, idx) => (
                     <ProductImage key={idx} product={product} />
                  ))}
               </Carousel>
            </div>
         </div>
      </div>
   );
};
