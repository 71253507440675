import classNames from "classnames";

import { Icon } from "../icon";
import styles from "./banner.module.scss";

export type BannerTheme = "mint" | "beige" | "pink";

interface BannerProps {
   label: string;
   theme?: BannerTheme;
   url?: string;
}

export const Banner = (props: BannerProps) => {
   const { label, theme = "mint", url } = props;

   const classes = classNames({
      [styles.banner]: true,
      [styles[theme]]: true,
   });

   const Inner = (props: { label: string }) => (
      <>
         <span>{props.label}</span>
         <span className={styles.icon}>
            <Icon type="ChevronRight" size={20} />
         </span>
      </>
   );

   return (
      <div className={classes}>
         {url ? (
            <a className={styles.label} href={url}>
               <Inner label={label} />
            </a>
         ) : (
            <div className={styles.label}>
               <Inner label={label} />
            </div>
         )}
      </div>
   );
};
