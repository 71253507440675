import { createAsyncThunk, createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";

import { get, post } from "../../api/client";
import { ApiStatus } from "../../api/types";
import { AppStartListening } from "../../middleware/listener";
import { RootState } from "../../store";
import { fetchCart } from "../cart/cart-slice";
import { success } from "../notification/notification-slice";
import { pushCustomerGtmEvents } from "./tracking";
import {
   AuthenticationContext,
   InitSessionResultWithContext,
   PersistedState,
   ServerState,
   Session,
   TrackingInfo,
} from "./types";

export interface SessionState {
   initStatus: ApiStatus;
   lookupStatus: ApiStatus;
   authenticateStatus: ApiStatus;
   authenticationFailureReason?: string;
   authenticationFailureReasonType?: string;
   hasExpiredSession: boolean;
   session: Session;
   persistedState?: PersistedState;
   trackingInfo?: TrackingInfo;
   optimizelyEnabled?: boolean;
}

export const initialState: SessionState = {
   initStatus: "idle",
   lookupStatus: "idle",
   authenticateStatus: "idle",
   authenticationFailureReason: undefined,
   authenticationFailureReasonType: undefined,
   hasExpiredSession: false,
   session: {
      email: "",
      customerFirstName: undefined,
      authenticationInfo: {
         isAuthenticated: false,
      },
      customerIdentifier: "",
      hasCredentials: false,
      accountStatus: undefined,
      returnerType: undefined,
   },
   persistedState: undefined,
   trackingInfo: undefined,
   optimizelyEnabled: false,
};

export const initSession = createAsyncThunk(
   "customer/initsession",
   async (request: { locale: string; context?: string; successMessage?: string }) => {
      const { locale, context, successMessage } = request;

      const {
         state: persistedState,
         dataLayer: trackingInfo,
         optimizelyEnabled,
      } = (await get<{
         state: ServerState;
         dataLayer: TrackingInfo;
         optimizelyEnabled: boolean;
      }>(`/api/v1/${locale}/checkoutstate/`)) || {};

      const session = await get<Session>(`/api/v1/${locale}/authentication/sessions/current/`);

      return {
         persistedState,
         trackingInfo,
         session,
         optimizelyEnabled,
         authContext: context,
         successMessage,
      } as InitSessionResultWithContext;
   }
);

export const lookupCustomer = createAsyncThunk(
   "customer/lookupCustomer",
   async (request: { locale: string; email: string }) => {
      const response = await post<{}, Session>(`/api/v1/${request.locale}/authentication/lookup/`, {
         email: request.email,
      });

      return { response, requestEmail: request.email };
   }
);

export const authenticate = createAsyncThunk(
   "customer/authenticate",
   async (
      request: {
         email: string;
         password: string;
         rememberMe: boolean;
         locale: string;
         context: AuthenticationContext;
         successMessage?: string;
      },
      thunkAPI
   ) => {
      const { email, password, rememberMe, locale, context, successMessage } = request;

      const authResponse = await post<{}, Session>(`/api/v1/${locale}/authentication/`, {
         email,
         password,
         rememberMe,
         languageName: locale,
      });

      if (!authResponse?.authenticationInfo?.isAuthenticated) {
         return authResponse;
      }

      thunkAPI.dispatch(initSession({ locale, context, successMessage }));
      thunkAPI.dispatch(fetchCart(locale));

      return authResponse;
   }
);

const sessionSlice = createSlice({
   name: "user",
   initialState,
   reducers: {
      expired: (state) => {
         state.hasExpiredSession = true;
      },
   },
   extraReducers: (builder) => {
      builder.addCase(initSession.pending, (state) => {
         state.initStatus = "loading";
      });
      builder.addCase(initSession.fulfilled, (state, action) => {
         const { session, persistedState, trackingInfo, optimizelyEnabled } = action.payload;

         state.initStatus = "success";
         state.session = session!;

         if (persistedState) {
            const { consents, ...rest } = persistedState;

            state.persistedState = {
               ...rest,
               hasApprovedTermsOfService: consents?.hasApprovedTermsOfService || false,
               hasSignedUpForNewsLetter: consents?.hasSignedUpForNewsLetter || false,
            };
         }

         state.trackingInfo = trackingInfo;
         state.optimizelyEnabled = optimizelyEnabled;
      });
      builder.addCase(initSession.rejected, (state) => {
         state.initStatus = "error";
      });

      builder.addCase(lookupCustomer.pending, (state) => {
         state.lookupStatus = "loading";
      });
      builder.addCase(lookupCustomer.fulfilled, (state, action) => {
         state.lookupStatus = "success";

         // Lookup will return empty response if user doesn't exist
         state.session = {
            ...state.session,
            ...(action.payload.response ||
               ({
                  email: action.payload.requestEmail,
                  hasCredentials: false,
               } as Session)),
         };
      });
      builder.addCase(lookupCustomer.rejected, (state) => {
         state.lookupStatus = "error";
      });

      builder.addCase(authenticate.pending, (state) => {
         state.authenticateStatus = "loading";
      });
      builder.addCase(authenticate.fulfilled, (state, action) => {
         if (!action.payload?.authenticationInfo?.isAuthenticated) {
            state.authenticateStatus = "error";
            state.authenticationFailureReason = action.payload?.authenticationInfo?.failureReason;
            state.authenticationFailureReasonType =
               action.payload?.authenticationInfo?.failureReasonType;
         } else {
            state.authenticateStatus = "success";
            state.session = action.payload;
            state.hasExpiredSession = false;
         }
      });
      builder.addCase(authenticate.rejected, (state) => {
         state.authenticateStatus = "error";
      });
   },
});

export const { expired } = sessionSlice.actions;

export const selectSession = (s: RootState) => s.session;
export const selectAuthenticated = (s: RootState) =>
   !!s.session.session?.authenticationInfo?.isAuthenticated;

export default sessionSlice.reducer;

export const addSessionListeners = (startListening: AppStartListening) => {
   startListening({
      matcher: isAnyOf(initSession.fulfilled),
      effect: (action: PayloadAction<InitSessionResultWithContext>, listenerApi) => {
         const { authContext, successMessage: notifyMessage } = action.payload;
         const trackingInfo = listenerApi.getState().session.trackingInfo;

         if (notifyMessage) {
            listenerApi.dispatch(success(notifyMessage));
         }

         if (authContext && trackingInfo) {
            pushCustomerGtmEvents(authContext, trackingInfo);
         }
      },
   });
};
