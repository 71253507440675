import { configureAppInsights } from "@afound/react";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

let appInsights: ApplicationInsights;

export const initAppInsights = (instrumentationKey: string) => {
   const [reactPlugin, ai] = configureAppInsights(instrumentationKey);
   appInsights = ai;
   return reactPlugin;
};

export const globalAppInsights = () => appInsights;
