import { Checkbox, FieldRow, InputField, Select, useBreakpoints } from "@afound/react";
import { countries } from "countries-list";
import { FormikErrors, useFormikContext } from "formik";
import classNames from "classnames";
import {
   CountryCallingCode,
   CountryCode,
   getCountryCallingCode,
   isSupportedCountry,
   parsePhoneNumber,
} from "libphonenumber-js";
import React, { useMemo } from "react";

import { GooglePlacesAutocomplete } from "../../../../components/google-places-autocomplete";
import { PlaceDetails } from "../../../../components/google-places-autocomplete/types";
import { AddressTexts, useSettings } from "../../../../settings";
import { Address, CustomerAddresses } from "../../types";
import styles from "./address-fields.module.scss";
import { AddressSummary } from "./address-summary";

interface AddressFieldsProps {
   marketCountryCode: string;
   callingCodeCountryCode: string;
   showAsSummary: boolean;
   addressTexts: AddressTexts & {
      billingLabel: string;
      shippingLabel: string;
      phoneNumberDescription: string;
   };
   onCallingCodeChange: (countryCode: string) => void;
}

interface CallingCodeCountry {
   countryCode: string;
   countryName: string;
   callingCode: CountryCallingCode;
}

const getCallingCodeCountry = (countryCode: string) =>
   ({
      countryCode,
      countryName: countries[countryCode as keyof typeof countries].name,
      callingCode: getCountryCallingCode(countryCode as CountryCode),
   } as CallingCodeCountry);

const getCountryCallingCodes = () => {
   const countryTwoLetterCodes = Object.keys(countries);
   return countryTwoLetterCodes
      .filter((c) => isSupportedCountry(c))
      .map((c) => getCallingCodeCountry(c))
      .sort((a, b) => (a.countryName < b.countryName ? -1 : a.countryName > b.countryName ? 1 : 0));
};

export const AddressFields = (props: AddressFieldsProps) => {
   const {
      marketCountryCode,
      callingCodeCountryCode,
      showAsSummary,
      addressTexts,
      onCallingCodeChange,
   } = props;

   const {
      firstName,
      lastName,
      line1,
      postalCode,
      city,
      phoneNumber,
      shipToSeparateAddress, //TODO EB: remove after new Checkout is live
   } = addressTexts;

   const { enableNewCheckoutDesign } = useSettings();

   const { values, errors, handleBlur, setFieldValue } = useFormikContext<CustomerAddresses>();

   const handlePhoneNumberBlur = (
      ev: React.FocusEvent,
      field: keyof Pick<CustomerAddresses, "billingAddress" | "shippingAddress">
   ) => {
      handleBlur(ev);

      if (
         (!!errors[field] && (errors[field] as FormikErrors<Address>).phoneNumber) ||
         // When phone field is blurred immediately (before touching other fields), Formik's errors
         // object won't have updated yet, so for this edge case we check that there is a value instead.
         (!!values[field] && !values[field].phoneNumber)
      ) {
         return;
      }

      const phone = values[field].phoneNumber;
      const formatted = parsePhoneNumber(phone, callingCodeCountryCode as CountryCode).format(
         "NATIONAL"
      );
      setFieldValue(`${field}.phoneNumber`, formatted);
   };

   const handleGoogleMapsItemSelect = (
      addressNamePrefix: "billingAddress" | "shippingAddress",
      placeDetails: PlaceDetails
   ) => {
      const { streetAddress, postalCode, city } = placeDetails;

      setFieldValue(`${addressNamePrefix}.line1`, streetAddress, true);
      setFieldValue(`${addressNamePrefix}.postalCode`, postalCode, true);
      setFieldValue(`${addressNamePrefix}.city`, city, true);
   };

   const callingCodeOptions = useMemo(
      () =>
         getCountryCallingCodes().map((c) => ({
            text: `${c.countryName} +(${c.callingCode})`,
            value: c.countryCode,
         })),
      []
   );

   const { current } = useBreakpoints();

   const countryCodeClasses = classNames(styles.callingCode, styles.billingAddress);

   return showAsSummary ? (
      <AddressSummary
         addresses={values}
         billingLabel={enableNewCheckoutDesign ? "" : addressTexts.billingLabel}
         shippingLabel={enableNewCheckoutDesign ? "" : addressTexts.shippingLabel}
      />
   ) : (
      <>
         <FieldRow>
            <InputField name="billingAddress.firstName" placeholder={firstName} />
            <InputField name="billingAddress.lastName" placeholder={lastName} />
         </FieldRow>
         <GooglePlacesAutocomplete
            name="billingAddress.line1"
            placeholder={line1}
            countryCode={marketCountryCode}
            onItemSelect={(p) => handleGoogleMapsItemSelect("billingAddress", p)}
            className={enableNewCheckoutDesign ? styles.billingAddress : undefined}
         />
         <FieldRow sizes={[1, 2]}>
            <InputField name="billingAddress.postalCode" placeholder={postalCode} />
            <InputField name="billingAddress.city" placeholder={city} />
         </FieldRow>
         {current === "desktop" ? (
            <>
               <FieldRow sizes={[1, 2]}>
                  <Select
                     className={enableNewCheckoutDesign ? countryCodeClasses : styles.callingCode}
                     name="billingAddress.callingCodeCountryCode"
                     options={callingCodeOptions}
                     onChange={(ev) => onCallingCodeChange(ev.target.value)}
                  />
                  <InputField
                     name="billingAddress.phoneNumber"
                     placeholder={phoneNumber}
                     description={addressTexts.phoneNumberDescription}
                     onBlur={(ev: React.FocusEvent) => handlePhoneNumberBlur(ev, "billingAddress")}
                  />
               </FieldRow>
            </>
         ) : (
            <>
               <Select
                  className={styles.callingCode}
                  name="billingAddress.callingCodeCountryCode"
                  options={callingCodeOptions}
                  onChange={(ev) => onCallingCodeChange(ev.target.value)}
               />
               <InputField
                  name="billingAddress.phoneNumber"
                  placeholder={phoneNumber}
                  description={addressTexts.phoneNumberDescription}
                  onBlur={(ev: React.FocusEvent) => handlePhoneNumberBlur(ev, "billingAddress")}
               />
            </>
         )}
         {!enableNewCheckoutDesign ? (
            <>
               <Checkbox name="hasDifferentBillingAndShippingAddress">
                  {shipToSeparateAddress}
               </Checkbox>
               {values.hasDifferentBillingAndShippingAddress && (
                  <div>
                     <GooglePlacesAutocomplete
                        name="shippingAddress.line1"
                        placeholder={line1}
                        countryCode={marketCountryCode}
                        onItemSelect={(p) => handleGoogleMapsItemSelect("shippingAddress", p)}
                     />
                     <FieldRow sizes={[1, 2]}>
                        <InputField name="shippingAddress.postalCode" placeholder={postalCode} />
                        <InputField name="shippingAddress.city" placeholder={city} />
                     </FieldRow>
                  </div>
               )}
            </>
         ) : (
            <></>
         )}
      </>
   );
};
