import { Price } from "@afound/react";
import { Fragment } from "react";

import { Banner, BannerTheme } from "../../../components/banner";
import { Countdown } from "../../../components/countdown";
import { Icon } from "../../../components/icon";
import { useLocalization } from "../../../settings";
import { useAppDispatch, useAppSelector } from "../../../store";
import { CartProduct } from "../cart-product";
import { removeCoupon, changeQuantity, selectCart } from "../cart-slice";
import { CartSeller } from "../types";
import styles from "./seller-items.module.scss";

interface SellerItemsProps extends CartSeller {
   num: number;
   currency: string;
   locale: string;
   hasAppliedCoupons: boolean;
   hasEmployeeDiscount: boolean;
   onFlashDealExpired?: () => void;
}

const getPromotionTheme = (subType: string) => {
   const themeLookup: { [key: string]: BannerTheme } = {
      threefortwo: "mint",
      threshold: "pink",
   };

   return themeLookup[subType] || "mint";
};

export const SellerItems = (props: SellerItemsProps) => {
   const {
      num,
      hasAppliedCoupons,
      hasEmployeeDiscount,
      title,
      products,
      currency,
      locale,
      deliveryBy,
      deliveryTime,
      shippingFeeAmount,
      couponCode,
      calculatedShippingFeeAmount,
      couponDiscountValueFormatted,
      couponSaving,
      employeeDiscountPercentage,
      employeeDiscountSaving,
      promotions,
      recommendedPromotions,
      onFlashDealExpired,
   } = props;

   const {
      freeShipping,
      couponLabel,
      employeeDiscountLabel,
      flashDealLabel,
      flashDealExpired,
      dateShorthands,
   } = useLocalization("cart");
   const { shipment } = useLocalization("shared");

   const { couponStatus } = useAppSelector(selectCart);
   const dispatch = useAppDispatch();

   const handleProductRemoved = (offerCode: string) => {
      dispatch(changeQuantity({ locale, offerCode, quantity: 0 }));
   };

   const handleCouponRemoved = () => {
      dispatch(removeCoupon({ locale, couponCode: couponCode! }));
   };

   return (
      <>
         <div className={styles.header}>
            <span>
               {shipment} {num}
            </span>
            <h2 className={styles.title}>{title}</h2>
         </div>

         <div className={styles.promotionRecommendations}>
            {recommendedPromotions.map((r, idx) => (
               <Banner
                  key={idx}
                  label={r.label}
                  url={r.promotionPageUrl}
                  theme={getPromotionTheme(r.subType)}
               />
            ))}
         </div>

         {products.map((item) => (
            <Fragment key={item.offerCode}>
               {item.isFlashDeal && item.lineItemPriceEndDate && (
                  <Countdown
                     until={new Date(item.lineItemPriceEndDate)}
                     title={flashDealLabel}
                     labels={dateShorthands}
                     renderExpired={() => <span>{flashDealExpired}</span>}
                     onExpired={onFlashDealExpired}
                  />
               )}
               <CartProduct
                  currency={currency}
                  product={item}
                  onRemoveClick={handleProductRemoved}
               />
            </Fragment>
         ))}

         <div className={styles.extras}>
            <span>{deliveryBy}</span>
            <span className="u-bold">{deliveryTime}</span>
            {shippingFeeAmount === 0 && freeShipping}
            {calculatedShippingFeeAmount === 0 && shippingFeeAmount > 0 && (
               <Price currency={currency} locale={locale} value={shippingFeeAmount} reduced />
            )}
            {calculatedShippingFeeAmount > 0 && (
               <Price
                  currency={currency}
                  locale={locale}
                  value={calculatedShippingFeeAmount}
                  plain
               />
            )}
         </div>

         {hasAppliedCoupons && couponSaving > 0 && (
            <div className={styles.extras}>
               <span>
                  {couponLabel}&nbsp;
                  <span className="u-bold">{couponDiscountValueFormatted}</span>
               </span>
               <span>
                  <Price currency={currency} locale={locale} value={couponSaving * -1} />
                  <button
                     type="button"
                     className={styles.removeCoupon}
                     disabled={couponStatus === "loading"}
                     onClick={handleCouponRemoved}
                  >
                     <Icon type="Close" size={16} />
                  </button>
               </span>
            </div>
         )}

         {hasEmployeeDiscount && employeeDiscountSaving > 0 && (
            <div className={styles.extras}>
               <span>
                  {employeeDiscountLabel}&nbsp;
                  <span className="u-bold">{employeeDiscountPercentage} %</span>
               </span>
               <Price currency={currency} locale={locale} value={employeeDiscountSaving * -1} />
            </div>
         )}

         {promotions.map((promo, idx) => (
            <div className={styles.extras} key={`promo_${idx}`}>
               <span>{promo.label}&nbsp;</span>
               <Price currency={currency} locale={locale} value={promo.totalSaving * -1} />
            </div>
         ))}
      </>
   );
};
