import { PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { Middleware } from "redux";

import { globalAppInsights } from "../ai";

type Action = PayloadAction<any, string, any, SerializedError>;

const isRejectedAction = <TAction>(action: TAction | Action): action is Action => {
   const actionTyped = action as Action;
   return !!actionTyped.type && actionTyped.type.endsWith("rejected");
};

const isNonCritialError = (error: Error) => /status 40(0|1)/.test(error.message);

const constructAIException = (name: string, error: Error) => {
   return {
      name,
      message: error.message,
      stack: error.stack,
   } as Error;
};

export const logToAppInsightsMiddleware: Middleware = (_) => (next) => (action: unknown) => {
   if (isRejectedAction(action)) {
      // Fetch error object from payload for thunks implementing rejectWithValue. Otherwise get
      // it from the default error prop.
      const err: Error = action.payload ? action.payload : action.error;
      const ai = globalAppInsights();
      isNonCritialError(err)
         ? ai.trackTrace({
              message: err.message,
           })
         : ai.trackException({
              exception: constructAIException(action.type, err),
           });
   }

   return next(action);
};
