import { Accordion, Price, useBreakpoints } from "@afound/react";
import classNames from "classnames";
import { useEffect, useRef } from "react";

import { Icon } from "../../components/icon";
import { useLocalization, useSettings } from "../../settings";
import { useAppDispatch, useAppSelector } from "../../store";
import { standard } from "../notification/notification-slice";
import { selectSession } from "../session/session-slice";
import { fetchCart, selectCart, selectCartEmpty } from "./cart-slice";
import { CartSummary } from "./cart-summary";
import styles from "./cart.module.scss";
import { CouponForm } from "./coupon-form";
import { EmptyCartOld } from "./empty-cart";
import { SellerItems } from "./seller-items";
import { InnerCart } from "./inner-cart";

export const Cart = () => {
   const { cart, locked } = useAppSelector(selectCart);
   const { hasExpiredSession, session } = useAppSelector(selectSession);
   const isEmpty = useAppSelector(selectCartEmpty);
   const hasChanges = !!cart && cart.hasBeenChanged;
   const hasUpdatedAfterMerge = !!cart && cart.hasBeenUpdatedAfterMerge;

   const dispatch = useAppDispatch();

   const {
      locale,
      storeSiteUrl,
      contentUrls: { pricingFaq },
      hideCouponForGuestsEnabled,
      enableNewCheckoutDesign,
   } = useSettings();
   const { current } = useBreakpoints();

   const { continueShopping } = useLocalization("shared");
   const {
      cartEmpty,
      cartChanged,
      cartUpdatedAfterMerge,
      openOrderSummary,
      closeOrderSummary,
      oneItemTemplate = "",
      multipleItemTemplate = "",
      couponsForMembersOnly,
   } = useLocalization("cart");

   const initialExpanded = useRef(false);

   useEffect(() => {
      if (hasChanges) {
         dispatch(standard(cartChanged));
      } else if (hasUpdatedAfterMerge) {
         dispatch(standard(cartUpdatedAfterMerge));
      }
   }, [hasChanges, hasUpdatedAfterMerge, cartChanged, cartUpdatedAfterMerge, dispatch]);

   useEffect(() => {
      const query = new URLSearchParams(window.location.search);
      initialExpanded.current = query.get("exp") === "1";
   }, []);

   const handleFlashDealExpired = () => dispatch(fetchCart(locale));
   const isAuthenticated = session.authenticationInfo.isAuthenticated;
   const hideCouponForGuests = hideCouponForGuestsEnabled && !isAuthenticated;
   //TO DO: Need to do a check here and probabily change to login as link
   const modifiedCouponsForMembersOnly = couponsForMembersOnly.replace(/{{|}}/g, "");

   const innerCartOld = (
      <div>
         {!!cart ? (
            <>
               {cart.shops.map((s, idx) => (
                  <SellerItems
                     key={s.sellerId}
                     {...s}
                     num={idx + 1}
                     currency={cart.currency}
                     locale={locale}
                     hasAppliedCoupons={cart.hasCoupon}
                     hasEmployeeDiscount={cart.hasEmployeeDiscount}
                     onFlashDealExpired={handleFlashDealExpired}
                  />
               ))}
               {!cart.hasPromotion &&
                  (!hideCouponForGuests ? (
                     <CouponForm disabled={locked} />
                  ) : (
                     <div className={styles.couponForMembersMessage}>
                        {modifiedCouponsForMembersOnly}
                     </div>
                  ))}
               <CartSummary cart={cart} locale={locale} pricingFaqUrl={pricingFaq} />
            </>
         ) : null}
      </div>
   );

   return (
      <section className={enableNewCheckoutDesign ? styles.cartNew : styles.cart}>
         {!enableNewCheckoutDesign && (isEmpty || hasExpiredSession) ? (
            <EmptyCartOld
               emptyText={cartEmpty}
               continueShoppingText={continueShopping}
               continueShoppingUrl={storeSiteUrl}
            />
         ) : current === "mobile" ? (
            <Accordion
               className={styles.mobileCart}
               initialExpanded={initialExpanded.current}
               skipAnimation={initialExpanded.current}
               header={(toggle, _, expanded) => {
                  const iconClasses = classNames({
                     [styles.accordionIcon]: true,
                     [styles.expanded]: expanded,
                  });

                  const itemCount = !cart
                     ? 0
                     : cart.shops.reduce((prev, s) => prev + s.products.length, 0);

                  const itemsLabel = itemCount === 1 ? oneItemTemplate : multipleItemTemplate;

                  return cart ? (
                     <button type="button" className={styles.mobileHeader} onClick={toggle}>
                        <span className={styles.mobileHeaderTitle}>
                           {expanded ? closeOrderSummary : openOrderSummary}&nbsp;
                           {`(${itemCount} ${itemsLabel})`}
                        </span>
                        <span className={styles.mobileHeaderPrices}>
                           <Price
                              value={cart.totalBeforeSavings}
                              currency={cart.currency}
                              locale={locale}
                              reduced
                           />
                           <Price value={cart.total} currency={cart.currency} locale={locale} />
                           <span className={iconClasses}>
                              <Icon type="ChevronDown" />
                           </span>
                        </span>
                     </button>
                  ) : (
                     <span />
                  );
               }}
            >
               {enableNewCheckoutDesign ? <InnerCart cart={cart!}></InnerCart> : innerCartOld}
            </Accordion>
         ) : enableNewCheckoutDesign ? (
            <InnerCart cart={cart!}></InnerCart>
         ) : (
            innerCartOld
         )}
      </section>
   );
};
