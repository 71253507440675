import { Button } from "@afound/react";
import classNames from "classnames";
import { ReactNode } from "react";

import styles from "./login-button.module.scss";

interface LoginButtonProps {
   authUrl: string;
   className?: string;
   children: ReactNode;
}

export const LoginButton = (props: LoginButtonProps) => {
   const { authUrl, className, children } = props;

   const classes = classNames(styles.button, className);

   return (
      <Button wide theme="secondary" className={classes} url={authUrl}>
         {children}
      </Button>
   );
};
