import { ExternalLogin } from "../../../../components/external-login";
import { useLocalization, useSettings } from "../../../../settings";
import { Login } from "../login";
import { IdentificationFormProps } from "../types";
import { UserGreeting } from "../user-greeting";

interface LoginFormProps extends IdentificationFormProps {
   firstName: string;
}

export const LoginForm = (props: LoginFormProps) => {
   const { email, firstName } = props;

   const { externalLoginSettings } = useSettings();

   const { loginFormHeading, loginFormMessage, ...rest } = useLocalization("checkout");
   const errorTexts = useLocalization("errors");

   return (
      <div>
         <UserGreeting
            heading={loginFormHeading.replace("{name}", firstName)}
            message={loginFormMessage}
         />
         <Login email={email} texts={rest} errorTexts={errorTexts} context="login" />
         {externalLoginSettings.isEnabled && <ExternalLogin />}
      </div>
   );
};
