import { ApiError, HttpMethod, ResponseInterceptor } from "./types";

interface Options {
   headers?: { [key: string]: string };
}

const isPromise = (val: any): val is Promise<any> =>
   typeof val === "object" && typeof val.then === "function";

const getErrorContent = async (resp: Response) => {
   const contentType = resp.headers.get("Content-Type");

   if (contentType?.startsWith("application/json")) {
      return await resp.json();
   } else if (contentType?.startsWith("text/plain")) {
      return await resp.text();
   }

   return undefined;
};

const defaultInterceptor = async (response: Response, method: HttpMethod, requestUrl: string) => {
   // Note: fetch() will essentially only throw errors when there is a network error. HTTP error
   // statuses will still resolve successfully, so we need to check response status instead.
   if (!response.ok) {
      let errContent: string | object;

      try {
         errContent = await getErrorContent(response);
      } catch {
         errContent = "Attempt to read response error content failed.";
      }

      throw new ApiError(
         `HTTP ${method} to ${requestUrl} failed with status ${response.status}. ${
            errContent ? "Server response was: " + errContent : ""
         }`,
         response.status,
         errContent
      );
   }
};

const responseInterceptors: ResponseInterceptor[] = [defaultInterceptor];

const client = async <TReturn, TBody extends object = {}>(
   method: HttpMethod,
   url: string,
   config: Options & { body?: TBody } = {}
) => {
   const { headers, body } = config;

   const fetchConfig: RequestInit = {
      method,
      mode: "cors",
      headers: { "Content-Type": "application/json", ...headers },
      body: body ? JSON.stringify(body) : undefined,
      credentials: "include",
   };

   let data;
   const response = await window.fetch(url, fetchConfig);

   if (responseInterceptors.length) {
      for (let i = responseInterceptors.length - 1; i >= 0; i--) {
         const val = responseInterceptors[i](response, method, url);
         if (isPromise(val)) {
            await val;
         }
      }
   }

   const contentLength = (await response.clone().text()).length;
   if (contentLength > 0) {
      data = (await response.json()) as TReturn;
   }

   return data;
};

export const get = <T>(url: string, options?: Options) => client<T>("GET", url, options);

export const post = <TBody extends object, TReturn = any>(
   url: string,
   body: TBody,
   options?: Options
) => client<TReturn, TBody>("POST", url, { ...(options || {}), body });

export const put = <TBody extends object, TReturn = any>(
   url: string,
   body: TBody,
   options?: Options
) => client<TReturn, TBody>("PUT", url, { ...(options || {}), body });

export const addResponseInterceptor = (interceptor: ResponseInterceptor) =>
   responseInterceptors.push(interceptor);
