import { InputField, InputFieldProps } from "@afound/react";
import { useEffect, useState } from "react";

import styles from "./google-autocomplete.module.scss";
import { PredictionDropdown } from "./prediction-dropdown";
import { AddressComponentTypes, PlaceDetails } from "./types";
import { usePlacesAutocomplete } from "./use-places-autocomplete";

type GooglePlacesAutocompleteProps = InputFieldProps & {
   countryCode: string;
   onItemSelect: (address: PlaceDetails) => void;
};

const mapToPlaceDetails = (addressComponents: google.maps.GeocoderAddressComponent[]) => {
   let streetNumber = "";
   let streetName = "";
   let city = "";
   let postalCode = "";

   addressComponents.forEach((ac) => {
      const componentType = ac.types[0];

      switch (componentType) {
         case AddressComponentTypes.StreetNumber:
            streetNumber = ac.long_name;
            break;
         case AddressComponentTypes.StreetName:
            streetName = ac.long_name;
            break;
         case AddressComponentTypes.PostalTown:
         case AddressComponentTypes.Locality:
            city = ac.long_name;
            break;
         case AddressComponentTypes.PostalCode:
            postalCode = ac.long_name;
      }
   });

   return {
      streetAddress: `${streetName} ${streetNumber}`,
      postalCode: postalCode?.replace(" ", ""),
      city,
   } as PlaceDetails;
};

export const GooglePlacesAutocomplete = (props: GooglePlacesAutocompleteProps) => {
   const { countryCode, onItemSelect, ...inputFieldProps } = props;
   const [searchValue, setSearchValue] = useState("");
   const [showPredictions, setPredictions] = useState(false);
   const [activeIndex, setActiveIndex] = useState(-1);

   const { predictions, fetchPlaceDetails } = usePlacesAutocomplete(searchValue, countryCode);

   useEffect(() => {
      if (predictions.length < 1) {
         return;
      }

      setPredictions(true);
   }, [predictions]);

   const handleOnChange = (ev: React.ChangeEvent<any>) => {
      const searchValue = ev.target.value;
      if (searchValue.trim().length < 1) {
         setPredictions(false);
      }

      setSearchValue(searchValue);
   };

   const handleOnKeyDown = (ev: React.KeyboardEvent<any>) => {
      const activeItem = predictions[activeIndex];

      switch (ev.key) {
         case "Enter": {
            if (activeItem) {
               handleOnItemSelect(activeItem);
            }

            ev.preventDefault();
            break;
         }
         case "Tab": {
            if (activeItem) {
               handleOnItemSelect(activeItem);
            } else {
               handleOnClose();
            }
            break;
         }
         case "ArrowUp":
            setActiveIndex(Math.max(0, activeIndex - 1));
            break;
         case "ArrowDown":
            setActiveIndex(Math.min(activeIndex + 1, predictions.length - 1));
            break;
      }
   };

   const handleOnClose = () => setPredictions(false);

   const handleOnItemSelect = async (prediction: google.maps.places.AutocompletePrediction) => {
      const addressComponents = await fetchPlaceDetails(prediction.place_id);

      setPredictions(false);
      setSearchValue("");

      onItemSelect(mapToPlaceDetails(addressComponents));
   };

   return (
      <div className={styles.wrapper}>
         <InputField
            {...(inputFieldProps as InputFieldProps)}
            autoComplete="off"
            onChange={handleOnChange}
            onKeyDown={handleOnKeyDown}
         />
         {showPredictions && (
            <PredictionDropdown
               searchValue={searchValue}
               predictions={predictions}
               activeIndex={activeIndex}
               onItemSelect={handleOnItemSelect}
               onClose={handleOnClose}
            />
         )}
      </div>
   );
};
