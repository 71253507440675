import "typeface-roboto";

import { GtmContextProvider, NotificationBar, withAppInsights } from "@afound/react";
import { OptimizelyProvider } from "@optimizely/react-sdk";
import { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";

import { initAppInsights } from "./ai";
import { addResponseInterceptor } from "./api/client";
import { ErrorPage } from "./components/error-page";
import { Page } from "./components/page";
import { Splash } from "./components/splash";
import { reset, selectNotification } from "./features/notification/notification-slice";
import { expired, selectSession } from "./features/session/session-slice";
import { SettingsContext, useInitSettings } from "./settings";
import { store, useAppDispatch, useAppSelector } from "./store";
import { useOptimizely } from "./tracking/optimizely";

const reactPlugin = initAppInsights(APP_CONFIG.appInsightsKey);

const ApplicationWrapper = () => {
   const { type, message } = useAppSelector(selectNotification);
   const theme = type === "none" || type === "fatal" ? undefined : type;

   const { trackingInfo, optimizelyEnabled } = useAppSelector(selectSession);
   const dispatch = useAppDispatch();

   const fetchedSettings = useInitSettings();
   const [optimizely, userId] = useOptimizely(APP_CONFIG.optimizelySdkKey, reactPlugin);

   useEffect(() => {
      addResponseInterceptor((resp) => {
         if (resp.status === 401) {
            dispatch(expired());
         }
      });
   }, [dispatch]);

   const handleErrorNotificationClose = () => dispatch(reset());

   return type === "fatal" ? (
      <ErrorPage />
   ) : !fetchedSettings ? (
      <Splash />
   ) : (
      <HelmetProvider>
         <Helmet
            htmlAttributes={{
               lang: fetchedSettings.locale,
            }}
         >
            <script
               src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
               data-document-language="true"
               type="text/javascript"
               charSet="UTF-8"
               data-domain-script={fetchedSettings.oneTrustDomainScriptKey}
            ></script>
            <script type="text/javascript">{`function OptanonWrapper() { }`}</script>
            {optimizelyEnabled && (
               <link rel="preload" href="//cdn-pci.optimizely.com/js/21297620389.js" as="script" />
            )}
            {optimizelyEnabled && <link rel="preconnect" href="//logx.optimizely.com" />}
            {optimizelyEnabled && (
               <script src="https://cdn-pci.optimizely.com/js/21297620389.js"></script>
            )}
            <script
               src={fetchedSettings.ecomWidgetUrl}
               data-selector=".ecomid-widget-container"
               data-media-query="none"
               data-lang={fetchedSettings.locale}
               data-return-fee-faq-link={fetchedSettings.contentUrls?.faq}
               data-hide-return-fee-card="true"
               data-consent={fetchedSettings.trackingConsent}
               data-logo-size="xxs"
               data-cta-font-weight="700"
               data-font-family="p12-desc,Helvetica Narrow,Arial Narrow,Tahoma,Arial,Helvetica,sans-serif"
               data-background-color="#FFFFFF"
               data-padding="12px 12px 12px 18px"
               data-gap="6px"
               data-z-index="5001"
               defer
               crossOrigin="anonymous"
            ></script>
         </Helmet>
         <GtmContextProvider gtmId={APP_CONFIG.gtmId} data={trackingInfo}>
            <OptimizelyProvider optimizely={optimizely} user={{ id: userId }}>
               <SettingsContext.Provider value={fetchedSettings}>
                  <NotificationBar
                     visible={type !== "none"}
                     theme={theme}
                     message={message || ""}
                     closeable
                     onClose={handleErrorNotificationClose}
                  />
                  <Page />
               </SettingsContext.Provider>
            </OptimizelyProvider>
         </GtmContextProvider>
      </HelmetProvider>
   );
};

const App = () => (
   <Provider store={store}>
      <ApplicationWrapper />
   </Provider>
);

export default withAppInsights(reactPlugin, App, () => <ErrorPage />);
