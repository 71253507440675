import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import cartReducer from "./features/cart/cart-slice";
import notificationReducer from "./features/notification/notification-slice";
import sessionReducer from "./features/session/session-slice";
import { listenerMiddleware } from "./middleware/listener";
import { logToAppInsightsMiddleware } from "./middleware/logger";

export const store = configureStore({
   reducer: {
      cart: cartReducer,
      session: sessionReducer,
      notification: notificationReducer,
   },
   middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
         .prepend(listenerMiddleware.middleware)
         .concat([logToAppInsightsMiddleware]),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
   ReturnType,
   RootState,
   unknown,
   Action<string>
>;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
