import { useEffect, useState } from "react";

import { GoogleMapsServices } from "./types";

const initializeGoogleMapsSdk = (apiKey: string, ...libs: string[]) => {
   return new Promise<void>((resolve, reject) => {
      const scriptId = "pid";
      if (document.getElementById(scriptId)) {
         return resolve();
      }

      const script = document.createElement("script");
      script.src = `https://maps.google.com/maps/api/js?key=${apiKey}&libraries=${libs.join(",")}`;
      script.id = scriptId;

      script.addEventListener("load", () => {
         resolve();
      });
      script.addEventListener("error", (e) => {
         reject(e);
      });

      document.body.appendChild(script);
   });
};

export const useGoogleMaps = (apiKey: string) => {
   const [googleServices, setGoogleServices] = useState<GoogleMapsServices>();

   useEffect(() => {
      const init = async () => {
         await initializeGoogleMapsSdk(apiKey, "places"); // Currently only supports places lib

         setGoogleServices({
            autoCompleteService: new google.maps.places.AutocompleteService(),
            placesService: new google.maps.places.PlacesService(document.createElement("div")),
            requestSessionToken: () => new google.maps.places.AutocompleteSessionToken(),
         });
      };

      init();
   }, [apiKey]);

   return googleServices;
};
