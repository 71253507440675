import { ReactComponent as Logo } from "../../icons/site-logo-light.svg";
import styles from "./error-page.module.scss";

export const ErrorPage = () => (
   <div className={styles.page}>
      <a href="/" className={styles.logo}>
         <Logo height={32} />
      </a>
      <main>
         <h1 className={styles.title}>We're overwhelmed by your excitement!</h1>
         <div className={styles.description}>
            <p className={styles.text}>
               The checkout is unavailable at the moment. You can wait a while and try again or go
               back to the site.
            </p>
            <a className={styles.link} href="https://afound.com">
               Go to afound.com
            </a>
         </div>
      </main>
   </div>
);
