/**
 * THIS FILE IS OBSOLETE. RECOMMENDED WAY OF CREATING TRACKING FUNCTIONS IS TO CREATE THEM INSIDE THE FEATURE FOLDER.
 * SEE checkout/tracking.ts FOR EXAMPLES.
 */

import { GtmEcommerceProduct, GtmEvent } from "@afound/types";

import { Cart, CartItem, CartSeller } from "../../features/cart/types";
import { AccountStatus, UserTrackingInfo } from "../../features/session/types";
import { DepartmentPreference } from "../../features/checkout/types";

const GTM_LOGIN_EVENT_TYPE = "loginEvent";
const GTM_LOGIN_EVENT_CATEGORY = "login";
const GTM_LOGIN_EVENT_ACTION = "login";
const GTM_LOGIN_EVENT_ACTION_ERROR = "error:login";
const GTM_LOGIN_EVENT_LABEL = "login:checkout";

const getEcommerceProduct = (cartItem: CartItem, seller: CartSeller): GtmEcommerceProduct => ({
   id: cartItem.productCode,
   name: cartItem.title,
   brand: cartItem.brand,
   category: cartItem.category,
   price: cartItem.actualPrice.toFixed(3),
   variant: cartItem.color,
   quantity: cartItem.quantity,
   dimension8: cartItem.stockCount.toString(),
   dimension9: seller.title,
   dimension10: cartItem.sellerSize,
   dimension13: cartItem.offerCode,
   dimension20: "checkout-page",
   gaPromotionId: cartItem.gaPromotionId,
   gaPromotionName: cartItem.gaPromotionName,
   gaPromotionCreative: cartItem.gaPromotionCreative,
});

export const getCheckoutEventProps = (cart: Cart, currentStep: number, isEmpty: boolean) => ({
   event: "checkoutEvent",
   market: cart.marketName,
   ecommerce: {
      currencyCode: cart.currency,
      checkout: {
         actionField: {
            step: currentStep,
            option: "load",
            action: "checkout",
         },
         products: !isEmpty
            ? cart.shops.reduce(
                 (prev, s) =>
                    prev.concat(
                       s.products.map((p, idx) => ({
                          ...getEcommerceProduct(p, s),
                          position: idx + 1,
                       }))
                    ),
                 [] as Array<GtmEcommerceProduct & { position: number }>
              )
            : null,
      },
   },
});

export const getNewsletterSubscriptionProps = (
   email: string,
   departmentPreference?: DepartmentPreference
) => ({
   event: "newsletterSignupEvent",
   eventAction: "newslettersignup",
   eventCategory: "Email",
   eventLabel: "checkout",
   email,
   departmentPreference: departmentPreference || "",
});

export const getLoginEventProps = (userInfo: UserTrackingInfo) => ({
   user: userInfo,
});

export const getLoginSuccessEventProps = (): GtmEvent => ({
   event: GTM_LOGIN_EVENT_TYPE,
   eventCategory: GTM_LOGIN_EVENT_CATEGORY,
   eventAction: GTM_LOGIN_EVENT_ACTION,
   eventLabel: GTM_LOGIN_EVENT_LABEL,
});

export const getLoginErrorEventProps = (failedReason: string | undefined): GtmEvent => ({
   event: GTM_LOGIN_EVENT_TYPE,
   eventCategory: GTM_LOGIN_EVENT_CATEGORY,
   eventAction: GTM_LOGIN_EVENT_ACTION_ERROR,
   eventLabel: failedReason,
});

export const getAccountStatusEventProps = (accountStatus?: AccountStatus): GtmEvent | undefined => {
   const action =
      accountStatus === "Inactive"
         ? "Activate account"
         : accountStatus === "None"
         ? "Create account"
         : accountStatus === "Active"
         ? "Login"
         : undefined;

   if (!action) {
      return undefined;
   }

   return {
      event: "customerEvent",
      eventCategory: "Account status",
      eventAction: action,
      eventLabel: "checkout",
   };
};

export const getReturnerEventProps = (): GtmEvent => ({
   event: "returnerEvent",
   eventCategory: "Ecommerce",
   eventAction: "Extreme returner",
   eventLabel: "blocked",
});

export const getAccountRegistrationEventProps = (): GtmEvent => ({
   event: "customerEvent",
   eventCategory: "Account",
   eventAction: "Create account",
   eventLabel: "registeraccount:checkout",
});

export const getAccountActivationInitiatedEventProps = (): GtmEvent => ({
   event: "customerActivationEvent",
   eventCategory: "Account",
   eventAction: "Create account",
   eventLabel: "accountActivationInitiated:checkout",
});

export const getAccountActivationCompletedEventProps = (): GtmEvent => ({
   event: "customerActivationEvent",
   eventCategory: "Account",
   eventAction: "Create account",
   eventLabel: "accountActivationCompleted:checkout",
});

export const getCartModifiedEventProps = (): GtmEvent => ({
   event: "soldOutItem",
   eventCategory: "Ecommerce",
   eventAction: "soldOutItem:checkout",
   eventLabel: "", // Might be populated with item name in future
});

export const getRemovedFromCartEventProps = (cart: Cart) => ({
   event: "removeFromCartEvent",
   totalBasketValue: cart.total,
   ecommerce: {
      currencyCode: cart.currency,
      remove: {
         products: cart.shops.reduce(
            (prev, s) => prev.concat(s.products.map((p) => getEcommerceProduct(p, s))),
            [] as GtmEcommerceProduct[]
         ),
      },
   },
   cartSnapshot: {},
});

export const getCouponAppliedEventProps = (code: string): GtmEvent => ({
   event: "couponCodeEvent",
   eventCategory: "Ecommerce",
   eventAction: "coupon:checkout",
   eventLabel: code,
});
