import { useGtm, Notification } from "@afound/react";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { parse } from "query-string";
import { useEffect, useRef, useState } from "react";

import { ContactUs } from "../../components/contact-us";
import { LegalModal, LegalModalName } from "../../components/legal-modal";
import { LegalModalContext } from "../../components/legal-modal/legal-modal-context";
import { Step, Wizard } from "../../components/wizard";
import { AdyenErrors, useLocalization, useSettings } from "../../settings";
import { useAppDispatch, useAppSelector } from "../../store";
import {
   getCheckoutEventProps,
   getLoginEventProps,
   getNewsletterSubscriptionProps,
} from "../../tracking/gtm";
import { lock, selectCart, selectCartEmpty } from "../cart/cart-slice";
import { Cart } from "../cart/types";
import { error } from "../notification/notification-slice";
import { selectAuthenticated, selectSession } from "../session/session-slice";
import styles from "./checkout.module.scss";
import { Identification } from "./identification";
import { Payment } from "./payment";
import { OrderSummary } from "./order-summary";
import { CheckoutHelp } from "./help";
import { Shipping } from "./shipping";
import { CustomerAddresses, StepConfig } from "./types";
import { UserInfo } from "./user-info";
import { getInitialStep } from "./initial-step";
import { useReenterInfo } from "./use-reenter-info";
import { Icon } from "../../components/icon";

export const Checkout = () => {
   const isAuthenticated = useAppSelector(selectAuthenticated);

   const {
      persistedState,
      trackingInfo,
      session: { returnerType },
   } = useAppSelector(selectSession);

   const { cart } = useAppSelector(selectCart);
   const isCartEmpty = useAppSelector(selectCartEmpty);

   const [showLegalModal, setShowLegalModal] = useState<LegalModalName>();
   const { locale, contentUrls, paymentLogos, shippingLogos, enableNewCheckoutDesign } =
      useSettings();

   const reenterTypes = useReenterInfo(persistedState);

   const initialStep = getInitialStep(
      isAuthenticated,
      returnerType,
      persistedState,
      reenterTypes.length > 0
   );
   const [currentStep, setCurrentStep] = useState(initialStep);
   const cartRef = useRef<Cart>();
   const isInitialLoad = useRef<boolean>(true);

   const { codes } = useLocalization("errors");
   const ai = useAppInsightsContext();

   const {
      identificationStepHeading,
      myInformationStepHeading,
      shippingStepHeading,
      shippingStepSubHeading,
      orderSummaryStepHeading,
      orderSummaryStepSubHeading,
      paymentStepHeading,
      paymentStepSubHeading,
      contactUsHeading,
      contactUsText,
      edit,
      lockedPromotionHeading,
   } = useLocalization("checkout");

   const { faqHeader, contactUs, helpHeading } = useLocalization("cart");

   const dispatch = useAppDispatch();
   const { dataLayer } = useGtm();

   const createStepSubHeadingContent = (logos: { url: string; name: string }[]) => (
      <div className={styles.logos}>
         {logos?.map((logo, idx) => {
            return <img className={styles.image} src={`${logo.url}`} alt={logo.name} key={idx} />;
         })}
      </div>
   );

   // Store the cart in a ref so we don't need to pass cart as a dep to
   // the dataLayer useEffect (it would trigger a dataLayer push for every)
   // cart change, but we only want to push once for each step).
   useEffect(() => {
      cartRef.current = cart;
   }, [cart]);

   useEffect(() => {
      if (!cartRef.current) {
         return;
      }

      if (isInitialLoad.current) {
         // Ensure that checkout events are always generated for all steps, even if initiated at a later step
         for (let i = 1; i <= currentStep; i++) {
            dataLayer(getCheckoutEventProps(cartRef.current!, i, isCartEmpty));
         }

         isInitialLoad.current = false;
      } else {
         dataLayer(getCheckoutEventProps(cartRef.current!, currentStep, isCartEmpty));
      }
   }, [currentStep, isCartEmpty, dataLayer]);

   useEffect(() => {
      const { c: errorCode } = parse(window.location.search);

      if (errorCode) {
         ai.trackTrace({
            message: `Checkout was loaded with payment error parameter: ${errorCode}.`,
         });
         const msg = codes[errorCode as keyof AdyenErrors];
         dispatch(error(msg));
      }
   }, [codes, ai, dispatch]);

   const handleBeforeSubmit = (
      values: CustomerAddresses,
      customerIdentifier: string,
      subscribedSuccessfully: boolean
   ) => {
      const { billingAddress, hasSignedUpForNewsLetter, departmentPreference } = values;

      if (hasSignedUpForNewsLetter && subscribedSuccessfully) {
         dataLayer(getNewsletterSubscriptionProps(billingAddress.email, departmentPreference));
      }

      if (
         customerIdentifier &&
         trackingInfo?.user &&
         trackingInfo.user.cd3 !== customerIdentifier
      ) {
         dataLayer(getLoginEventProps({ ...trackingInfo.user, cd3: customerIdentifier }));
      }
   };

   const cartHelpTexts = {
      heading: helpHeading,
      faq: faqHeader,
      contactUs: contactUs,
   };

   const handleStepChange = (step: number) => {
      setCurrentStep(step);

      if (step === (enableNewCheckoutDesign ? 5 : StepConfig.Payment)) {
         dispatch(lock(true));
      } else {
         dispatch(lock(false));
      }
   };

   const handleLegalModalClose = () => setShowLegalModal(undefined);

   return !isCartEmpty ? (
      <section className={styles.checkout}>
         <LegalModalContext.Provider value={{ showModal: setShowLegalModal }}>
            <Notification
               className={styles.lockedPromotion}
               visible={cart?.hasLockedPromotion || false}
               theme="warning"
               message={lockedPromotionHeading}
               closeable={false}
            />
            <Wizard
               initialStep={currentStep}
               className={styles.wizard}
               stepEditLabel={edit}
               onStepChange={handleStepChange}
            >
               <Step heading={identificationStepHeading} editable={!isAuthenticated}>
                  {(p) => (
                     <Identification
                        {...p}
                        isAuthenticated={isAuthenticated}
                        editable={
                           p.editable && !(isAuthenticated && returnerType === "ExtremeReturner")
                        }
                     />
                  )}
               </Step>
               <Step heading={myInformationStepHeading}>
                  {(p) => (
                     <UserInfo
                        {...p}
                        reenterInfoTypes={reenterTypes}
                        onBeforeSubmit={handleBeforeSubmit}
                     />
                  )}
               </Step>
               <Step
                  heading={shippingStepHeading}
                  subHeading={shippingStepSubHeading}
                  subHeadingContent={createStepSubHeadingContent(
                     shippingLogos?.map((logo) => ({ url: logo.logoUrl, name: logo.name }))
                  )}
               >
                  {(p) => <Shipping {...p} />}
               </Step>
               {enableNewCheckoutDesign && (
                  <Step heading={orderSummaryStepHeading} subHeading={orderSummaryStepSubHeading}>
                     {(p) => <OrderSummary {...p} />}
                  </Step>
               )}
               <Step
                  heading={paymentStepHeading}
                  subHeading={paymentStepSubHeading}
                  subHeadingContent={createStepSubHeadingContent(
                     paymentLogos?.map((logo) => ({ url: logo.url, name: logo.name }))
                  )}
               >
                  {() => <Payment />}
               </Step>
            </Wizard>

            {enableNewCheckoutDesign ? (
               <CheckoutHelp
                  texts={cartHelpTexts}
                  faqPageUrl={contentUrls.faq}
                  contactUsUrl={contentUrls.contactUs}
               ></CheckoutHelp>
            ) : (
               <ContactUs
                  heading={contactUsHeading}
                  text={contactUsText}
                  url={contentUrls.contactUs}
               />
            )}

            <LegalModal
               visible={showLegalModal === "marketingPolicy"}
               contentUrl={contentUrls.directMarketingPolicy}
               locale={locale}
               onClose={handleLegalModalClose}
            />
            <LegalModal
               visible={showLegalModal === "privacyPolicy"}
               contentUrl={contentUrls.purchasePolicy}
               locale={locale}
               onClose={handleLegalModalClose}
            />
            <LegalModal
               visible={showLegalModal === "accountPolicy"}
               contentUrl={contentUrls.accountPolicy}
               locale={locale}
               onClose={handleLegalModalClose}
            />
            <LegalModal
               visible={showLegalModal === "accountPrivacyPolicy"}
               contentUrl={contentUrls.accountPrivacyPolicy}
               locale={locale}
               onClose={handleLegalModalClose}
            />
            <LegalModal
               visible={showLegalModal === "termsAndConditions"}
               contentUrl={contentUrls.terms}
               locale={locale}
               onClose={handleLegalModalClose}
            />
         </LegalModalContext.Provider>
      </section>
   ) : null;
};
