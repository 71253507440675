import { Button, Notification } from "@afound/react";
import styles from "./order-summary.module.scss";
import { StepContentProps } from "../../../components/wizard";
import { useAppSelector } from "../../../store";
import { selectCart } from "../../cart/cart-slice";
import { useEffect, useState } from "react";
import { ShippingOption } from "../types";
import { useLocalization, useSettings } from "../../../settings";
import { useShipping } from "../shipping/use-shipping";
import "swiper/css";
import "swiper/css/navigation";
import { OrderSummaryItem } from "./order-summary-item";

interface OrderSummaryProps extends StepContentProps {}

export const OrderSummary = (props: OrderSummaryProps) => {
   const { editable, submitStep } = props;
   const cart = useAppSelector(selectCart);
   const { continueToPayment, orderSummaryNotificationText } = useLocalization("checkout");
   const { locale, isShippingAssistantEnabled } = useSettings();
   const [shippingOptions, setShippingOptions] = useState<ShippingOption[]>([]);
   const { fetchShippingOptions } = useShipping(isShippingAssistantEnabled, locale);

   const notificationLabel = shippingOptions
      ? orderSummaryNotificationText.replace("{count}", shippingOptions.length.toString())
      : "";

   const handleSubmit = () => {
      submitStep();
   };

   useEffect(() => {
      const fetch = async () => {
         const shippingOptions = await fetchShippingOptions(cart.cart!);
         if (shippingOptions) {
            setShippingOptions(shippingOptions);
         }
      };

      fetch();
   }, [cart.cart, fetchShippingOptions]);

   return (
      <div>
         <Notification
            className={styles.notification}
            visible={true}
            theme="warning"
            message={notificationLabel}
            closeable={false}
         />
         <div className={styles.summary}>
            {shippingOptions.map((so, idx) => (
               <OrderSummaryItem id={idx} cart={cart.cart} selectedShippingOption={so} key={idx} />
            ))}
         </div>
         {editable && (
            <div className={styles.submit}>
               <Button type="submit" onClick={handleSubmit} theme={"primary"}>
                  {continueToPayment}
               </Button>
            </div>
         )}
      </div>
   );
};
