import { post, put } from "@afound/common";
import { useCallback } from "react";

import { ActivateAccountRequest, CreateAccountRequest, CreateAccountResponse } from "./types";

export const useRegistration = (locale: string) => {
   const createAccount = async (request: CreateAccountRequest) => {
      try {
         const response = await post<CreateAccountRequest, CreateAccountResponse>(
            `/api/v1/${locale}/customer/createaccount`,
            request
         );
         return response || { success: false };
      } catch (err) {
         return { success: false };
      }
   };

   const requestActivationEmail = async (email: string) => {
      try {
         await put<ActivateAccountRequest, void>(`/api/v1/${locale}/customer/requestactivation`, {
            email,
         });
         return true;
      } catch (err) {
         return false;
      }
   };

   return {
      createAccount: useCallback(createAccount, [locale]),
      requestActivationEmail: useCallback(requestActivationEmail, [locale]),
   };
};
