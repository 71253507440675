import { firstToLower } from "@afound/common";

import { ServerValidationError } from "./types";

export const mapServerErrors = (errors: ServerValidationError[]) =>
   errors.reduce(
      (prev, cur) => ({
         ...prev,
         [firstToLower(cur.propertyName)]: cur.errorMessage,
      }),
      {}
   );
