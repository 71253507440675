import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useEffect, useState } from "react";

import { get } from "../api/client";
import { fatal } from "../features/notification/notification-slice";
import { useAppDispatch } from "../store";
import { Settings } from "./types";

const extractLocale = () => window.location.pathname.split("/").filter((s) => !!s)[0];

export const useInitSettings = () => {
   const [settings, setSettings] = useState<Settings | undefined>(undefined);
   const dispatch = useAppDispatch();

   const ai = useAppInsightsContext();

   useEffect(() => {
      const locale = extractLocale();

      if (!locale) {
         dispatch(fatal());
         ai.trackException({
            exception: new Error("Failed to load checkout. No locale was present in the path."),
         });
         return;
      }

      const fetch = async () => {
         try {
            const s = await get<Settings>(`/api/v1/${locale}/configuration`);
            if (s) {
               setSettings({
                  ...s,
                  locale,
               });
            }
         } catch (err) {
            dispatch(fatal());
            ai.trackException({ exception: err as Error });
            throw err;
         }
      };

      fetch();
   }, [ai, dispatch]);

   return settings;
};
