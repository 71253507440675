import { useLocalization } from "../../../../settings";
import { ShippingOption } from "../../types";
import { Cart } from "../../../cart/types";
import styles from "./order-summary-item.module.scss";
import { Icon } from "../../../../components/icon";
import { OrderSummaryItemContent } from "./order-summary-item-content";

interface OrderSummaryItemProps {
   id: number;
   cart: Cart | undefined;
   selectedShippingOption: ShippingOption;
}

export const OrderSummaryItem = (props: OrderSummaryItemProps) => {
   const { id, cart, selectedShippingOption } = props;
   const { shipment } = useLocalization("shared");
   const { soldAndShippedBy, oneItemTemplate, multipleItemTemplate } = useLocalization("cart");

   const cartSeller = cart?.shops.find((s) => s.sellerId === selectedShippingOption.sellerId);
   const quantity = cartSeller
      ? cartSeller.products.reduce((sum, current) => sum + current.quantity, 0)
      : 0;
   const selectedCarrier = selectedShippingOption.carriers;
   const shipmentHeader = shipment + " " + (id + 1);
   const quantityLabel = quantity === 1 ? oneItemTemplate : multipleItemTemplate;

   return (
      <div className={styles.orderSummaryItem} key={selectedShippingOption.sellerId + "," + id}>
         <div className={styles.orderItemHeader}>
            <div className={styles.packageHeaderPackageText}>
               <Icon type="Package" color="none" />
               <span>&nbsp;{shipmentHeader}&nbsp;</span>
               <span>
                  {`(${quantity} ${quantityLabel})`}
                  {" -"}
               </span>
            </div>
            <div className={styles.packageHeaderShippedByText}>
               <span>{soldAndShippedBy}</span>
               <span>&nbsp;{selectedShippingOption.sellerName}</span>
            </div>
         </div>
         <OrderSummaryItemContent
            keyId={id}
            selectedCarrier={selectedCarrier}
            cartSeller={cartSeller}
            currency={cart?.currency}
            shipmentHeader={shipmentHeader}
         />
      </div>
   );
};
