import { Button, ButtonWrapper, LinkButton, Loader, Notification, useGtm } from "@afound/react";
import { Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import * as Yup from "yup";

import { EmailField } from "../../../components/email-field";
import { Icon } from "../../../components/icon";
import { StepContentProps } from "../../../components/wizard";
import { ErrorTexts, useLocalization, useSettings, Validations } from "../../../settings";
import { useAppDispatch, useAppSelector } from "../../../store";
import { getAccountStatusEventProps, getReturnerEventProps } from "../../../tracking/gtm";
import { lookupCustomer, selectSession } from "../../session/session-slice";
import { ActivationForm } from "./activation-form";
import styles from "./identification.module.scss";
import { LoginForm } from "./login-form";
import { RegistrationForm } from "./registration-form";

interface IdentificationProps extends StepContentProps {
   isAuthenticated: boolean;
}

interface EmailFormValues {
   email: string;
}

const getEmailValidationSchema = (
   validations: Validations,
   errorTexts: Pick<ErrorTexts, "required" | "invalidEmail">
) =>
   Yup.object().shape({
      email: Yup.string()
         .required(errorTexts.required)
         .trim()
         .lowercase()
         .matches(new RegExp(validations.email), errorTexts.invalidEmail),
   });

interface EmailFormProps {
   initialEmail: string;
   isEditable: boolean;
   isSubmitted: boolean;
   showErrorMessage: boolean;
   onChangeEmail: () => void;
   onSubmit: (values: EmailFormValues) => void;
}

const EmailForm = (props: EmailFormProps) => {
   const { initialEmail, isEditable, isSubmitted, showErrorMessage, onChangeEmail, onSubmit } =
      props;

   const { emailPrompt, emailFieldLabel, changeEmail, submitEmail } = useLocalization("checkout");
   const errorTexts = useLocalization("errors");

   const { validations, emailSuggestions, enableNewCheckoutDesign } = useSettings();

   return (
      <Formik
         initialValues={{ email: initialEmail || "" }}
         validationSchema={getEmailValidationSchema(validations, errorTexts)}
         onSubmit={onSubmit}
      >
         <Form className={enableNewCheckoutDesign ? styles.form : undefined}>
            {isEditable && <p>{emailPrompt}</p>}
            <EmailField
               name="email"
               label={emailFieldLabel}
               locked={!isEditable || isSubmitted}
               domainSuggestions={emailSuggestions}
            />
            {isEditable && (
               <>
                  {isSubmitted ? (
                     <div className={styles.backButton}>
                        <Icon type="ChevronDown" rotate={90} size={16} />
                        <LinkButton type="button" theme="plain" onClick={onChangeEmail}>
                           {changeEmail}
                        </LinkButton>
                     </div>
                  ) : (
                     <ButtonWrapper>
                        <Button type="submit" theme="primary">
                           {submitEmail}
                        </Button>
                     </ButtonWrapper>
                  )}
                  <Notification
                     className={styles.error}
                     visible={showErrorMessage}
                     theme="error"
                     message={errorTexts.general}
                     closeable={false}
                  />
               </>
            )}
         </Form>
      </Formik>
   );
};

export const Identification = (props: IdentificationProps) => {
   const { isAuthenticated, editable, submitStep } = props;

   // Put in ref so we don't have to include it as a dep to e.g. useEffect
   const submitStepRef = useRef<typeof submitStep>(submitStep);

   const { locale } = useSettings();
   const { guestCheckoutOrLabel, continueAsGuest } = useLocalization("checkout");
   const errorTexts = useLocalization("errors");

   const {
      lookupStatus,
      session: { email, customerFirstName, accountStatus, returnerType },
   } = useAppSelector(selectSession);

   const dispatch = useAppDispatch();

   const [currentEmail, setCurrentEmail] = useState(email);
   const [userError, setUserError] = useState<string>();
   const [emailSubmitted, setEmailSubmitted] = useState(false);

   const { dataLayer } = useGtm();

   useEffect(() => {
      // Handle logged in users since no lookup is done then
      if (!isAuthenticated) {
         return;
      }

      if (returnerType === "ExtremeReturner") {
         dataLayer(getReturnerEventProps());
         setUserError(errorTexts.extremeReturnerBlocked);
      }
   }, [returnerType, isAuthenticated, errorTexts.extremeReturnerBlocked, dataLayer]);

   useEffect(() => {
      if (lookupStatus === "error") {
         setEmailSubmitted(false);
      } else if (lookupStatus === "success") {
         const accountStatusEvent = getAccountStatusEventProps(accountStatus);
         if (accountStatusEvent) {
            dataLayer(accountStatusEvent);
         }

         // Block new account creations now that we're shuttning down :'(
         if (accountStatus === "Inactive" || accountStatus === "None") {
            submitStepRef.current!();
            setEmailSubmitted(false);
            return;
         }

         if (accountStatus === "Discrepancy") {
            setUserError(errorTexts.customerDiscrepancy);
         } else if (returnerType === "ExtremeReturner") {
            dataLayer(getReturnerEventProps());
            setUserError(errorTexts.extremeReturnerBlocked);
         }
      }
   }, [
      lookupStatus,
      accountStatus,
      returnerType,
      errorTexts.customerDiscrepancy,
      errorTexts.extremeReturnerBlocked,
      dataLayer,
   ]);

   const handleChangeEmailClick = () => {
      setEmailSubmitted(false);
      setUserError(undefined);
   };

   const handleEmailSubmit = (values: EmailFormValues) => {
      dispatch(lookupCustomer({ email: values.email, locale }));
      setCurrentEmail(values.email);
      setEmailSubmitted(true);
      setUserError(undefined);
   };

   const handleGuestCheckoutClick = () => {
      submitStepRef.current!();
      setEmailSubmitted(false);
   };

   const hasUserError = userError !== undefined;

   return (
      <div>
         <EmailForm
            initialEmail={email}
            isEditable={editable}
            isSubmitted={emailSubmitted}
            showErrorMessage={lookupStatus === "error"}
            onChangeEmail={handleChangeEmailClick}
            onSubmit={handleEmailSubmit}
         />

         {hasUserError && (
            <Notification
               className={styles.error}
               visible
               theme="error"
               message={userError}
               closeable={false}
            />
         )}

         {editable && emailSubmitted && (
            <div className={styles.formWrapper}>
               {lookupStatus === "loading" && <Loader visible />}
               {lookupStatus === "success" && !hasUserError && (
                  <>
                     {accountStatus === "None" ? (
                        <RegistrationForm locale={locale} email={currentEmail} />
                     ) : accountStatus === "Inactive" ? (
                        <ActivationForm
                           locale={locale}
                           email={currentEmail}
                           firstName={customerFirstName || ""}
                        />
                     ) : accountStatus === "Active" ? (
                        <LoginForm email={currentEmail} firstName={customerFirstName || ""} />
                     ) : null}

                     {accountStatus !== "Discrepancy" && (
                        <div className={styles.guestCheckoutWrapper}>
                           <ButtonWrapper>
                              <div>{guestCheckoutOrLabel}</div>
                              <LinkButton theme="underlined" onClick={handleGuestCheckoutClick}>
                                 {continueAsGuest}
                              </LinkButton>
                           </ButtonWrapper>
                        </div>
                     )}
                  </>
               )}
            </div>
         )}
      </div>
   );
};
