import { useOutsideClick } from "@afound/react";
import classNames from "classnames";
import { useRef } from "react";

import styles from "./prediction-dropdown.module.scss";

interface PredictionDropdownProps {
   searchValue: string;
   predictions: google.maps.places.AutocompletePrediction[];
   activeIndex?: number;
   onItemSelect: (prediction: google.maps.places.AutocompletePrediction) => void;
   onClose: () => void;
}

interface PredictionItemProps {
   searchValue: string;
   prediction: google.maps.places.AutocompletePrediction;
   active?: boolean;
   onSelect: () => void;
}

const findMatch = (description: string, searchValue: string) => {
   const matched = description.substring(0, searchValue.length);
   const remaining = description.substring(matched.length, description.length);

   return { matched, remaining };
};

const PredictionItem = (props: PredictionItemProps) => {
   const { prediction, searchValue, active, onSelect } = props;

   const { matched, remaining } = findMatch(prediction.description, searchValue);

   const classes = classNames({
      [styles.item]: true,
      [styles.active]: active,
   });

   return (
      <div className={classes} onClick={onSelect}>
         <span className={styles.markerIcon}></span>
         <span className={styles.highlight}>{matched}</span>
         <span>{remaining}</span>
      </div>
   );
};

export const PredictionDropdown = (props: PredictionDropdownProps) => {
   const { searchValue, predictions, activeIndex, onItemSelect, onClose } = props;

   const containerRef = useRef(null);
   useOutsideClick(containerRef, onClose);

   const classes = classNames(styles.dropdown, styles.logo);

   return (
      <div className={classes} ref={containerRef}>
         {predictions.map((p, idx) => {
            return (
               <PredictionItem
                  key={p.place_id}
                  searchValue={searchValue}
                  prediction={p}
                  active={idx === activeIndex}
                  onSelect={() => onItemSelect(p)}
               />
            );
         })}
      </div>
   );
};
