import { LinkButton } from "@afound/react";
import classNames from "classnames";
import { ReactChild, ReactNode } from "react";

import { Icon } from "../icon";
import styles from "./step.module.scss";
import { useWizardContext } from "./wizard-context";
import { useSettings } from "../../settings";

export interface StepProps {
   heading: string;
   subHeading?: string;
   subHeadingContent?: ReactNode;
   editable?: boolean;
   children: (contentModel: StepContentProps) => ReactChild;
   __editLabel?: string;
   __step?: number;
}

export const Step = (props: StepProps) => {
   const {
      __step,
      heading,
      subHeading,
      subHeadingContent,
      editable = true,
      __editLabel,
      children,
   } = props as Required<StepProps>;

   const { configureStep } = useWizardContext();
   const { isActive, isLocked, hasPassed, edit, submit } = configureStep(__step);
   const { enableNewCheckoutDesign } = useSettings();

   const active = isActive();
   const locked = isLocked();
   const passed = hasPassed();

   const stepClasses = classNames({
      [styles.step]: true,
      [styles.isNewCheckout]: enableNewCheckoutDesign,
      [styles.active]: active,
      [styles.locked]: locked,
   });

   const headerClasses = classNames({
      [styles.header]: true,
      [styles.isNewCheckout]: enableNewCheckoutDesign,
   });

   const headingClasses = classNames({
      [styles.heading]: true,
      [styles.isNewCheckout]: enableNewCheckoutDesign,
      [styles.passed]: passed,
   });

   const contentClasses = classNames({
      [styles.content]: true,
      [styles.isNewCheckout]: enableNewCheckoutDesign,
      [styles.locked]: locked,
      [styles.passed]: passed,
   });

   const stepSubHeading = () => (
      ((locked || active) && <div>
         <div className={styles.subHeading}>{subHeading}</div>
         {locked && subHeadingContent}
      </div>)
   );

   return (
      <div className={stepClasses}>
         <div className={headerClasses}>
            <h2 className={headingClasses}>{`${__step}. ${heading || ""}`}</h2>
            {!active && !locked && editable && (
               <LinkButton className={styles.editButton} onClick={() => edit()}>
                  {enableNewCheckoutDesign ? (
                     <Icon type="EditNew" color="white" />
                  ) : (
                     <>
                        <Icon type="Edit" /> &nbsp;{__editLabel}
                     </>
                  )}
               </LinkButton>
            )}
         </div>
         {stepSubHeading()}
         {!locked && (
            <div className={contentClasses}>
               {children({
                  step: __step,
                  editable: active && !locked,
                  submitStep: submit,
               })}
            </div>
         )}
      </div>
   );
};

export interface StepContentProps {
   step: number;
   editable: boolean;
   submitStep: (data?: any) => void;
}
