import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useCallback } from "react";
import { get } from "../../api/client";

interface InformationBarResponse {
   informationBarForCheckout: string;
}

export const useInformationBar = () => {
   const ai = useAppInsightsContext();

   const fetchInformationBar = async (url: string, locale: string) => {
      try {
         const resp = await get<[InformationBarResponse]>(
            `/api/v1/${locale}/configuration/content/?url=${url}`
         );
         return resp && resp[0];
      } catch (err) {
         ai.trackException({ exception: err as Error });
      }
   };

   return { fetchInformationBar: useCallback(fetchInformationBar, [ai]) };
};
