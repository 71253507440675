import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createInstance, ReactSDKClient } from "@optimizely/react-sdk";
import { useEffect, useState } from "react";

type OptimizelyConfig = [ReactSDKClient, string];

const extractOptimizelyUserId = (cookieName: string) => {
   const cookies = document.cookie.split(";").map((c) => {
      const [key, value] = c.trim().split("=");
      return { key, value };
   });

   return cookies.find((c) => c.key === cookieName)?.value || "";
};

const getOptimizelyErrorHandler = (appInsights: ReactPlugin) => ({
   handleError: (error: Error) => {
      appInsights.trackException({
         exception: {
            ...error,
            message: `Optimizely error: ${error.message}`,
         },
      });
   },
});

/**
 * Configures an application-wide Optimizely instance, intended to be passed on to the
 * `OptimizelyProvider` context from the React SDK. See Optimizely docs for more info:
 * https://docs.developers.optimizely.com/full-stack/v4.0/docs/example-usage-react.
 * @param sdkKey The Optimizely SDK key.
 * @param appInsights The Application Insights instance, used for logging Optimizely errors.
 * @param userIdCookie Optional cookie name, if you need to extract the user ID from a another cookie.
 * @returns A tuple containing the Optimizely instance and the user ID to be passed to Optimizely.
 *
 * Usage:
 * ```tsx
 * export const App = () => {
 * 	const [optimizely, userId] = useOptimizely(sdkKey, appInsights);
 *
 *		return (
 *			<OptimizelyProvider optimizely={optimizely} user={{ id: userId }}>
 *				<YourApp>
 *					...
 *				</YourApp>
 *			</OptimizelyProvider>
 *		);
 *	};
 * ```
 */

export const useOptimizely = (
   sdkKey: string,
   appInsights: ReactPlugin,
   userIdCookie = "optimizelyEndUserId"
) => {
   const [config, setConfig] = useState<OptimizelyConfig>([] as any);

   useEffect(() => {
      const optimizely = createInstance({
         sdkKey,
         errorHandler: getOptimizelyErrorHandler(appInsights),
      });

      const userId = extractOptimizelyUserId(userIdCookie);

      setConfig([optimizely, userId]);
   }, [sdkKey, appInsights, userIdCookie]);

   return config;
};
