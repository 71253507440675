import { Icon } from "../icon";
import styles from "./contact-us.module.scss";

interface ContactUsProps {
   heading: string;
   text: string;
   url: string;
}

export const ContactUs = (props: ContactUsProps) => {
   const { heading, text, url } = props;

   return (
      <a href={url} className={styles.wrapper}>
         <h3 className={styles.heading}>{heading}</h3>
           <p className={styles.text}>
               <Icon type="Envelope" size={22} color={"none"} />
            <span>{text}</span>
         </p>
      </a>
   );
};
