import React, { useEffect, useState } from "react";

import { StepProps } from "./step";
import { WizardContext } from "./wizard-context";

export interface WizardProps {
   initialStep?: number;
   className?: string;
   stepEditLabel: string;
   children: React.ReactNode;
   onStepChange?: (step: number) => void;
}

export const Wizard = (props: WizardProps) => {
   const { initialStep = 1, className, stepEditLabel, children, onStepChange } = props;

   const [active, setActive] = useState(initialStep);

   useEffect(() => {
      setActive(initialStep);
   }, [initialStep]);

   const handleSubmitStep = (step: number) => (data?: any) => {
      const next = step + 1;

      if (next > React.Children.count(children)) {
         return;
      }

      setActive(next);

      onStepChange && onStepChange(next);
   };

   const stepConfig = (step: number) => {
      return {
         isActive: () => step === active,
         isLocked: () => step > active,
         hasPassed: () => step < active,
         edit: () => {
            setActive(step);
            onStepChange && onStepChange(step);
         },
         submit: handleSubmitStep(step),
      };
   };

   return (
      <div className={className ? className : ""}>
         <WizardContext.Provider value={{ activeStep: active, configureStep: stepConfig }}>
            {React.Children.toArray(children)
               .filter((c) => !!c)
               .map((c, idx) => {
                  const child = c as React.ReactElement<StepProps>;
                  return child
                     ? React.cloneElement(child, {
                          ...child.props,
                          __editLabel: stepEditLabel,
                          __step: idx + 1,
                       })
                     : null;
               })}
         </WizardContext.Provider>
      </div>
   );
};
