import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useCallback } from "react";
import { get } from "../../api/client";

export const useLegal = () => {
   const ai = useAppInsightsContext();

   const fetchLegal = async (contentUrl: string, locale: string) => {
      try {
         const resp = await get<[{ mainBody: string }]>(
            `/api/v1/${locale}/configuration/content/?url=${contentUrl}`
         );
         return resp && resp[0];
      } catch (err) {
         ai.trackException({ exception: err as Error });
      }
   };

   return { fetchLegal: useCallback(fetchLegal, [ai]) };
};
