import { GtmEvent } from "@afound/types";

export type MinicartEventAction = "More from sellers" | "Coupon" | "Product";

export const getMinicartEvent = (action: MinicartEventAction, label: string): GtmEvent => ({
   event: "miniCartEvent",
   eventCategory: "minicart",
   eventAction: action,
   eventLabel: label,
});

export const getEcomIdWidgetGtmEvent = (
   isLoggedIn: boolean,
   location: string | undefined
): GtmEvent => ({
   event: "ecomIdWidgetClickEvent",
   eventAction: "overlay opened in " + location,
   eventLabel: isLoggedIn ? "personal" : "generic",
});
