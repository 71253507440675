import {
   Button,
   ButtonWrapper,
   Checkbox,
   InputField,
   LinkButton,
   Notification,
   useGtm,
} from "@afound/react";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import { ErrorTexts, LocalizedTexts, useSettings } from "../../../../settings";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { authenticate, selectSession } from "../../../session/session-slice";
import { AuthenticationContext } from "../../../session/types";
import { LoginFormValues } from "../types";
import styles from "./login.module.scss";
import { useEffect } from "react";
import { getLoginErrorEventProps } from "../../../../tracking/gtm";

type LoginTexts = Pick<
   LocalizedTexts["checkout"],
   "passwordFieldLabel" | "rememberMe" | "forgotPassword" | "login"
>;

interface LoginProps {
   email: string;
   context: AuthenticationContext;
   texts: LoginTexts;
   errorTexts: ErrorTexts;
}

const getPasswordValidationSchema = (errorTexts: Pick<ErrorTexts, "required">) =>
   Yup.object({
      password: Yup.string().required(errorTexts.required),
   });

export const Login = (props: LoginProps) => {
   const {
      email,
      context,
      texts: { passwordFieldLabel, rememberMe, forgotPassword, login },
      errorTexts,
   } = props;

   const { authenticateStatus, authenticationFailureReason, authenticationFailureReasonType } = useAppSelector(selectSession);
   const dispatch = useAppDispatch();

   const {
      locale,
      contentUrls: { forgotPassword: forgotPasswordUrl },
   } = useSettings();

   const { dataLayer } = useGtm();

   useEffect(() => {
      if (authenticateStatus !== "error") {
         return;
      }

      dataLayer(getLoginErrorEventProps(authenticationFailureReasonType));
   }, [authenticateStatus, authenticationFailureReasonType, dataLayer]);

   const handleSubmit = (values: LoginFormValues) => {
      const { password, rememberMe } = values;
      dispatch(authenticate({ email, password, rememberMe, locale, context }));
   };

   return (
      <Formik
         initialValues={{ password: "", rememberMe: false }}
         validationSchema={getPasswordValidationSchema(errorTexts)}
         onSubmit={handleSubmit}
      >
         <Form>
            <InputField type="password" name="password" label={passwordFieldLabel} />
            <div className={styles.loginActions}>
               <Checkbox name="rememberMe">{rememberMe}</Checkbox>
               <LinkButton theme="underlined" url={forgotPasswordUrl} target="_blank">
                  {forgotPassword}
               </LinkButton>
            </div>
            <Notification
               visible={authenticateStatus === "error"}
               theme="error"
               className={styles.notification}
               message={authenticationFailureReason || errorTexts.authentication}
               closeable={false}
            />
            <ButtonWrapper>
               <Button theme="primary" type="submit" disabled={authenticateStatus === "loading"}>
                  {login}
               </Button>
            </ButtonWrapper>
         </Form>
      </Formik>
   );
};
