import "./styles/index.scss";
import "@afound/react/dist/main.css";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";

import App from "./app";
import reportWebVitals from "./tracking/web-vitals";
import { isProd } from "./utils";

ReactDOM.render(
   <React.StrictMode>
      <App />
   </React.StrictMode>,
   document.getElementById("root")
);

if (APP_CONFIG.enableWebVitals) {
   // If you want to start measuring performance in your app, pass a function
   // to log results (for example: reportWebVitals(console.log))
   // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
   const webVitalsCallback = isProd() ? undefined : undefined;
   reportWebVitals(webVitalsCallback);
}
