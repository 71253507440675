import { Price } from "@afound/react";

import { Icon } from "../../../components/icon";
import { useLocalization } from "../../../settings";
import { Cart } from "../types";
import styles from "./cart-summary.module.scss";

interface CartSummaryProps {
   cart: Cart;
   locale: string;
   pricingFaqUrl: string;
}

export const CartSummary = (props: CartSummaryProps) => {
   const { locale, cart, pricingFaqUrl } = props;

   const {
      shipping,
      currency,
      hasCoupon,
      hasEmployeeDiscount,
      hasPromotion,
      totalCouponSaving,
      totalEmployeeDiscountSaving,
      totalPromotionSaving,
      totalBeforeSavings,
      total,
      totalSavings,
      cartWarningMessages,
   } = cart;

   const {
      shippingTotal,
      freeShipping,
      total: totalText,
      includingVat,
      savings,
      couponLabel,
      employeeDiscountLabel,
      promotionLabel,
      promotionDiscountHelp,
   } = useLocalization("cart");

   const hasSavings = totalBeforeSavings > total;

   return (
      <>
         {cartWarningMessages &&
            cartWarningMessages.map((warning, idx) => <p key={`cartwarn_${idx}`}>{warning}</p>)}

         {hasPromotion && <p>{promotionDiscountHelp}</p>}

         <div className={styles.summary}>
            <p className={styles.align}>
               <span>{shippingTotal}</span>
               {shipping === 0 ? (
                  freeShipping
               ) : (
                  <Price value={shipping} currency={currency} locale={locale} plain />
               )}
            </p>
            {hasCoupon && !!totalCouponSaving && (
               <p className={styles.align}>
                  <span>{couponLabel}</span>
                  <Price value={totalCouponSaving * -1} currency={currency} locale={locale} />
               </p>
            )}
            {hasEmployeeDiscount && !!totalEmployeeDiscountSaving && (
               <p className={styles.align}>
                  <span>{employeeDiscountLabel}</span>
                  <Price
                     value={totalEmployeeDiscountSaving * -1}
                     currency={currency}
                     locale={locale}
                  />
               </p>
            )}
            {hasPromotion && !!totalPromotionSaving && (
               <p className={styles.align}>
                  <span>{promotionLabel}</span>
                  <Price value={totalPromotionSaving * -1} currency={currency} locale={locale} />
               </p>
            )}
            <div className={styles.totalRow}>
               <div className={styles.align}>
                  <span className={styles.total}>
                     {totalText}
                     <span className={styles.salesTax}>{includingVat}</span>
                  </span>
                  <Price
                     value={hasSavings ? totalBeforeSavings : total}
                     currency={currency}
                     locale={locale}
                     reduced={hasSavings}
                  />
               </div>
               {hasSavings && (
                  <div className={styles.align}>
                     <span></span>
                     <Price value={total} currency={currency} locale={locale} />
                  </div>
               )}
            </div>
            <a
               href={pricingFaqUrl}
               target="_blank"
               rel="noreferrer"
               className={`${styles.align} ${styles.youSave}`}
            >
               <span></span>
               <span>
                  {savings}&nbsp;
                  <Price value={totalSavings} currency={currency} locale={locale} plain />
                  <Icon type="Alert" size={12} />
               </span>
            </a>
         </div>
      </>
   );
};
