/* eslint-disable react-hooks/rules-of-hooks */
import { CartTexts } from "@afound/react"
import { useLocalization } from "../../../settings";

export const mapTexts = () => {
    
    const { ...cartTexts } = useLocalization("cart");
    const { continueShopping } = useLocalization("shared");

    const mappedTexts: CartTexts = {
        cartCount: cartTexts.cartCount,
        couponsForMembersOnly: cartTexts.couponsForMembersOnly,
        shipment: {
            packageLabel: cartTexts.packageLabel,
            itemCount: cartTexts.itemCount,
            soldAndShippedBy: cartTexts.soldAndShippedBy,
            shippingThresholdTemplate: cartTexts.shippingThresholdTemplate,
            shippingPolicyLabel: cartTexts.shippingPolicyLabel,
            deliveredByLabel: cartTexts.deliveredByLabel,
            returnFeeLabel: cartTexts.returnFeeLabel,
        },
        product: {
            color: cartTexts.color,
            fewLeft: cartTexts.fewLeft,
            wishlist: cartTexts.wishlist,
            savedToWishlist: cartTexts.savedToWishlist,
            notifyMe: cartTexts.notifyMe,
            notifyMeConfirmation: cartTexts.notifyMeConfirmation,
            wasPriceInfo: cartTexts.wasPriceInfo,
            priorPriceInfo: cartTexts.priorPriceInfo,
            discountInfo: cartTexts.discountInfo
        },
        couponCode: {
            label: cartTexts.couponLabel,
            appliedCoupons: cartTexts.appliedCoupon,
            inputPlaceholder: cartTexts.couponPlaceholder,
            submit: cartTexts.submitCoupon,
            required: cartTexts.couponCodeRequired,
        },
        priceSummary: {
            orderValue: cartTexts.orderValue,
            staffDiscount: cartTexts.staffDiscount,
            couponCode: cartTexts.couponCode,
            totalShippingFee: cartTexts.totalShippingFee,
            cartTotal: cartTexts.cartTotal,
            includingVat: cartTexts.includingVat,
        },
        cta: {
            ctaLabel: cartTexts.checkoutLabel,
            paymentLogosTitle: cartTexts.paymentLogosTitle,
            cartReservationDisclaimer: cartTexts.cartReservationDisclaimer,
            soldOutItemsInCartWarning: cartTexts.soldOutItemsInCartWarning
        },
        help: {
            heading: cartTexts.helpHeading,
            faq: cartTexts.faqHeader,
            chat: cartTexts.chat,
            contactUs: cartTexts.contactUs,
        },
        recommendations: {
            heading: cartTexts.recommendationHeading,
            priceGuarantee: cartTexts.priceGuarantee,
        },
        emptyCart: {
            message: cartTexts.cartEmpty,
            continueShopping: continueShopping
        }
    };

    return mappedTexts;
}