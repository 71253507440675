import { CartWrapper, MarkerTheme, MarkerType, OptionalCartModule } from "@afound/react";
import { useLocalization, useSettings } from "../../../settings";
import { useAppDispatch, useAppSelector } from "../../../store";
import { removeCoupon, changeQuantity, applyCoupon, updateSize, selectCart } from "../cart-slice";
import { Cart, CartItem, CartSellerPromotion, ProductMarker } from "../types";
import { selectSession } from "../../session/session-slice";
import { getEcomIdWidgetGtmEvent, getMinicartEvent } from "../tracking";
import { useEffect, useRef } from "react";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { mapTexts } from "./map-texts";
import styles from "./inner-cart.module.scss";
import classNames from "classnames";
import { waitForElement } from "@afound/common";
import { ProductMarker as ProductMarkerType } from "@afound/types";

interface InnerCartProps {
   cart: Cart;
}

export const InnerCart = (props: InnerCartProps) => {
   const { cart } = props;
   const ecomIdInitRef = useRef<boolean>(false);
   const ai = useAppInsightsContext();

   const {
      trackingInfo,
      session: { authenticationInfo },
   } = useAppSelector(selectSession);

   useEffect(() => {
      if (!cart || ecomIdInitRef.current) {
         return;
      }

      try {
         window.ecomid.widgets.info?.load();
      } catch (err) {
         ai.trackException({ exception: err as Error });
      }
   }, [cart, ai]);

   useEffect(() => {
      waitForElement(".js-ecomid-widget").then((el) => {
         const element = el as HTMLElement;
         element.addEventListener("click", async () => {
            const isLoggedIn = authenticationInfo.isAuthenticated;
            var containerElement = element.closest(".ecomid-widget-container") as HTMLElement;
            window.dataLayer.push(
               getEcomIdWidgetGtmEvent(isLoggedIn, containerElement.dataset.location)
            );
         });
      });
   }, [authenticationInfo.isAuthenticated]);

   const {
      cartId,
      shipping,
      currency,
      hasCoupon,
      hasEmployeeDiscount,
      hasPromotion,
      hasBeenChanged,
      marketName,
      shops,
      totalCouponSaving,
      totalEmployeeDiscountSaving,
      totalPromotionSaving,
      totalBeforeSavings,
      total,
      totalSavings,
      totalExcludingShipping,
      totalItemsCount,
      cartWarningMessages,
   } = cart;

   const {
      locale,
      contentUrls: { faq, accountPrivacyPolicy, contactUs: contactUsUrl },
      paymentLogos,
      storeSiteUrl,
      enablePMarkersInMinicartAndCheckout,
      enableEcomIdV2,
   } = useSettings();

   const dispatch = useAppDispatch();

   const { locked, applyCouponError } = useAppSelector(selectCart);

   const texts = mapTexts();

   const excludedModules: OptionalCartModule[] = ["cta", "recommendations", "help"];

   const renderPaymentLogos = paymentLogos.map((p) => {
      return {
         imageSrc: p.url,
         title: p.name,
      };
   });

   const handleCouponRemoved = async (couponCode: string) => {
      const {
         meta: { requestStatus },
      } = await dispatch(removeCoupon({ locale, couponCode: couponCode! }));
      return requestStatus === "fulfilled";
   };

   const handleCouponSubmit = async (couponCode: string) => {
      const {
         meta: { requestStatus },
      } = await dispatch(applyCoupon({ locale, couponCode: couponCode! }));
      return requestStatus === "fulfilled";
   };

   const handleChangeQuantity = async (offerCode: string, quantity: number) => {
      const {
         meta: { requestStatus },
      } = await dispatch(changeQuantity({ locale, offerCode, quantity }));
      return requestStatus === "fulfilled";
   };

   const handleProductRemoved = async (offerCode: string) => {
      const {
         meta: { requestStatus },
      } = await dispatch(changeQuantity({ locale, offerCode, quantity: 0 }));
      return requestStatus === "fulfilled";
   };

   const handleSizeChange = async (oldOfferId: string, newOfferId: string, quantity: number) => {
      const {
         meta: { requestStatus },
      } = await dispatch(updateSize({ locale, oldOfferId, newOfferId, quantity }));
      return requestStatus === "fulfilled";
   };

   const handleWishlistClick = async () => {
      return true;
   };

   const handleNotifyClick = async () => {
      return true;
   };

   const handleDsaIconClick = () => {
      return true;
   };

   const handleSellerLinkClick = (sellerName: string) => {
      window.dataLayer.push(getMinicartEvent("More from sellers", sellerName));
   };

   const handleContinueShopping = () => {
      window.location.href = storeSiteUrl;
   };

   const renderPromotions = (promotions: CartSellerPromotion[], sellerId: number) =>
      promotions.map((prom) => ({
         id: sellerId?.toString(),
         ...prom,
      }));

   const renderMarkers = (markers: ProductMarker[]) => {
      return markers.map((marker) => ({
         ...marker,
         theme: MarkerTheme[marker.theme],
         type: MarkerType[marker.type],
      })) as ProductMarkerType[];
   };

   const renderProducts = (products: CartItem[]) =>
      products
         ? products?.map((product) => ({
              ...product,
              productId: product.productCode,
              offerId: product.offerCode,
              isSoldOut: product.soldOut,
              sizes: product.sizes ?? [],
              markers: renderMarkers(product.markers || []),
           }))
         : [];

   const renderShops = () =>
      shops
         ? shops?.map((shop) => ({
              ...shop,
              id: shop.sellerId,
              shippingPolicyText: shop.shippingText ?? "",
              products: renderProducts(shop.products),
              promotions: renderPromotions(shop.promotions, shop.sellerId),
              sellerId: shop.sellerId?.toString(),
           }))
         : [];
   return (
      <>
         <CartWrapper
            cartError={cartWarningMessages?.join(",")}
            exclude={excludedModules}
            locked={locked}
            couponError={applyCouponError}
            enablePMarkersInMinicartAndCheckout={enablePMarkersInMinicartAndCheckout}
            cartModel={{
               cartId: cartId,
               currency: currency,
               hasBeenChanged: hasBeenChanged,
               hasCoupon: hasCoupon,
               hasEmployeeDiscount: hasEmployeeDiscount,
               hasPromotion: hasPromotion,
               market: marketName ?? "",
               shipping: shipping,
               shops: renderShops(),
               total: total,
               totalBeforeSavings: totalBeforeSavings,
               totalCouponSaving: totalCouponSaving,
               totalEmployeeDiscountSaving: totalEmployeeDiscountSaving ?? 0,
               totalExcludingShipping: totalExcludingShipping,
               totalItemsCount: totalItemsCount,
               totalPromotionSaving: totalPromotionSaving ?? 0,
               totalSavings: totalSavings,
            }}
            onCouponRemove={handleCouponRemoved}
            onCouponSubmit={handleCouponSubmit}
            onNotifiyClick={handleNotifyClick}
            onQuantityChange={handleChangeQuantity}
            onRemove={handleProductRemoved}
            onSellerLinkClick={handleSellerLinkClick}
            onSizeChange={handleSizeChange}
            onWishlistClick={handleWishlistClick}
            onDsaIconClick={handleDsaIconClick}
            paymentLogos={renderPaymentLogos}
            renderEcomIdPlaceholder={
               enableEcomIdV2
                  ? () => (
                       <div
                          data-location="checkout"
                          className={classNames(
                             "ecomid-widget-container",
                             styles.ecomIdWidgetContainer
                          )}
                       >
                          <div className="widget-container"></div>
                       </div>
                    )
                  : undefined
            }
            texts={{ ...texts }}
            checkoutUrl={""}
            onContinueShoppingClick={handleContinueShopping}
            faqPageUrl={faq}
            pricePolicyPageUrl={accountPrivacyPolicy}
            userLoggedIn={trackingInfo?.user.loggedIn!}
            disableWishlist
         />
      </>
   );
};
