import "./adyen-overrides.scss";
import "@adyen/adyen-web/dist/adyen.css";

import AdyenCheckout from "@adyen/adyen-web";
import { HtmlContent } from "@afound/react";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useEffect, useRef, useState } from "react";

import { get, post } from "../../../api/client";
import { useLegalModalContext } from "../../../components/legal-modal/legal-modal-context";
import { useLocalization, useSettings } from "../../../settings";
import { useAppDispatch } from "../../../store";
import { fetchCart } from "../../cart/cart-slice";
import { error, standard } from "../../notification/notification-slice";
import { getAdditionalPaymentMethodConfiguration } from "./payment-configuration";
import styles from "./payment.module.scss";
import { PaymentMethodResponseObject } from "./types";

export const Payment = () => {
   const dropinElement = useRef<HTMLDivElement>(null);
   const cardBrandRef = useRef<string>();

   const { adyenClientKey, locale, country, enableNewCheckoutDesign } = useSettings();

   const [paymentMethods, setPaymentMethods] =
      useState<PaymentMethodResponseObject | undefined>(undefined);

   const { paymentTermsNotice } = useLocalization("checkout");
   const { payment } = useLocalization("errors");

   const { showModal } = useLegalModalContext();

   const dispatch = useAppDispatch();

   const ai = useAppInsightsContext();

   useEffect(() => {
      const fetchPaymentMethods = async (locale: string) => {
         try {
            const response = await get<PaymentMethodResponseObject>(
               `/api/v1/${locale}/adyenpayment/methods/`
            );
            setPaymentMethods(response);

            if (response?.cartChangedReason) {
               dispatch(fetchCart(locale));
               dispatch(standard(response?.cartChangedReason));
            }
         } catch (err) {
            ai.trackException({ exception: err as Error });
            dispatch(error(payment));
         }
      };

      fetchPaymentMethods(locale);
   }, [locale, payment, ai, dispatch]);

   useEffect(() => {
      const initDropin = async (payMethods: PaymentMethodResponseObject) => {
         const { paymentMethodsResponse, amount, locale, environment, enableStoreDetails } =
            payMethods;

         const checkout = await AdyenCheckout({
            clientKey: adyenClientKey,
            paymentMethodsResponse,
            amount,
            locale,
            environment,
            paymentMethodsConfiguration: {
               card: {
                  enableStoreDetails,
                  onBrand: (d) => {
                     cardBrandRef.current = d.brand;
                  },
               },
               storedCard: {
                  onBrand: (d) => {
                     cardBrandRef.current = d.brand;
                  },
               },
               ...getAdditionalPaymentMethodConfiguration(
                  payMethods,
                  country.twoLetterISORegionName
               ),
            },
            onSubmit: async (state: any, dropin: any) => {
               dropin.setStatus("loading");

               try {
                  const isCardPayment = state.data.paymentMethod.type === "scheme";
                  const brandParam =
                     isCardPayment && !!cardBrandRef.current
                        ? `?brand=${cardBrandRef.current}`
                        : "";

                  const data = await post<any>(
                     `/api/v1/${locale}/adyenpayment${brandParam}`,
                     state.data
                  );

                  if (data.action) {
                     // redirect
                     dropin.handleAction(data.action);
                  } else {
                     if (data.success) {
                        // to order confirmation
                        window.location.href = data.returnUrl;
                     } else {
                        // show error on checkout page and restart payment
                        window.location.href = data.returnUrl;
                     }
                  }
               } catch (err) {
                  dropin.setStatus("idle");
                  ai.trackException({ exception: err as Error });
                  dispatch(error(payment));
               }
            },
         });

         checkout
            .create("dropin", {
               // Starting from version 4.0.0, Drop-in configuration only accepts props related
               // to itself and cannot contain generic configuration like the onSubmit event.
               openFirstPaymentMethod: true,
            })
            .mount(dropinElement.current!);
      };

      if (!paymentMethods || !dropinElement.current) {
         return;
      }

      initDropin(paymentMethods);
   }, [paymentMethods, adyenClientKey, ai, payment, country.twoLetterISORegionName, dispatch]);

   return (
      <div>
         <div ref={dropinElement}></div>
         <HtmlContent
            className={enableNewCheckoutDesign ? styles.termsNewCheckout : styles.terms}
            html={paymentTermsNotice}
            links={[() => showModal("termsAndConditions"), () => showModal("privacyPolicy")]}
         />
      </div>
   );
};
