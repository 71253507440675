import { Marker, PriceBlock, MarkerThemeClass } from "@afound/react";
import classNames from "classnames";
import { FC, useEffect } from "react";

import { Icon } from "../../../components/icon";
import { useLocalization, useSettings } from "../../../settings";
import { useAppDispatch, useAppSelector } from "../../../store";
import { error } from "../../notification/notification-slice";
import { changeQuantity, selectCart } from "../cart-slice";
import type { CartItem, MarkerType, ProductMarker } from "../types";
import styles from "./cart-product.module.scss";

interface CartProductProps {
   product: CartItem;
   currency: string;
   onRemoveClick: (offerCode: string) => void;
}

const PromotionMarker: FC<{ subType: string; countryCode: string }> = ({
   subType,
   countryCode,
}) => {
   const promotionLang = subType.toLowerCase() === "threshold" ? "EN" : countryCode;

   return (
      <div className={styles.promotionMarker}>
         <img src={`/images/${subType}_${promotionLang}.svg`} alt={`${subType} icon`} />
      </div>
   );
};

export const CartProduct = (props: CartProductProps) => {
   const { product, currency, onRemoveClick } = props;

   const {
      offerCode,
      title,
      imageUrl,
      actualPrice,
      priorPrice,
      wasPrice,
      brand,
      sellerSize,
      stockCount,
      quantity,
      hasFewLeft,
      isFlashDeal,
      promotionSubType,
      markers,
   } = product;

   const {
      size,
      quantity: quantityText,
      fewLeft,
      wasPriceInfo,
      priorPriceInfo,
   } = useLocalization("cart");
   const { cartUpdateGeneral } = useLocalization("errors");

   const { locale, country, enablePMarkersInMinicartAndCheckout } = useSettings();

   const { modifyStatus, cart, locked } = useAppSelector(selectCart);
   const dispatch = useAppDispatch();

   useEffect(() => {
      if (modifyStatus === "error") {
         dispatch(error(cartUpdateGeneral));
      } else if (cart && cart.cartUpdateErrorMessages.length) {
         const cartError = cart.cartUpdateErrorMessages[0];
         dispatch(error(cartError));
      }
   }, [modifyStatus, cart, cartUpdateGeneral, dispatch]);

   const classes = classNames({
      [styles.product]: true,
      [styles.flashDeal]: isFlashDeal,
   });

   const isLoading = modifyStatus === "loading";

   const wasTotalPrice = wasPrice * quantity;
   const priorTotalPrice = priorPrice ? priorPrice * quantity : undefined;
   const actualTotalPrice = actualPrice * quantity;

   const sizeText = sellerSize ? `${size}: ${sellerSize} | ` : "";

   const pMarkers: MarkerType[] = ["ThreeForTwo", "Threshold"];

   return (
      <div className={classes}>
         <div className={styles.imageWrapper}>
            {enablePMarkersInMinicartAndCheckout && markers && markers.length > 0 && (
               <div className={styles.markerWrapper}>
                  {markers.map((marker: ProductMarker, index: number) => {
                     // showing only p-markers
                     return pMarkers.includes(marker.type) ? (
                        <Marker
                           key={index}
                           text={marker.text}
                           theme={marker.theme.toString()?.toLowerCase() as MarkerThemeClass}
                        />
                     ) : (
                        <></>
                     );
                  })}
               </div>
            )}

            <img className={styles.image} src={`${imageUrl}?preset=product-card-s`} alt={title} />
         </div>
         <div className={styles.description}>
            <div className={styles.align}>
               <span className="u-bold">{brand}</span>
               {!locked && (
                  <button
                     type="button"
                     disabled={isLoading}
                     onClick={() => onRemoveClick(offerCode)}
                  >
                     <Icon type="Close" />
                  </button>
               )}
            </div>
            <div className={styles.align}>
               <span>
                  {title}
                  {hasFewLeft && <span className={styles.fewLeft}>- {fewLeft}</span>}
               </span>
            </div>
            {!enablePMarkersInMinicartAndCheckout && promotionSubType && (
               <PromotionMarker
                  subType={promotionSubType}
                  countryCode={country.twoLetterISORegionName}
               />
            )}
            <div className={styles.pricePanel}>
               <div className={styles.controls}>
                  <div className={styles.sizeQuantity}>
                     {sizeText}
                     {quantityText}: {quantity}
                  </div>
                  <div className={styles.buttons}>
                     <button
                        type="button"
                        className={styles.quantityButton}
                        disabled={locked || quantity <= 0 || isLoading}
                        onClick={() =>
                           dispatch(changeQuantity({ locale, offerCode, quantity: quantity - 1 }))
                        }
                     >
                        <Icon type="Minus" />
                     </button>
                     <button
                        type="button"
                        className={styles.quantityButton}
                        disabled={locked || quantity + 1 > stockCount || isLoading}
                        onClick={() =>
                           dispatch(changeQuantity({ locale, offerCode, quantity: quantity + 1 }))
                        }
                     >
                        <Icon type="Plus" />
                     </button>
                  </div>
               </div>
               <div className={styles.prices}>
                  <PriceBlock
                     wasPrice={{
                        value: wasTotalPrice,
                     }}
                     priorPrice={
                        priorTotalPrice
                           ? {
                                value: priorTotalPrice,
                             }
                           : undefined
                     }
                     price={{ value: actualTotalPrice }}
                     priceInfoConfig={{
                        labels: {
                           wasPriceInfo,
                           priorPriceInfo,
                        },
                     }}
                     currency={currency}
                     locale={locale}
                  />
               </div>
            </div>
         </div>
      </div>
   );
};
