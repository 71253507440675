import { Button } from "@afound/react";
import { FC } from "react";

import { Icon } from "../../../components/icon";
import styles from "./empty-cart.module.scss";

interface EmptyCartProps {
   emptyText: string;
   continueShoppingText: string;
   continueShoppingUrl: string;
}

export const EmptyCartOld: FC<EmptyCartProps> = ({
   emptyText,
   continueShoppingText,
   continueShoppingUrl,
}) => (
   <div className={styles.emptyCart}>
      <Icon type="CartPackage" size={48} />
      <h2>{emptyText}</h2>
      <Button theme="primary" url={continueShoppingUrl}>
         {continueShoppingText}
      </Button>
   </div>
);
