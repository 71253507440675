import { HtmlContent, Loader } from "@afound/react";
import { useEffect, useState } from "react";

import { useLegal } from "./use-legal";

interface LegalTextProps {
   active: boolean;
   contentUrl: string;
   locale: string;
}

export const LegalText = (props: LegalTextProps) => {
   const { active, contentUrl, locale } = props;

   const [content, setContent] = useState("");
   const { fetchLegal } = useLegal();

   useEffect(() => {
      if (!active) {
         return;
      }

      const fetch = async () => {
         const legal = await fetchLegal(contentUrl, locale);
         if (legal) {
            setContent(legal.mainBody);
         }
      };

      fetch();
   }, [active, contentUrl, locale, fetchLegal]);

   return !content ? <Loader visible /> : <HtmlContent html={content} />;
};
