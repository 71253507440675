import { createListenerMiddleware } from "@reduxjs/toolkit";

import { addCartListeners } from "../features/cart/cart-slice";
import { addSessionListeners } from "../features/session/session-slice";

import type { TypedStartListening } from "@reduxjs/toolkit";
import type { RootState, AppDispatch } from "../store";
export const listenerMiddleware = createListenerMiddleware();

export type AppStartListening = TypedStartListening<RootState, AppDispatch>;

export const startAppListening = listenerMiddleware.startListening as AppStartListening;

addSessionListeners(startAppListening);
addCartListeners(startAppListening);
