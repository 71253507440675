import { useEffect, useState } from "react";
import { InformationBar as InformationBarBase } from '@afound/react';

import { useInformationBar } from "./use-information-bar";

export interface InformationBarProps {
   url: string;
   locale: string;
}

function InformationBar({ url, locale }: InformationBarProps) {
   const [content, setContent] = useState("");
   const { fetchInformationBar } = useInformationBar();

   useEffect(() => {
      const fetch = async () => {
         const res = await fetchInformationBar(url, locale);
         if (res) {
             setContent(res.informationBarForCheckout);
         }
      };

      fetch();
   }, [locale, fetchInformationBar, url]);

   return content ? (
      <InformationBarBase message={content} />
   ) : null;
};

export default InformationBar;