import { Button, Checkbox, FieldRow, HtmlContent, RadioButton } from "@afound/react";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import { useEffect, useRef, useState } from "react";

import { mapServerErrors } from "../../../api/helpers";
import { useLegalModalContext } from "../../../components/legal-modal/legal-modal-context";
import { StepContentProps } from "../../../components/wizard";
import { useLocalization, useSettings } from "../../../settings";
import { selectAuthenticated, selectSession } from "../../session/session-slice";
import { CustomerAddresses } from "../types";
import { AddressFields } from "./address-fields";
import { ServerAddressValidationResult, useAddresses } from "./use-addresses";
import styles from "./user-info.module.scss";
import { useAppSelector } from "../../../store";
import { ReenterInfoType } from "../use-reenter-info";
import { getAddressValidationSchema, getInitialErrors, getInitialFormValues } from "./form-helpers";
import { usePlacesAutocomplete } from "../../../components/google-places-autocomplete/use-places-autocomplete";

interface UserInfoStepProps extends StepContentProps {
   reenterInfoTypes: ReenterInfoType[];
   onBeforeSubmit?: (
      values: CustomerAddresses,
      customerIdentifier: string,
      subscribedSuccessfully: boolean
   ) => void;
}

export const UserInfo = (props: UserInfoStepProps) => {
   const { reenterInfoTypes, editable, onBeforeSubmit, submitStep } = props;

   const { locale, country: currentCountry, validations, enableNewCheckoutDesign } = useSettings();

   const [email, setEmail] = useState<string>("");
   const [loading, setLoading] = useState(false);
   const [callingCodeCountryCode, setCallingCodeCountryCode] = useState(
      currentCountry.twoLetterISORegionName
   );

   const formikRef = useRef<FormikProps<CustomerAddresses>>(null);

   const isAuthenticated = useAppSelector(selectAuthenticated);
   const { session, persistedState } = useAppSelector(selectSession);

   const { updateAddresses } = useAddresses(locale);

   const { fetchPredictions } = usePlacesAutocomplete("", "");

   const {
      addressForm,
      billingAddress,
      shippingAddress,
      phoneNumberDescription,
      continueToShipping,
      directMarketingPolicy,
      departmentPreference,
      mensDepartment,
      womensDepartment,
      invalidAddress,
   } = useLocalization("checkout");
   const { saveAddress, ...errorTexts } = useLocalization("errors");

   const { showModal } = useLegalModalContext();

   useEffect(() => {
      if (session && session.email) {
         setEmail(session.email);
      }
   }, [session]);

   const handleSubmit = async (
      values: CustomerAddresses,
      { setFieldValue, setFieldError }: FormikHelpers<CustomerAddresses>
   ) => {
      if (locale === "en-fi") {
         if (values.hasDifferentBillingAndShippingAddress) {
            const addressComponents = await fetchPredictions(
               values.shippingAddress.line1 + " " + values.shippingAddress.city,
               currentCountry.twoLetterISORegionName
            );

            if (addressComponents.length === 0) {
               setFieldError("shippingAddress.line1", invalidAddress);
               return;
            }
         }

         const addressComponents = await fetchPredictions(
            values.billingAddress.line1 + " " + values.billingAddress.city,
            currentCountry.twoLetterISORegionName
         );

         if (addressComponents.length === 0) {
            setFieldError("billingAddress.line1", invalidAddress);
            return;
         }
      }

      setLoading(true);

      // For authenticated customer, there is no blur event for the email field, so set to lowercase here
      // in submit as well.
      setFieldValue("billingAddress.email", values.billingAddress.email.toLowerCase());
      // At this stage modifying the form values won't affect the already submitted values,
      // so we need to manually override the email value.
      values = {
         ...values,
         billingAddress: {
            ...values.billingAddress,
            email: (values.billingAddress.email || email).toLowerCase(),
         },
      };

      try {
         const { customerIdentifier, subscribedSuccessfully } = await updateAddresses(
            values,
            callingCodeCountryCode,
            saveAddress,
            isAuthenticated ? session!.customerIdentifier : undefined,
            (errors: ServerAddressValidationResult) => {
               if (errors) {
                  if (enableNewCheckoutDesign) {
                     formikRef.current!.setErrors({
                        billingAddress: mapServerErrors(
                           errors.billingAddressValidationResult.errors
                        ),
                     });
                  } else {
                     formikRef.current!.setErrors({
                        billingAddress: mapServerErrors(
                           errors.billingAddressValidationResult.errors
                        ),
                        shippingAddress: mapServerErrors(
                           errors.shippingAddressValidationResult.errors
                        ),
                     });
                  }
                  setLoading(false);
               }
            }
         );

         if (customerIdentifier) {
            setLoading(false);

            onBeforeSubmit && onBeforeSubmit(values, customerIdentifier, subscribedSuccessfully);
            submitStep();
         }
      } catch {
         setLoading(false);
      }
   };

   const initialFormValues = getInitialFormValues(
      email,
      currentCountry,
      reenterInfoTypes,
      persistedState
   );
   const [initialErrors, initialTouched] = getInitialErrors(reenterInfoTypes, errorTexts);
   const validationSchema = getAddressValidationSchema(
      validations,
      errorTexts,
      !initialFormValues.hasSignedUpForNewsLetter
   );

   // Disable form reinitialization if we're submitting so we don't accidentally wipe out valid form values
   // with initial empty values. When not submitting, only allow it when we're logged in (email needs to get
   // prefilled from init data) or when no email is present (otherwise we would wipe out form values from
   // browser autofill that happens after email is entered).
   const enableReinitialize = !formikRef.current?.isSubmitting && (!!isAuthenticated || !email);

   return (
      <Formik
         enableReinitialize={enableReinitialize}
         innerRef={formikRef}
         initialValues={initialFormValues}
         initialErrors={initialErrors}
         initialTouched={initialTouched}
         validationSchema={validationSchema}
         onSubmit={handleSubmit}
      >
         {({ values }) => (
            <Form className={enableNewCheckoutDesign ? styles.userInfo : undefined}>
               <AddressFields
                  marketCountryCode={currentCountry.twoLetterISORegionName}
                  callingCodeCountryCode={callingCodeCountryCode}
                  showAsSummary={!editable}
                  addressTexts={{
                     ...addressForm,
                     billingLabel: billingAddress,
                     shippingLabel: shippingAddress,
                     phoneNumberDescription,
                  }}
                  onCallingCodeChange={(c) => setCallingCodeCountryCode(c)}
               />
               {editable && !initialFormValues.hasSignedUpForNewsLetter && (
                  <>
                     <Checkbox name="hasSignedUpForNewsLetter">
                        <HtmlContent
                           html={directMarketingPolicy}
                           links={[() => showModal("marketingPolicy")]}
                           className={enableNewCheckoutDesign ? styles.marketingPolicy : undefined}
                        />
                     </Checkbox>
                     {values.hasSignedUpForNewsLetter && (
                        <div className={styles.departmentSelect}>
                           <div className={styles.departmentTitle}>{departmentPreference}</div>
                           <FieldRow className={styles.departments}>
                              <RadioButton
                                 name="departmentPreference"
                                 label={mensDepartment}
                                 value="men"
                              />
                              <RadioButton
                                 name="departmentPreference"
                                 label={womensDepartment}
                                 value="women"
                                 hideError
                              />
                           </FieldRow>
                        </div>
                     )}
                  </>
               )}
               {editable && (
                  <div className={styles.submit}>
                     <Button
                        type="submit"
                        busy={loading}
                        className={enableNewCheckoutDesign ? styles.submitButton : undefined}
                     >
                        {continueToShipping}
                     </Button>
                  </div>
               )}
            </Form>
         )}
      </Formik>
   );
};
