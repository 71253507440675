export type ApiStatus = "idle" | "loading" | "success" | "error";

// Needs to be a class so ESLint doesn't blow up on no-throw-literal
export class ApiError extends Error {
   status: number;
   content?: string | object;

   constructor(message: string, status: number, content: string | object) {
      super(message);

      this.status = status;
      this.content = content;
   }
}

export interface ApiResponse<T> {
   result?: T;
   errorMessage?: string;
}

export type HttpMethod = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

export interface ServerValidationError {
   attemptedValue: string;
   errorCode: string;
   errorMessage: string;
   propertyName: string;
}

export type ResponseInterceptor = (
   response: Response,
   method: HttpMethod,
   requestUrl: string
) => void | Promise<void>;
