import { isValidPhoneNumber } from "libphonenumber-js";
import { CustomerAddresses } from "./types";

type UserDetails = Partial<Pick<CustomerAddresses, "billingAddress" | "shippingAddress">>;
export type ReenterInfoType = "phone" | "streetAddress" | "city" | "postalCode" | undefined;

/**
 * Checks whether we need to prompt user to reenter their info. Currently this happens in
 * the following scenarios:
 * 1. Phone number is invalid (e.g. missing country code) in billing or shipping address
 * 2. Shipping street address exceeds 41 chars, which isn't supported due to ESCI limitations
 * 3. Shipping postal code contains commas
 * 4. Shipping city exceeds 40 chars
 * @param userDetails
 * @returns
 */
export const useReenterInfo = (userDetails: UserDetails | undefined): ReenterInfoType[] => {
   const reenterTypes: ReenterInfoType[] = [];

   if (!userDetails) {
      return reenterTypes;
   }

   const { billingAddress, shippingAddress } = userDetails;

   if (
      (billingAddress && !isValidPhoneNumber(billingAddress.phoneNumber)) ||
      (shippingAddress && !isValidPhoneNumber(shippingAddress.phoneNumber))
   ) {
      reenterTypes.push("phone");
   }

   if (billingAddress && billingAddress.city.length > 39) {
      reenterTypes.push("city");
   }

   if (shippingAddress && shippingAddress.line1.length > 41) {
      reenterTypes.push("streetAddress");
   }

   if (shippingAddress && shippingAddress.postalCode.indexOf(",") > -1) {
      reenterTypes.push("postalCode");
   }

   return reenterTypes;
};
