import { Button, HtmlContent, Price, useGtm } from "@afound/react";
import { Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { formatDateRange } from "@afound/common";

import { useLegalModalContext } from "../../../components/legal-modal/legal-modal-context";
import { StepContentProps } from "../../../components/wizard";
import { useLocalization, useSettings } from "../../../settings";
import { useAppDispatch, useAppSelector } from "../../../store";
import { selectCart, validateCart } from "../../cart/cart-slice";
import { error } from "../../notification/notification-slice";
import { Carrier, SelectedShippingOption, ShippingOption } from "../types";
import { DEFAULT_CARRIER_CODE, Shipment } from "./shipment";
import styles from "./shipping.module.scss";
import { useShipping } from "./use-shipping";
import { getShippingMethodGtmEvent } from "../tracking";
import { Icon } from "../../../components/icon";
import { selectAuthenticated } from "../../session/session-slice";

interface ShippingProps extends StepContentProps {}

interface ShippingOptionsModel {
   selectedShippingOptions: SelectedShippingOption[];
}

const createInitialFormValues = (
   shippingOptions: ShippingOption[],
   selectedOptions: SelectedShippingOption[]
) => {
   const initialData: SelectedShippingOption[] = [];

   for (let i = 0; i < shippingOptions.length; i++) {
      const shippingOption = shippingOptions[i];

      const selectedShippingOption = selectedOptions.find(
         (selected) => selected.sellerId === shippingOption.sellerId
      );

      const sellerId = selectedShippingOption?.sellerId ?? shippingOption.sellerId;

      const code =
         selectedShippingOption?.code ??
         (shippingOption.carriers && shippingOption.carriers[0]?.code) ??
         DEFAULT_CARRIER_CODE;

      const agentIndex = selectedShippingOption?.agentIndex ?? "0";

      initialData.push({
         sellerId,
         code,
         agentIndex,
      });
   }

   return {
      selectedShippingOptions: initialData,
   } as ShippingOptionsModel;
};

export const Shipping = (props: ShippingProps) => {
   const { editable, submitStep } = props;

   const cart = useAppSelector(selectCart);

   const isAuthenticated = useAppSelector(selectAuthenticated);

   const dispatch = useAppDispatch();

   const initialRenderRef = useRef(true);

   const { locale, isShippingAssistantEnabled, enableNewCheckoutDesign } = useSettings();

   const { freeShipping, soldAndShippedBy, oneItemTemplate, multipleItemTemplate } =
      useLocalization("cart");
   const { continueToOrderSummary, continueToPayment, privacyNotice } = useLocalization("checkout");
   const { shipment } = useLocalization("shared");
   const { cartFetchGeneral, updateShippingOptionGeneralError } = useLocalization("errors");

   const { showModal } = useLegalModalContext();

   const [didSubmit, setDidSubmit] = useState(false);
   const [loading, setLoading] = useState(false);
   const [shouldFetchShippingOptions, setShouldFetchShippingOptions] = useState(false);
   const [shippingOptions, setShippingOptions] = useState<ShippingOption[]>([]);
   const [selectedShippingOptions, setSelectedShippingOptions] = useState<SelectedShippingOption[]>(
      []
   );

   const { fetchShippingOptions, updateSelectedShippingOption } = useShipping(
      isShippingAssistantEnabled,
      locale
   );

   const { dataLayer } = useGtm();

   useEffect(() => {
      if (cart.cart?.shops.length !== shippingOptions.length) {
         setShouldFetchShippingOptions(true);
      } else {
         setShouldFetchShippingOptions(false);
      }
   }, [cart.cart?.shops, shippingOptions.length]);

   useEffect(() => {
      const fetch = async () => {
         setLoading(true);

         const shippingOptions = await fetchShippingOptions(cart.cart!);
         if (shippingOptions) {
             setShippingOptions(shippingOptions);
             if (!isAuthenticated) {
                 dispatch(validateCart(locale));
             }
            setLoading(false);
         }
      };

      if (shouldFetchShippingOptions) {
         fetch();
         initialRenderRef.current = false;
      }
   }, [cart.cart, fetchShippingOptions, locale, shouldFetchShippingOptions, dispatch]);

   useEffect(() => {
      if (!didSubmit || cart.validateStatus === "loading") {
         return;
      }

      if (cart.validateStatus === "error") {
         dispatch(error(cartFetchGeneral));
      } else if (cart.validateStatus === "success") {
         let allCarriers: Carrier[] = [];

         shippingOptions.forEach((s) => {
            allCarriers = [...allCarriers, ...(s.carriers || [])];
         });

         const selectedShippingOptionNames = selectedShippingOptions.reduce<string[]>(
            (cur, selected) => {
               const name = allCarriers.find((c) => c.code === selected.code)?.name || "";
               cur.push(name);
               return cur;
            },
            []
         );

         dataLayer(getShippingMethodGtmEvent(selectedShippingOptionNames));
         setDidSubmit(false);
         submitStep();
      }
   }, [cart.validateStatus, didSubmit, cartFetchGeneral, dispatch, submitStep, dataLayer]);

   useEffect(() => {
      if (cart.updateShippingStatus === "error") {
         dispatch(error(updateShippingOptionGeneralError));
      }
   }, [cart.updateShippingStatus, updateShippingOptionGeneralError, dispatch]);

   const handleOnSelectedShippingOption = async (
      selectedShippingOptions: SelectedShippingOption[]
   ) =>
      await updateSelectedShippingOption(
         shippingOptions,
         selectedShippingOptions,
         cart.cart?.cartId!
      );

   const handleSubmit = async ({ selectedShippingOptions }: ShippingOptionsModel) => {
      if (cart.updateShippingStatus === "error") {
         return;
      }

      const selectedShippingAssistantOptions = selectedShippingOptions.filter(
         (so) => so.code !== DEFAULT_CARRIER_CODE
      );
      if (selectedShippingAssistantOptions.length > 0) {
         const isSuccess = await handleOnSelectedShippingOption(selectedShippingOptions);
         if (!isSuccess) {
            return;
         }

         setSelectedShippingOptions(selectedShippingOptions);
      }

      dispatch(validateCart(locale));

      setDidSubmit(true);
   };

   const hasUndeliverableSellers = shippingOptions.some((o) => o.emptyResultFromDdoCall);

   return editable ? (
      <Formik
         enableReinitialize={true}
         validateOnChange={false}
         validateOnBlur={false}
         initialValues={createInitialFormValues(shippingOptions, selectedShippingOptions)}
         onSubmit={handleSubmit}
      >
         {(values) => {
            return (
               <Form>
                  {shippingOptions.map((shippingOption, i) => {
                     const seller = cart.cart!.shops.find(
                        (shop) => shop.sellerId === shippingOption.sellerId
                     );
                     return seller ? (
                        <Shipment
                           key={shippingOption.sellerId}
                           cartSeller={seller}
                           shipmentIndex={i}
                           shipmentLabel={shipment}
                           currency={cart.cart!.currency}
                           locale={locale}
                           carriers={shippingOption.carriers || []}
                           isLoading={cart.updateShippingStatus === "loading"}
                           initialExpandedCarrier={
                              values.values.selectedShippingOptions.find(
                                 (s) => s.sellerId === shippingOption.sellerId
                              )?.code
                           }
                           showUnableToDeliverMessage={shippingOption.emptyResultFromDdoCall}
                           handleOnSelectedShippingOption={handleOnSelectedShippingOption}
                        />
                     ) : null;
                  })}
                  <HtmlContent
                     className={enableNewCheckoutDesign ? styles.disclaimerNew : styles.disclaimer}
                     html={privacyNotice}
                     links={[
                        () => showModal("accountPolicy"),
                        () => showModal("accountPrivacyPolicy"),
                     ]}
                  />
                  <div className={styles.submit}>
                     <Button
                        type="submit"
                        busy={
                           loading ||
                           cart.validateStatus === "loading" ||
                           cart.updateShippingStatus === "loading"
                        }
                        className={enableNewCheckoutDesign ? styles.submitButton : undefined}
                        disabled={hasUndeliverableSellers}
                     >
                        {enableNewCheckoutDesign ? continueToOrderSummary : continueToPayment}
                     </Button>
                  </div>
               </Form>
            );
         }}
      </Formik>
   ) : (
      <div className={styles.summary}>
         {shippingOptions.map((so, idx) => {
            const cartSeller = cart.cart?.shops.find((s) => s.sellerId === so.sellerId);
            const quantity = cartSeller
               ? cartSeller.products.reduce((sum, current) => sum + current.quantity, 0)
               : 0;
            const selectedShippingOption = selectedShippingOptions.find(
               (selected) => selected.sellerId === so.sellerId
            )!;
            const selectedCarrier = so.carriers?.find(
               (c) => c.code === selectedShippingOption?.code
            );
            const deliveryTime = cartSeller
               ? `${formatDateRange(cartSeller.deliveryStartDate, locale)} - ${formatDateRange(
                    cartSeller.deliveryEndDate,
                    locale
                 )}`
               : "";
            const quantityLabel = quantity === 1 ? oneItemTemplate : multipleItemTemplate;

            return (
               <div
                  className={enableNewCheckoutDesign ? styles.summaryItemNew : styles.summaryItem}
                  key={so.sellerId}
               >
                  {enableNewCheckoutDesign ? (
                     <div className={styles.detailsNew}>
                        <div>
                           <Icon type="Package" color="none" />
                           <span className="u-bold">
                              &nbsp;{shipment} {idx + 1}&nbsp;
                           </span>
                        </div>
                        <div>
                           <span className={styles.packageQuantity}>
                              {`(${quantity} ${quantityLabel})`} -{" "}
                           </span>
                           <span>{soldAndShippedBy}</span>
                           <span className="u-bold">&nbsp;{so.sellerName}</span>
                        </div>
                     </div>
                  ) : (
                     <div className={styles.details}>
                        <span className="u-bold">
                           {shipment} {idx + 1}: {so.sellerName}
                        </span>
                        <span>{selectedCarrier?.name ?? cartSeller?.deliveryBy}</span>
                     </div>
                  )}
                  <div
                     className={
                        enableNewCheckoutDesign
                           ? [styles.detailsNew, styles.reverseNew].join(" ")
                           : [styles.details, styles.reverse].join(" ")
                     }
                  >
                     {enableNewCheckoutDesign ? (
                        <span> {selectedCarrier?.name ?? cartSeller?.deliveryBy},</span>
                     ) : (
                        <></>
                     )}
                     <span>
                        {selectedCarrier?.estimatedDeliveryText ??
                           (enableNewCheckoutDesign ? deliveryTime : cartSeller?.deliveryTime)}
                     </span>
                     <span>
                        {selectedCarrier?.shippingCost === 0 ||
                        cartSeller?.calculatedShippingFeeAmount === 0 ? (
                           freeShipping
                        ) : (
                           <Price
                              value={
                                 selectedCarrier?.shippingCost ??
                                 cartSeller?.calculatedShippingFeeAmount ??
                                 0
                              }
                              currency={cart.cart!.currency}
                              locale={locale}
                              plain
                              className={enableNewCheckoutDesign ? styles.deliveryPrice : undefined}
                           />
                        )}
                     </span>
                  </div>
               </div>
            );
         })}
      </div>
   );
};
