import { LinkButton, LinkButtonProps } from "@afound/react";

import { useSettings } from "../../settings";
import styles from "./back-button.module.scss";

type BackButtonProps = LinkButtonProps & {
   fallbackUrl?: string;
};

export const BackButton = (props: BackButtonProps) => {
   const { fallbackUrl, onClick, ...rest } = props;

   const { storeSiteUrl } = useSettings();

   // Make sure the referrer is valid, i.e.
   const hasValidReferrer = document.referrer.indexOf(storeSiteUrl) !== -1;

   const handleClick = () => {
      if (hasValidReferrer) {
         window.history.back();
      }
   };

   return (
      <LinkButton
         {...rest}
         className={styles.backButton}
         onClick={handleClick}
         // Render as a link to the fallback URL if no valid referrer exists
         url={hasValidReferrer ? undefined : fallbackUrl || storeSiteUrl}
      />
   );
};
