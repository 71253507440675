import { PersistedState, ReturnerType } from "../session/types";
import { StepConfig } from "./types";

export const getInitialStep = (
   isAuthenticated: boolean,
   returnerType: ReturnerType | undefined,
   persistedState: PersistedState | undefined,
   requiresNewUserInfo: boolean
): number => {
   let initialStep = 1;

   if (requiresNewUserInfo) {
      return StepConfig.Address;
   }

   const hasValidAddresses = persistedState?.billingAddress && persistedState?.shippingAddress;

   if (isAuthenticated) {
      if (returnerType === "ExtremeReturner") {
         return initialStep;
      }

      return hasValidAddresses ? StepConfig.Shipping : StepConfig.Address;
   }

   const hasSavedProgression =
      persistedState?.progressionState === "ReadyForPayment" ||
      persistedState?.progressionState === "PaymentInitiated";

   if (hasSavedProgression) {
      // Temporary until we would save and do not reset shipping preferences
      return hasValidAddresses ? StepConfig.Shipping : StepConfig.Address;
   }

   return initialStep;
};
