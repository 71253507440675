import { iconMap } from "./icon-map";
import styles from "./icon.module.scss";

export type IconType = keyof typeof iconMap;

export interface IconProps {
   type: IconType;
   size?: number;
   height?: number;
   width?: number;
   color?: string;
   rotate?: number;
}

export const Icon = (props: IconProps) => {
   const { type, size = 20, height, width, color, rotate } = props;

   const IconComponent = iconMap[type];
   return IconComponent ? (
      <IconComponent
         className={styles.icon}
         height={height ?? size}
         width={width ?? size}
         fill={color}
         style={rotate !== undefined ? { transform: `rotate(${rotate}deg)` } : {}}
      />
   ) : null;
};
