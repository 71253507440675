export type DepartmentPreference = "men" | "women";

export enum StepConfig {
   Identification = 1,
   Address = 2,
   Shipping = 3,
   Payment = 4,
}

export interface CustomerAddresses {
   billingAddress: BillingAddress;
   shippingAddress: Address;
   hasApprovedTermsOfService: boolean;
   hasSignedUpForNewsLetter?: boolean;
   departmentPreference?: DepartmentPreference;
   hasDifferentBillingAndShippingAddress?: boolean;
}

export interface Address {
   email: string;
   firstName: string;
   lastName: string;
   line1: string;
   postalCode: string;
   city: string;
   countryCode?: string;
   phoneNumber: string;
}

export interface BillingAddress extends Address {
   callingCodeCountryCode: string;
}

export interface Carrier {
   name: string;
   estimatedDeliveryText: string;
   description: string;
   shippingCost: number;
   agents: Array<CarrierAgent>;
   code: string;
   isEco: boolean;
   iconUrls: string[];
}

export interface CarrierAgent {
   storeName: string;
   address: string;
   postCode: string;
   //storetimes
   carrierCode: string;
   groupCode: string;
   storeId: string;
   shippingLocation: string;
}

export interface CarrierAgentShippingOption extends CarrierAgent {
   shippingCost: number;
   deliveryBy: string;
   deliveryTime: string;
}

export type ShippingOption = {
   sellerId: number;
   sellerName: string;
   carriers?: Array<Carrier>;
   emptyResultFromDdoCall: boolean;
};

export type SelectedShippingOption = Pick<Carrier, "code"> &
   Pick<ShippingOption, "sellerId"> & {
      agentIndex: string;
   };
